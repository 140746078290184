import { defineStore } from 'pinia'
import axios from 'axios'
import{getAuthStore} from './auth'
import router from '../router'


export const getLogoutStore = defineStore('logoutStore', {
  state: () => ({
    isAuth: false,
    error: '',
    loading: false
  }),
  actions: {
    async logout() {
      this.error = '';
      this.loading = true;
      try {
        const res = await axios.post(process.env.VUE_APP_API + 'logout');
        if (res.data && res.data.status == 'OK') {
          if (router.currentRoute.name != 'HomePage') {
            router.push({ name: "HomePage" });
          }
          getAuthStore().fetchData();
        } else if (res.data && res.data.status != 'OK') {
          this.error = res.data.message;
        } else {
          this.error = res;
        }
      } catch (err) {
        if (err.response) {
          this.error = err.response.data.message;
        } else {
          this.error = err;
        }
      } finally {
        this.loading = false;
      }
    },
  },
});

