import { defineStore } from 'pinia'
import axios from 'axios'
import{getModalsStore} from './modals'


export const getRecoverStore = defineStore('recoverStore', {
  state: () => ({
    error: '',
    successMessage: '',
    loading: false
  }),
  actions: {
    async submitRecover(params = {}) {
      this.error = '';
      this.loading = true;
      try {
        const res = await axios.post(process.env.VUE_APP_API + 'user/reset-password', params);
        let self = this;
        if (res.data && res.data.status == 'OK') {
          self.error = '';
          self.successMessage = 'Success';
          setTimeout(function() {
            self.successMessage = '';
            getModalsStore().closeSignIn();
          }, 2500)
        }  else if (res.data && res.data.status != 'OK') {
          this.error = res.data.message;
        } else {
          this.error = res;
        }
      } catch (err) {
        if (err.response) {
          this.error = err.response.data.message;
        } else {
          this.error = err;
        }
      } finally {
        this.loading = false;
      }
    },
    clearError() {
      this.error = '';
    }
  },
});

