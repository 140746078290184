
import { createStore } from 'vuex'

import app from './modules/app'


const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
    modules: {
        app
    },
    strict: debug,
})

export default store