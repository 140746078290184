import { defineStore } from 'pinia'
import axios from "@/axios";
import i18n from "@/i18n";

export const useLocalizationsStore = defineStore('localizationsStore', () => {
  const fetchLocalizations = async (locale) => {
    try {
      const response = await axios.get(process.env.VUE_APP_API + 'localizations');
      // Update i18n messages dynamically
      i18n.global.setLocaleMessage(locale, response.data);
      i18n.global.locale.value = locale;
    } catch (error) {
      console.error('Error fetching localizations:', error);
    }
  }

  return {
    fetchLocalizations,
  };
});

