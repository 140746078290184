import {reactive, ref} from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';
import router from '@/router';
import {getAuthStore} from "@/stores/auth"; // Ensure the router is imported

export const getProfileStore = defineStore('profileStore', () => {
  // Define reactive state using `ref` and `reactive`
  const profile = reactive({});
  const error = ref('');

  // Define the actions as functions
  const fetchData = async () => {
    error.value = ''; // Reset error before fetching data
    try {
      const res = await axios.get(process.env.VUE_APP_API + 'user/profile');
      Object.assign(profile, res.data);
    } catch (err) {
      error.value = err; // Capture error if the request fails
    }
  };

  const logout = async () => {
    try {
      await axios.post(`${process.env.VUE_APP_API}logout`);
      getAuthStore().isAuth = false;
    } catch (e) {
      console.error(e);
    }

    Object.assign(profile, {});
    await router.push('/'); // Redirect to the homepage
  };

  const updateBalance = (balance) => {
    profile.balance = balance;
  }

  // Return the state and actions so they can be used in components
  return {
    profile,
    error,
    fetchData,
    updateBalance,
    logout
  };
});
