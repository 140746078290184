// debounce.js
const debounce = (callback, delay = 1000) => {
    let debounceTimeout;

    return (...args) => {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        debounceTimeout = setTimeout(() => {
            callback(...args); // Pass arguments to the callback
        }, delay);
    };
};

export { debounce };
