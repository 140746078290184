<template>
  <div class="cabinet-wrapper">
    <div class="cabinet-profile-container">
      <div class="title regular">
        <b>{{ $t('Top up balance') }}</b>
      </div>
      <div class="topup-content">
        <div class="common-nav">
          <a :class="['title small', {'active': activeNavItem == 1}]" @click.prevent="changeNavItem(1)">
            <b>{{ $t('Individual Services') }}</b>
          </a>
          <a :class="['title small', {'active': activeNavItem == 2}]" @click.prevent="changeNavItem(2)">
            <b>{{ $t('Discounted Packages') }}</b>
          </a>
        </div>
        <div class="topup-payment" v-if="activeNavItem == 1">
          <div class="topup-balance">
            <form @submit="onSubmit" class="form">
              <label>
                <div class="desc small">
                  <b>{{ $t('Enter amount') }}:</b>
                </div>
                <div
                    class="TextInput"
                >
                  <input
                      name="title"
                      type="text"
                      v-model="amount"
                      v-bind="amountAttrs"
                      required
                  />
                  <div class="desc currency">
                    <b>{{ $t('EUR') }}</b>
                  </div>

                  <p class="help-message" v-show="errors && errors.amount">
                    {{ errors.amount }}
                  </p>
                </div>
              </label>
              <div class="total">
                <div v-if="amount && settings.points_conversion_rate" class="left">
                  <div class="desc small">
                    <b>{{ $t('Total credits:') }}</b>
                  </div>
                  <div class="desc big">
                    <b>{{ (amount * settings.points_conversion_rate) }} {{ $t('credits') }}</b>
                  </div>
                </div>
                <div class="right">
                  <button :disabled="!meta.valid || !meta.touched" class="button extrasmall">
                    <span>{{ $t('Pay') }}</span>
                  </button>
                </div>
              </div>
            </form>
            <div class="my-balance">
              <div class="balance-title">
                <img
                  class="img"
                  src="./../assets/img/wallet.svg"
                />
                <div class="desc">
                  <b>{{ $t('My balance') }}</b>
                </div>
              </div>
              <div class="title small balance-amount">
                <b>{{ profile.balance }} credits</b>
              </div>
            </div>
          </div>
          <div class="pricing-cards">
            <div class="card">
              <div class="card-top">
                <div class="title small">
                  <b>{{ $t('Text') }}</b>
                </div>
                <div class="icon">
                  <img
                      class="img"
                      src="./../assets/img/icons/article.svg"
                  />
                </div>
              </div>
              <div class="list">
                <div class="item">
                  <div class="desc small">
                    {{ $t('1000 characters') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('1 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('5000 characters') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('5 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('10,000 characters') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('10 EUR') }}
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-top">
                <div class="title small">
                  <b>{{ $t('Videos') }}</b>
                </div>
                <div class="icon">
                  <img
                      class="img"
                      src="./../assets/img/icons/video.svg"
                  />
                </div>
              </div>
              <div class="list">
                <div class="item">
                  <div class="desc small">
                    {{ $t('1 video') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('10 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('3 video') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('30 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('5 video') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('50 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('10 video') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('100 EUR') }}
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-top">
                <div class="title small">
                  <b>{{ $t('Images') }}</b>
                </div>
                <div class="icon">
                  <img
                      class="img"
                      src="./../assets/img/icons/picture.svg"
                  />
                </div>
              </div>
              <div class="list">
                <div class="item">
                  <div class="desc small">
                    {{ $t('1 image') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('0.7 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('9 images') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('6.3 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('15 images') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('10.5 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('30 images') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('21 EUR') }}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="price-desc">
            <div class="price-desc__item desc small">1 character = 1 credit</div>
            <div class="price-desc__item desc small">1 video = 10000 credit</div>
            <div class="price-desc__item desc small">1 image = 700 credit</div>
          </div>
        </div>
        <div class="topup-payment topup-payment-2" v-if="activeNavItem == 2">
          <div class="desc small">
            <b>{{ $t('Choose package') }}:</b>
          </div>
          <div class="pricing-cards pricing-plans">

            <div :key="pack.id" v-for="(pack, i) in packs" class="card">
              <div class="card-top">
                <div class="title small">
                  <b>{{ $t(pack.name) }}</b> {{ $t('Package') }}
                </div>
                <div class="icon">
                  <div class="desc small">
                    <b>{{ i + 1 }}</b>
                  </div>
                </div>
              </div>
              <div class="desc small">
                {{ $t('Includes:') }}
              </div>
              <div class="list">
                <div class="item">
                  <div class="desc small">
                    {{ $t('characters of text ') }}
                  </div>
                  <div class="desc small">
                    <b>
                      {{ pack.article_characters_amount }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('videos') }}
                  </div>
                  <div class="desc small">
                    <b>
                      {{ pack.videos_amount }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('images') }}
                  </div>
                  <div class="desc small">
                    <b>
                      {{ pack.article_images_amount }}
                    </b>
                  </div>
                </div>
              </div>
              <div class="card-bottom">
                <div class="left">
<!--                  <div class="desc extrasmall">{{ $t('51.00 EUR') }}</div>-->
                  <div class="title small">
                    <b>{{ `${pack.price} EUR` }}</b>
                  </div>
                </div>
                <div class="right">
                  <button @click="modalsStore.openTopupModal(null, pack)" class="button extrasmall">
                    <span>{{ $t('GET') }}</span>
                  </button>
                </div>
              </div>
            </div>

<!--            <div class="card">-->
<!--              <div class="card-top">-->
<!--                <div class="title small">-->
<!--                  <b>{{ $t('Standard ') }}</b> {{ $t('Package') }}-->
<!--                </div>-->
<!--                <div class="icon">-->
<!--                  <div class="desc small">-->
<!--                    <b>2</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="desc small">-->
<!--                {{ $t('Includes:') }}-->
<!--              </div>-->
<!--              <div class="list">-->
<!--                <div class="item">-->
<!--                  <div class="desc small">-->
<!--                    {{ $t('characters of text ') }}-->
<!--                  </div>-->
<!--                  <div class="desc small">-->
<!--                    <b>-->
<!--                      {{ $t('5000') }}-->
<!--                    </b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <div class="desc small">-->
<!--                    {{ $t('videos') }}-->
<!--                  </div>-->
<!--                  <div class="desc small">-->
<!--                    <b>-->
<!--                      {{ $t('7') }}-->
<!--                    </b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <div class="desc small">-->
<!--                    {{ $t('images') }}-->
<!--                  </div>-->
<!--                  <div class="desc small">-->
<!--                    <b>-->
<!--                      {{ $t('15') }}-->
<!--                    </b>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card-bottom">-->
<!--                <div class="left">-->
<!--                  <div class="desc extrasmall">{{ $t('105.00 EUR') }}</div>-->
<!--                  <div class="title small">-->
<!--                    <b>{{ $t('76.50 EUR') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="right">-->
<!--                  <button class="button extrasmall">-->
<!--                    <span>{{ $t('GET') }}</span>-->
<!--                  </button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="card premium">-->
<!--              <div class="card-top">-->
<!--                <div class="title small">-->
<!--                  <b>{{ $t('Premium ') }}</b> {{ $t('Package') }}-->
<!--                </div>-->
<!--                <div class="icon">-->
<!--                  <div class="desc small">-->
<!--                    <b>3</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="desc small">-->
<!--                {{ $t('Includes:') }}-->
<!--              </div>-->
<!--              <div class="list">-->
<!--                <div class="item">-->
<!--                  <div class="desc small">-->
<!--                    {{ $t('characters of text') }}-->
<!--                  </div>-->
<!--                  <div class="desc small">-->
<!--                    <b>-->
<!--                      {{ $t('10 000') }}-->
<!--                    </b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <div class="desc small">-->
<!--                    {{ $t('videos') }}-->
<!--                  </div>-->
<!--                  <div class="desc small">-->
<!--                    <b>-->
<!--                      {{ $t('10') }}-->
<!--                    </b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <div class="desc small">-->
<!--                    {{ $t('images') }}-->
<!--                  </div>-->
<!--                  <div class="desc small">-->
<!--                    <b>-->
<!--                      {{ $t('30') }}-->
<!--                    </b>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card-bottom">-->
<!--                <div class="left">-->
<!--                  <div class="desc extrasmall">{{ $t('160.00 EUR') }}</div>-->
<!--                  <div class="title small">-->
<!--                    <b>{{ $t('150.00 EUR') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="right">-->
<!--                  <button class="button extrasmall">-->
<!--                    <span>{{ $t('GET') }}</span>-->
<!--                  </button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>

      <div class="top-up-footer">
        <div class="desc small requisites">
          {{ settings.requisites }}
          <br/>
          <span>e-mail: <a :href="`mailto:${settings.support_email}`" >{{settings.support_email	}}</a></span>
        </div>
        <div class="payment-logos">
          <img src="../assets/img/methods/visa.svg" alt="">
          <img src="../assets/img/methods/mc_symbol.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getProfileStore} from "@/stores/profile";
import {getSettingsStore} from "@/stores/settings";
import {useDepositsStore} from "@/stores/deposits";
import {onMounted, ref} from 'vue';
import {useForm} from "vee-validate";
import * as yup from 'yup';
import {getModalsStore} from "@/stores/modals";
import {storeToRefs} from "pinia";

const profileStore = getProfileStore();
const settingsStore = getSettingsStore();
const modalsStore = getModalsStore();
const depositsStore = useDepositsStore();

const {profile} = profileStore;
const {settings} = settingsStore;
const {fetchDepositPacks} = depositsStore;

const {handleSubmit, defineField, errors, meta} = useForm({
  validationSchema: yup.object({
    amount: yup.number().required().positive().when([], {
      is: () => settings.min_top_up_amount,
      then: (schema) => schema.min(settings.min_top_up_amount, `Min top up amount is ${settings.min_top_up_amount} EUR`),
      otherwise: (schema) => schema
    })
  })
});


const onSubmit = handleSubmit(({amount}) => {
  modalsStore.openTopupModal(amount);
})

const [amount, amountAttrs] = defineField('amount');

// State
// const enteredCredits = ref(0);

// Computed properties
// const totalToPay = computed(() => enteredCredits.value * 2); // Assuming 1 credit = $2
// const formattedTotal = computed(() => `$${totalToPay.value.toFixed(2)}`);

const {packs} = storeToRefs(depositsStore)

let activeNavItem = ref(1);

onMounted(() => {
  fetchDepositPacks();
})

const changeNavItem = (item) => {
  activeNavItem.value = item;
};

</script>
