<template>
  <div class="tab-preview">
    <label class="search-label">
      <TextInput
          name="title"
          type="text"
          placeholder="Search"
          required
      />
      <img
          class="search-img"
          src="../assets/img/icons/search.svg"
      />
    </label>
    <div class="scene-list">
      <VueDraggable
          handle=".item-header"
          v-model="localScenes"
          @end="onEndDrag"
          animation="150"
          ghostClass="ghost"
          class="container"
      >
        <div
            v-for="(scene, i) in localScenes"
            :key="i"
            class="item"
        >
          <div
              class="item-header"
              @click="editingIndex !== i && editScene(i)"
          >
            <div class="desc small">{{ $t('Scene') }} {{ i + 1 }}</div>
            <img
                class="img"
                src="../assets/img/icons/move.svg"
            />
          </div>
          <div class="item-main">
            <!-- Отображаем текст с v-html и поддержкой редактирования -->
            <div
                v-if="editingIndex === i"
                contenteditable="true"
                @input="debouncedOnInput($event, i)"
                @blur="stopEditing"
                class="desc editable"
                v-html="scene.text"
            ></div>
            <div
                v-once
                v-else
                @click="editScene(i)"
                class="desc"
                v-html="scene.text"
            ></div>
          </div>
        </div>
      </VueDraggable>
    </div>
  </div>
</template>

<script setup>
import {VueDraggable} from 'vue-draggable-plus';
import TextInput from "@/components/TextInput.vue";
import {useVideoStore} from "@/stores/video";
import {storeToRefs} from "pinia";
import {ref} from "vue";
import {debounce} from "@/utils/utils";

// Подключаем store
const videoStore = useVideoStore();
const {scenes} = storeToRefs(videoStore);
const {updateScenes} = videoStore;

// Создаем локальную копию сцен для работы с ними
const localScenes = ref(JSON.parse(JSON.stringify(scenes.value)));
const updatedScenes = ref(JSON.parse(JSON.stringify(localScenes.value)));

// Индекс текущей редактируемой сцены
const editingIndex = ref(null);

// Начать редактирование
const editScene = (index) => {
  editingIndex.value = index;
};

// Обработчик ввода текста с тегами
const onInput = (event, index) => {
  // Сохранить позицию курсора
  const target = event.target;

  // Обновить текст в локальной копии
  updatedScenes.value[index].text = target.innerHTML;

  updateScenes(updatedScenes.value);
};

// Обёртка с debounce
const debouncedOnInput = debounce(onInput, 500);

// Остановить редактирование и обновить данные в store
const stopEditing = () => {
  if (editingIndex.value !== null) {
    // console.log('dfdfdfdf')
    // updateScenes(localScenes.value); // Сохраняем изменения в store
    editingIndex.value = null; // Сбрасываем индекс
    localScenes.value = JSON.parse(JSON.stringify(updatedScenes.value))
  }
};

// Обновление сцен после завершения перетаскивания
const onEndDrag = () => {
  console.log('3434234324322')
  console.log('3434234324322')
  updateScenes(localScenes.value); // Сохраняем новый порядок сцен в store
};
</script>

<style scoped>
.editable {
  width: 100%;
  min-height: 60px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
}
</style>