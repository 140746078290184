import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useDepositsStore = defineStore('depositsStore', () => {
  // State variables using ref
  const packs = ref([]);
  const deposits = ref([]);
  const error = ref('');

  // Actions
  const fetchDeposits = async () => {
    error.value = ''; // Reset error before fetching
    try {
      const res = await axios.get(process.env.VUE_APP_API + 'deposit/history');
      deposits.value = res.data.payload;
    } catch (err) {
      error.value = err; // Set error if request fails
    }
  };

  const fetchDepositPacks = async () => {
    try {
      const {data: {payload}} = await axios.get(process.env.VUE_APP_API + 'packs')
      packs.value = payload;
    } catch (e) {
      console.error(e)
    }
  }

  const clear = () => {
    deposits.value = []; // Clear the deposits array
  };

  // Return the state and actions
  return {
    deposits,
    packs,
    error,
    fetchDeposits,
    fetchDepositPacks,
    clear,
  };
});
