<template>
  <div class="modal video-paths-modal">
    <div
        class="overlay"
        @click="modalsStore.closeLoadingModal()"
    />
    <div class="wrapper wrapper-loading">
      <div class="container">
        <div class="title regular">
          <b>{{ $t(modalsStore.loadingModal.title) }}</b>
        </div>
        <div class="progress">
          <div :style="barStyle" class="bar"></div>
        </div>
        <div class="desc">
          <b>{{ modalsStore.loadingModal.percent }}%</b>
          <b> {{ !modalsStore.loadingModal.text ? $t('completed') : modalsStore.loadingModal.text }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getModalsStore} from './../stores/modals';
import {computed} from "vue";

let modalsStore = getModalsStore();

const barStyle = computed(() => ({
  width: `${modalsStore.loadingModal.percent}%`
}))
</script>


