<script setup>
import {ref} from "vue";
import axios from "@/axios";
import {useVideoStore} from "@/stores/video";
import {storeToRefs} from "pinia";

const videoStore = useVideoStore();
const selectedFiles = ref([]);
const isDragging = ref(false);
const fileInput = ref(null);

const videoDurations = ref({});

const videoFileTypes = [
  "video/mp4",
  "video/mov",
  "video/webm",
];

const imgFileTypes = [
  "image/jpeg",
  "image/png",
  "image/webp",
];


const videoAndImgFileTypes = [
  ...videoFileTypes,
  ...imgFileTypes
]

const audioFileTypes = [
  "audio/mp3",
  "audio/wav",
  "audio/weba"
];

const triggerFileInput = () => {
  fileInput.value.click();
};

const onFileChange = (event, mediaTypes) => {
  const files = Array.from(event.target.files);
  handleFiles(files, mediaTypes);
};

const onDragOver = () => {
  isDragging.value = true;
};

const onDragLeave = () => {
  isDragging.value = false;
};

const onDrop = (event, mediaTypes) => {
  isDragging.value = false;
  const files = Array.from(event.dataTransfer.files);
  handleFiles(files, mediaTypes);
};

const handleFiles = (files, mediaTypes) => {
  const validFiles = files.filter((file) => {
    // Validate file types
    const allowedTypes = mediaTypes;
    const maxSize = 1024 * 1024 * 1024; // 1GB

    if (!allowedTypes.includes(file.type)) {
      // alert(`${file.name} is not a supported file type.`);
      return false;
    }

    if (file.size > maxSize) {
      // alert(`${file.name} exceeds the maximum file size of 1GB.`);
      return false;
    }

    return true;
  });

  selectedFiles.value = [...selectedFiles.value, ...validFiles];

  if (validFiles.length > 0) {
    uploadFiles(validFiles);
  }
};


const uploadFiles = async (files) => {
  const formData = new FormData();
  formData.append('project_id', video.value.id)

  files.forEach((file) => {
    formData.append('content[]', file); // Use the appropriate field name as expected by your server

    let type;

    if (imgFileTypes.includes(file.type)) {
      type = 'image'
    } else if (videoFileTypes.includes(file.type)) {
      type = 'video'
    } else if (audioFileTypes.includes(file.type)) {
      type = 'audio';
    }

    formData.append('type[]', type)
  });

  let successfullyUploaded = false;

  try {
    await axios.post('/video/uploads/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    successfullyUploaded = true;
  } catch (error) {
    console.error('Upload error:', error);
  }

  if (successfullyUploaded) {
    fetchVisualUploads();
  }
};

const playVideo = (event) => {
  const video = event.target;
  video.play();
};

const resetVideo = (event) => {
  const video = event.target;
  video.pause();
  video.currentTime = 0;
};

// Функция для обработки получения длительности видео
const onVideoMetadataLoaded = (event, uploadId) => {
  const videoElement = event.target;
  const duration = videoElement.duration;

  // Сохраняем длительность в формате "минуты:секунды"
  videoDurations.value[uploadId] = formatTime(duration);
};

// Функция для форматирования времени в формате "мм:сс"
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

const {
    fetchVisualUploads,
    replaceSceneBackground
} = videoStore;

const {
  video,
  visualUploads
} = storeToRefs(videoStore);


</script>

<template>
  <div class="library library2">
    <div
        class="library-header"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop($event, videoAndImgFileTypes)"
        :class="{ 'dragging': isDragging }"
    >
      <label class="upload-label">
        <input
            type="file"
            multiple
            @change="onFileChange($event, videoAndImgFileTypes)"
            ref="fileInput"
            style="display: none"
            :accept="videoAndImgFileTypes.join(',')"
        />
        <div class="upload" @click="triggerFileInput">
          <img
              src="../assets/img/icons/uploadVideo.svg"
              class="img"
              alt="upload"
          />
          <div class="desc small">
            <b>{{ $t('Drag and drop files or browse from your computer') }}</b>
          </div>
          <div class="desc small">{{ $t('Supported format types and sizes:') }}</div>
          <div class="desc small">
            {{ $t('Video - MP4, MOV, WEBM, OGG up to 1 GB or 15.00 minute(s)') }}
            <br />
            {{ $t('Audio - MP3, WAV, WEBA up to 1 GB or 15.00 minute(s)') }}
          </div>
        </div>
      </label>
    </div>

    <div class="list">
      <div class="list-wrapper">

        <div :key="upload.id" v-for="upload in visualUploads" class="item">
          <a class="text-button">
            <img
                class="img"
                src="../assets/img/icons/add.svg"
            />
          </a>
          <a @click.prevent="replaceSceneBackground(upload.type, upload.url)" class="text-button">
            <img

                class="img"
                src="../assets/img/icons/replace.svg"
            />
          </a>
          <img
              v-if="upload.type === 'image'"
              class="img"
              :src="upload.url"
          />
          <video
              v-else-if="upload.type === 'video'"
              :src="upload.url"
              class="img"
              @mouseenter="playVideo($event)"
              @mouseleave="resetVideo($event)"
              @loadedmetadata="onVideoMetadataLoaded($event, upload.id)"
          ></video>

          <div v-if="upload.type === 'video'" class="desc small">{{ videoDurations[upload.id] || '00:00' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>