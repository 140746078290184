<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useVideoStore } from "@/stores/video";
import { storeToRefs } from "pinia";

const categories = [
  "Business and Industrial",
  "Agriculture and Forestry",
  "Arts and Entertainment",
  "Autos and Vehicles",
  "Beauty and Fitness",
  "Biological Sciences",
  "Books and Literature",
  "Computers and Electronics",
  "Finance",
  "Food and Drink",
  "Games",
  "Health",
  "Home and Garden",
  "Internet and Telecom",
  "Jobs and Education",
  "People and Society",
  "Pets and Animals",
  "Real Estate",
  "Science",
  "Sports",
  "Travel"
];

const videoStore = useVideoStore();
const { fetchMedia, replaceSceneBackground, addScene } = videoStore;
const { media, mediaPage, mediaSearch, mediaCategory } = storeToRefs(videoStore);

const searchQuery = ref(""); // Реактивная переменная для отслеживания инпута
const filterIsOpen = ref(false);
const selectDropdownIsActive = ref(false);

// Используем ref для хранения состояния видео (индекс каждого видео и его состояние)
const showVideo = ref({});

const searchCategory = ref(mediaCategory.value);

let debounceTimeout = null; // Для хранения идентификатора таймера
let observers = []; // Будем хранить все observers в массиве для очистки

// Функция с debounce для вызова fetchMedia
const handleSearch = (debounce = true) => {
  const search = async () => {
    await fetchMedia(true);
    showVideo.value = {}; // Сброс состояния
    console.log(showVideo.value, 'showVideo');
  };

  if (!debounce) {
    search()
    return;
  }

  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  debounceTimeout = setTimeout(search, 2000);
};

function formatDuration(seconds) {
  const roundedSeconds = Math.round(seconds);
  const minutes = Math.floor(roundedSeconds / 60);
  const remainingSeconds = roundedSeconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
}

// Метод для воспроизведения видео при наведении
const playVideo = async (index) => {
  console.log('play', index);

  if (!showVideo.value[index]) {
    const videoElement = document.getElementById(`video-visuals-${index}`);

    if (videoElement) {
      await videoElement.play();
      showVideo.value[index] = true;
    }
  }
};

// Метод для остановки видео при уходе курсора
const stopVideo = async (index) => {
  console.log('stop', index);

  if (showVideo.value[index]) {
    const videoElement = document.getElementById(`video-visuals-${index}`);

    if (videoElement) {
      await videoElement.pause();
      showVideo.value[index] = false;
    }
  }
};

const handleScroll = ({ target: { scrollTop, clientHeight, scrollHeight } }) => {
  if (scrollTop + clientHeight >= scrollHeight) {
    mediaPage.value = mediaPage.value + 1;
    fetchMedia();
  }
};

const addSceneToEnd = ({ duration, mediaType, preview }) => {
  console.log(mediaType, 'mediaType');

  addScene({
    duration,
    backgroundType: mediaType,
    backgroundUri: mediaType === 'video' ? preview.url : preview.url,
    fontSize: 23,
    fontWeight: 'normal',
    fontFamily: 'Arial',
    textBackgroundColor: 'rgba(17,17,17,153)',
    transition: 'none',
    subtitles: true
  });
};

// Наблюдаем за изменениями searchQuery и вызываем handleSearch (для текста поиска)
watch(searchQuery, () => {
  mediaPage.value = 1;
  mediaSearch.value = searchQuery.value; // Обновляем значение в store
  handleSearch(); // Запускаем дебаунс функцию
});

// Наблюдаем за изменениями mediaCategory (для категории) и сразу вызываем handleSearch
watch(mediaCategory, () => {
  mediaPage.value = 1; // Сброс страницы при изменении категории
  handleSearch(false); // Немедленно вызываем handleSearch при изменении категории
});

// Функция для инициализации IntersectionObserver
function initIntersectionObserver(media) {
  observers.forEach((observer) => observer.disconnect()); // Очищаем старые наблюдатели
  observers = [];

  const observer = new IntersectionObserver(handleIntersect, {
    threshold: 0.1, // Процент видимости элемента для срабатывания наблюдателя
  });

  media.forEach((item, index) => {
    const videoElement = document.getElementById(`video-visuals-${index}`);
    if (videoElement) {
      observer.observe(videoElement);
      observers.push(observer);
    }
  });
}

// Функция для обработки видимости элементов
function handleIntersect(entries) {
  entries.forEach((entry) => {
    const index = entry.target.getAttribute("data-index");
    const videoElement = document.getElementById(`video-visuals-${index}`);

    if (entry.isIntersecting) {
      // Видео становится видимым
      console.log('video visible', index);
    } else {
      // Видео становится невидимым
      if (videoElement) {
        videoElement.pause();
        showVideo.value[index] = false;
      }
    }
  });
}

const closeCategoryDropdown = () => {
  selectDropdownIsActive.value = false;
};

// Инициализация при монтировании компонента
onMounted(() => {
  initIntersectionObserver(media.value); // Инициализация на первоначальные данные
});

// Удаление всех наблюдателей при демонтаже компонента
onBeforeUnmount(() => {
  observers.forEach((observer) => observer.disconnect());
});

// Следим за изменениями в media и пересоздаем наблюдатели
watch(
    () => media.value, // Следим за изменениями в media
    (newMedia) => {
      initIntersectionObserver(newMedia); // Заново инициализируем наблюдатели при изменениях
    },
    { deep: true } // Указываем deep, если media - это вложенный объект
);
</script>

<template>
  <div class="library">
    <div class="library-header">
      <label class="search-label">
        <input
            v-model="searchQuery"
            name="title"
            type="text"
            placeholder="Search images and video"
            required
        />
        <img
            class="search-img"
            src="../assets/img/icons/search.svg"
        />
      </label>
      <a
          :class="['text-button filter-button', {'active': filterIsOpen}]"
          @click="filterIsOpen = !filterIsOpen"
      >
        <img
            class="img"
            src="../assets/img/icons/filter.svg"
        />
      </a>
    </div>
    <div :class="['library-filters', {'active': filterIsOpen}]">
      <div class="library-filters-wrapper">
        <div v-click-outside="closeCategoryDropdown" class="select-container">
          <div class="select" @click="selectDropdownIsActive = !selectDropdownIsActive">
            <div class="desc small">{{ mediaCategory || $t('Category') }}</div>
            <img
                src="../assets/img/arrow-down-dark.svg"
                class="img"
            >
          </div>
          <div :class="['settings-dropdown select-dropdown', {'active': selectDropdownIsActive}]">
            <div class="select-dropdown-wrapper">
              <div class="checkbox-list">
                <label class="checkbox-label" v-for="category in categories" :key="category">
                  <div :class="['category', 'desc', 'small', {active: searchCategory === category}]"
                       @click="searchCategory = category">{{ $t(category) }}
                  </div>
                </label>
              </div>
              <div class="buttons">
                <button @click="mediaCategory = null; searchCategory = null" class="button extrasmall lined">
                  <span>{{ $t('Clear') }}</span>
                </button>
                <button @click="mediaCategory = searchCategory; selectDropdownIsActive = false;"
                        class="button extrasmall">
                  <span>{{ $t('Apply') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="list">
      <div @scroll="handleScroll" class="list-wrapper">
        <div :key="item.id" v-for="(item, i) in media" class="item">
          <a @click.prevent="addSceneToEnd(item)" class="text-button">
            <img class="img" src="../assets/img/icons/add.svg"/>
          </a>
          <a @click.prevent="replaceSceneBackground(item.mediaType, item.preview.url)" class="text-button">
            <img class="img" src="../assets/img/icons/replace.svg"/>
          </a>

          <div class="media-container" @mouseenter="playVideo(i)" @mouseleave="stopVideo(i)">
            <img
                v-if="item.mediaType !== 'video'"
                class="img"
                :src="item.preview.jpg"
            />
            <img
                v-else
                v-show="!showVideo[i]"
                :id="'image-' + i"
                class="img"
                :src="item.preview.jpg"
            />
            <video
                v-if="item.mediaType === 'video'"
                v-show="showVideo[i]"
                :id="'video-visuals-' + i"
                class="img"
                :src="item.preview.url"
                loop
                muted
                preload="none"
                :data-index="i"
            ></video>
          </div>

          <!-- Длительность медиа -->
          <div v-if="item.duration" class="desc small">
            {{ formatDuration(item.duration) }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
/* Ваши стили здесь */
</style>
