<script setup>
import {ref, watch, onMounted} from "vue";
// import axios from "@/axios";
import {useVideoStore} from "@/stores/video";
import {storeToRefs} from "pinia";

const videoStore = useVideoStore();
// const selectedFiles = ref([]);
// const isDragging = ref(false);
// const fileInput = ref(null);
// const currentAudio = ref(null); // Ссылка на текущий воспроизводимый аудио элемент
// const audioPlaying = ref(false); // Флаг для отслеживания состояния воспроизведения
const searchQuery = ref(""); // Реактивная переменная для отслеживания инпута
const currentAudio = ref(null); // Ссылка на текущий воспроизводимый аудио элемент
const audioPlaying = ref(false); // Флаг для отслеживания состояния воспроизведения


const {
  // fetchAudioUploads,
  fetchVoices
} = videoStore;

const {
  video,
  // audioUploads,
  voices,
  voiceSearch,
} = storeToRefs(videoStore);

const speaker = ref(video.value?.metadata?.audio?.aiVoiceOver?.speaker); // Create a ref to store the background music URI

let debounceTimeout = null; // Для хранения идентификатора таймера

const handleSearch = () => {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }
  debounceTimeout = setTimeout(() => {
    fetchVoices();
  }, 2000);
};

watch(
    () => video.value?.metadata?.audio?.aiVoiceOver?.speaker,
    (newSpeaker) => {
      speaker.value = newSpeaker;
    },
    {immediate: true}
);


// Воспроизведение и остановка аудио
const playAudio = async (url) => {
  if (currentAudio.value) {
    await currentAudio.value.pause(); // Останавливаем текущее аудио, если оно играет
    currentAudio.value.currentTime = 0;
  }

  if (audioPlaying.value) {
    // Если аудио уже играет, остановить его
    await currentAudio.value.pause();
    audioPlaying.value = false;
  } else {
    // Создать новый аудио элемент и воспроизвести
    currentAudio.value = new Audio(url);
    await currentAudio.value.play();
    audioPlaying.value = true;

    currentAudio.value.onended = () => {
      audioPlaying.value = false; // Сбрасываем флаг после окончания аудио
    };
  }
};

const setSpeaker = (speaker) => {
  video.value.metadata.audio.aiVoiceOver = {
    ...video.value.metadata.audio.aiVoiceOver,
    speaker
  };
};

watch(searchQuery, (newQuery) => {
  voiceSearch.value = newQuery; // Обновляем значение в store
  handleSearch(); // Запускаем дебаунс функцию
});

onMounted(() => {
  fetchVoices();
})
</script>

<template>
  <div class="library library2">
    <div class="library-header">
      <label class="search-label">
        <input
            v-model="searchQuery"
            name="title"
            type="text"
            :placeholder="$t('Search tracks')"
            required
        />
        <img
            class="search-img"
            src="./../assets/img/icons/search.svg"
        />
      </label>
    </div>
    <div class="list audio-list">
      <div class="list-wrapper">
        <div :class="['item', {'active': name === speaker}]" v-for="([name, url]) in Object.entries(voices)" :key="url">
          <div class="left">
            <img
                v-if="audioPlaying && currentAudio?.src === url"
                src="../assets/img/icons/stop.svg"
                class="img"
                @click="playAudio(url)"
            >
            <img
                v-else
                src="../assets/img/icons/play3.svg"
                class="img"
                @click="playAudio(url)"
            >
            <div class="text">
              <div class="desc small">
                <b>{{ $t(name) }}</b>
              </div>
<!--              <div class="desc small">-->
<!--                {{ $t('Abstract, Beautiful,Abstract, Beautiful ') }}-->
<!--              </div>-->
            </div>
          </div>
          <div v-if="name === speaker" class="time">
            <div class="applied">
              <div class="desc small">
                <b>{{ $t('Applied') }}</b>
              </div>
              <img
                  src="./../assets/img/icons/smallCross.svg"
                  class="img"
                  @click="setSpeaker(null)"
              >
            </div>
<!--            <div class="desc small">{{ $t('03:43') }}</div>-->
          </div>
          <a v-else class="button extrasmall" @click.prevent="setSpeaker(name)">
            <span>{{ $t('Apply') }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Ваши стили */
</style>
