<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_420_840)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22758 1.59998H18.8467C21.8304 1.59998 23.4737 3.23978 23.4737 6.21718V14.0569L23.4704 14.0564C23.4329 14.4854 23.072 14.822 22.6324 14.822C22.1767 14.822 21.8056 14.4604 21.7915 14.0091L21.7911 14.0091V9.9949H3.28313V18.8096C3.28313 20.8691 4.16366 21.7478 6.22758 21.7478H14.036L14.036 21.7482C14.4883 21.7622 14.8506 22.1325 14.8506 22.5873C14.8506 23.026 14.5134 23.3861 14.0834 23.4236L14.0839 23.4268H6.22758C3.24387 23.4268 1.60059 21.787 1.60059 18.8096V6.21718C1.60059 3.23978 3.24387 1.59998 6.22758 1.59998ZM21.7911 8.31591V6.21718C21.7911 4.15763 20.9106 3.27896 18.8467 3.27896H16.7435V8.31591H21.7911ZM10.0133 3.27896V8.31591H15.0609V3.27896H10.0133ZM8.33076 3.27896H6.22758C4.16366 3.27896 3.28313 4.15763 3.28313 6.21718V8.31591H8.33076V3.27896Z" fill="#2E263F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3397 11.6045L15.8719 13.7614C16.1383 13.9236 16.3574 14.1514 16.5102 14.423C16.6629 14.6946 16.7432 15.0008 16.7432 15.3122C16.7432 15.6237 16.6629 15.9299 16.5102 16.2015C16.3574 16.473 16.1372 16.7009 15.8708 16.8631L12.3397 19.0189C12.052 19.1947 11.7225 19.2908 11.3851 19.2973C11.0478 19.3038 10.7148 19.2205 10.4205 19.0558C10.1253 18.8937 9.87959 18.655 9.7092 18.365C9.5388 18.0749 9.4501 17.7444 9.45246 17.4082V13.2152C9.4501 12.879 9.5388 12.5484 9.7092 12.2584C9.87959 11.9684 10.1253 11.7297 10.4205 11.5675C10.7148 11.403 11.0478 11.3197 11.3851 11.3262C11.7224 11.3327 12.0519 11.4288 12.3397 11.6045ZM11.4614 17.5873L14.9925 15.4303C15.0137 15.4184 15.0311 15.401 15.043 15.3799C15.0549 15.3587 15.0607 15.3348 15.0598 15.3106C15.0605 15.2867 15.0546 15.2631 15.0427 15.2424C15.0309 15.2216 15.0135 15.2046 14.9925 15.193L11.4614 13.0361C11.428 13.0147 11.389 13.0034 11.3492 13.0036C11.3123 13.0042 11.2761 13.0138 11.2438 13.0316C11.2097 13.0486 11.1811 13.0749 11.1613 13.1073C11.1415 13.1398 11.1312 13.1772 11.1316 13.2152V17.4037C11.1312 17.4417 11.1415 17.4791 11.1613 17.5116C11.1811 17.544 11.2097 17.5703 11.2438 17.5873C11.2765 17.6075 11.3142 17.6182 11.3526 17.6182C11.3911 17.6182 11.4287 17.6075 11.4614 17.5873Z" fill="#2E263F"/>
  <path d="M23.0695 21.6768C23.2273 21.5194 23.3159 21.3058 23.3159 21.0832C23.3159 20.8605 23.2273 20.647 23.0695 20.4896C22.9117 20.3321 22.6978 20.2437 22.4746 20.2437H21.9138V19.684C21.9138 19.4614 21.8252 19.2479 21.6674 19.0904C21.5096 18.933 21.2956 18.8445 21.0725 18.8445C20.8494 18.8445 20.6354 18.933 20.4777 19.0904C20.3199 19.2479 20.2313 19.4614 20.2313 19.684V20.2437H19.6704C19.4473 20.2437 19.2333 20.3321 19.0755 20.4896C18.9178 20.647 18.8291 20.8605 18.8291 21.0832C18.8291 21.3058 18.9178 21.5194 19.0755 21.6768C19.2333 21.8342 19.4473 21.9227 19.6704 21.9227H20.2313V22.4823C20.2313 22.705 20.3199 22.9185 20.4777 23.076C20.6354 23.2334 20.8494 23.3218 21.0725 23.3218C21.2956 23.3218 21.5096 23.2334 21.6674 23.076C21.8252 22.9185 21.9138 22.705 21.9138 22.4823V21.9227H22.4746C22.6978 21.9227 22.9117 21.8342 23.0695 21.6768Z" fill="#2E263F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1124 16.6624C18.9886 16.0782 20.0187 15.7664 21.0725 15.7664C22.4851 15.7682 23.8393 16.3289 24.8381 17.3256C25.8369 18.3223 26.3988 19.6736 26.4006 21.0832C26.4006 22.1348 26.0881 23.1627 25.5026 24.037C24.9172 24.9114 24.0851 25.5928 23.1115 25.9953C22.1379 26.3977 21.0666 26.503 20.0331 26.2978C18.9995 26.0927 18.0502 25.5863 17.305 24.8427C16.5599 24.0992 16.0524 23.1518 15.8469 22.1204C15.6413 21.0891 15.7468 20.0201 16.15 19.0485C16.5533 18.077 17.2362 17.2467 18.1124 16.6624ZM19.0472 24.1079C19.6467 24.5076 20.3515 24.721 21.0725 24.721C22.039 24.7198 22.9656 24.3362 23.649 23.6542C24.3324 22.9722 24.7169 22.0476 24.718 21.0832C24.718 20.3637 24.5042 19.6604 24.1037 19.0621C23.7031 18.4639 23.1337 17.9976 22.4676 17.7223C21.8015 17.447 21.0685 17.3749 20.3613 17.5153C19.6542 17.6557 19.0046 18.0021 18.4948 18.5109C17.9849 19.0196 17.6377 19.6678 17.4971 20.3735C17.3564 21.0792 17.4286 21.8106 17.7045 22.4753C17.9804 23.14 18.4477 23.7082 19.0472 24.1079Z" fill="#2E263F"/>
  </g>
  <defs>
  <clipPath id="clip0_420_840">
  <rect width="28" height="28" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>


  