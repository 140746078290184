<template>
  <main class="home-page">
    <section class="section hero-section">
      <img 
        src="./../assets/img/decor1.png" 
        class="decor1"
      >
      <img 
        src="./../assets/img/decor2.png" 
        class="decor2"
      >
      <div class="wrapper">
        <div class="desc tag">{{ $t('Welcome to ') }} {{ hostname }}</div>
        <div class="title">
          <b>{{ $t('Unleash Your Creativity with AI-Powered Content Creation') }}</b>
        </div>
        <div class="desc">{{ $t('Create High-Quality Texts Effortlessly') }}</div>
        <button 
          class="button button-get-started"
          @click="modalsStore.openSignIn()"
        >
          <div class="ball"></div>
          <b>{{ $t('GET STARTED') }}</b>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_259_1697)">
            <path d="M31.6334 15.1152C31.633 15.1148 31.6327 15.1144 31.6322 15.114L25.1007 8.614C24.6114 8.12706 23.82 8.12887 23.3329 8.61825C22.8459 9.10756 22.8478 9.899 23.3371 10.386L27.7224 14.75H1.25C0.559625 14.75 0 15.3096 0 16C0 16.6904 0.559625 17.25 1.25 17.25H27.7223L23.3372 21.614C22.8479 22.101 22.846 22.8924 23.333 23.3817C23.8201 23.8712 24.6116 23.8729 25.1008 23.386L31.6323 16.886C31.6327 16.8856 31.633 16.8852 31.6334 16.8848C32.123 16.3962 32.1214 15.6022 31.6334 15.1152Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_259_1697">
            <rect width="32" height="32" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </button>
        <div class="text-container">
          <img 
            src="./../assets/img/text.svg" 
            class="text"
          >
        </div>
      </div>
      <div class="mouse" @click="scrollToSection('#howItWorks')">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.9165 39.5604C19.7417 39.5604 19.574 39.491 19.4503 39.3673C19.3267 39.2437 19.2572 39.076 19.2572 38.9011V33.3802C19.2572 33.2054 19.3267 33.0377 19.4503 32.914C19.574 32.7904 19.7417 32.7209 19.9165 32.7209C20.0914 32.7209 20.2591 32.7904 20.3828 32.914C20.5064 33.0377 20.5759 33.2054 20.5759 33.3802V38.9187C20.5713 39.0905 20.4998 39.2537 20.3767 39.3736C20.2535 39.4935 20.0884 39.5605 19.9165 39.5604Z" fill="black"/>
          <path d="M19.9164 39.5605C19.755 39.5615 19.5988 39.5037 19.4769 39.3978L15.9604 36.3209C15.8287 36.2043 15.7486 36.0402 15.7379 35.8646C15.7272 35.689 15.7867 35.5164 15.9033 35.3846C16.0198 35.2529 16.184 35.1729 16.3596 35.1622C16.5351 35.1514 16.7078 35.2109 16.8395 35.3275L20.356 38.4044C20.487 38.5203 20.5669 38.6832 20.5784 38.8577C20.5899 39.0322 20.5321 39.2042 20.4175 39.3363C20.3552 39.4077 20.2781 39.4647 20.1916 39.5034C20.1051 39.5421 20.0112 39.5616 19.9164 39.5605Z" fill="black"/>
          <path d="M19.9164 39.5605C19.8224 39.5609 19.7294 39.5412 19.6437 39.5025C19.558 39.4638 19.4816 39.4071 19.4197 39.3363C19.3052 39.2042 19.2474 39.0322 19.2589 38.8577C19.2704 38.6832 19.3503 38.5203 19.4813 38.4044L22.9978 35.3275C23.1295 35.2109 23.3021 35.1514 23.4777 35.1622C23.6533 35.1729 23.8174 35.2529 23.934 35.3846C24.0506 35.5164 24.1101 35.689 24.0994 35.8646C24.0886 36.0402 24.0086 36.2043 23.8769 36.3209L20.3604 39.3978C20.2374 39.5047 20.0794 39.5626 19.9164 39.5605Z" fill="black"/>
          <path d="M19.9165 7.47253C19.7417 7.47253 19.574 7.40306 19.4503 7.27941C19.3267 7.15576 19.2572 6.98806 19.2572 6.81319V1.27033C19.2572 1.09547 19.3267 0.927759 19.4503 0.804109C19.574 0.680458 19.7417 0.610992 19.9165 0.610992C20.0914 0.610992 20.2591 0.680458 20.3828 0.804109C20.5064 0.927759 20.5759 1.09547 20.5759 1.27033V6.80879C20.5765 6.89575 20.5598 6.98196 20.527 7.06246C20.4941 7.14297 20.4456 7.21617 20.3843 7.27786C20.323 7.33955 20.2502 7.38851 20.1699 7.42192C20.0896 7.45533 20.0035 7.47253 19.9165 7.47253Z" fill="black"/>
          <path d="M16.3868 5.01537C16.252 5.0166 16.1202 4.97652 16.0089 4.90054C15.8977 4.82456 15.8124 4.71632 15.7645 4.59039C15.7166 4.46446 15.7084 4.32689 15.7411 4.19619C15.7738 4.06548 15.8457 3.94792 15.9472 3.85933L19.4637 0.782408C19.5278 0.724106 19.6027 0.678996 19.6842 0.649653C19.7657 0.62031 19.8522 0.607308 19.9387 0.611389C20.0253 0.615471 20.1101 0.636557 20.1885 0.673442C20.2669 0.710327 20.3373 0.76229 20.3956 0.826364C20.4539 0.890437 20.499 0.965367 20.5283 1.04687C20.5576 1.12838 20.5707 1.21487 20.5666 1.3014C20.5625 1.38793 20.5414 1.47281 20.5045 1.5512C20.4676 1.62958 20.4157 1.69993 20.3516 1.75823L16.8351 4.83516C16.7125 4.94764 16.5531 5.01169 16.3868 5.01537Z" fill="black"/>
          <path d="M23.4417 5.01538C23.2803 5.01642 23.124 4.9586 23.0022 4.85274L19.4857 1.77582C19.3539 1.65924 19.2739 1.49511 19.2632 1.31952C19.2525 1.14394 19.312 0.97129 19.4285 0.839556C19.5451 0.707822 19.7092 0.627794 19.8848 0.617078C20.0604 0.606361 20.2331 0.665834 20.3648 0.782413L23.8813 3.85934C23.9828 3.94793 24.0547 4.06549 24.0874 4.19619C24.12 4.3269 24.1119 4.46447 24.064 4.5904C24.0161 4.71633 23.9308 4.82457 23.8196 4.90055C23.7083 4.97653 23.5764 5.0166 23.4417 5.01538Z" fill="black"/>
          <path d="M22.0836 30.5714H17.7495C16.1617 30.5703 14.6391 29.9393 13.5159 28.8169C12.3927 27.6946 11.7606 26.1725 11.7583 24.5846V15.6044C11.7606 14.0166 12.3927 12.4945 13.5159 11.3721C14.6391 10.2497 16.1617 9.61875 17.7495 9.61758H22.0836C23.6707 9.61991 25.1921 10.2514 26.3143 11.3737C27.4366 12.4959 28.0681 14.0173 28.0704 15.6044V24.5846C28.0681 26.1717 27.4366 27.6931 26.3143 28.8154C25.1921 29.9376 23.6707 30.5691 22.0836 30.5714ZM17.7495 10.9363C16.5114 10.9374 15.3243 11.4295 14.4484 12.3045C13.5725 13.1796 13.0793 14.3663 13.077 15.6044V24.5846C13.0793 25.8227 13.5725 27.0094 14.4484 27.8845C15.3243 28.7595 16.5114 29.2516 17.7495 29.2528H22.0836C23.3213 29.2516 24.508 28.7594 25.3832 27.8842C26.2584 27.009 26.7505 25.8223 26.7517 24.5846V15.6044C26.7505 14.3667 26.2584 13.18 25.3832 12.3048C24.508 11.4296 23.3213 10.9374 22.0836 10.9363H17.7495Z" fill="black"/>
          <path d="M19.9165 18.7165C19.7417 18.7165 19.574 18.647 19.4503 18.5234C19.3267 18.3997 19.2572 18.232 19.2572 18.0572V15.1077C19.2572 14.9328 19.3267 14.7651 19.4503 14.6415C19.574 14.5178 19.7417 14.4484 19.9165 14.4484C20.0914 14.4484 20.2591 14.5178 20.3828 14.6415C20.5064 14.7651 20.5759 14.9328 20.5759 15.1077V18.0572C20.5759 18.232 20.5064 18.3997 20.3828 18.5234C20.2591 18.647 20.0914 18.7165 19.9165 18.7165Z" fill="black"/>
        </svg>
      </div>
    </section>
    <section class="section how-it-works-section" id="howItWorks">
      <img 
        src="./../assets/img/decorHow1.png" 
        class="decor1"
      >
      <img 
        src="./../assets/img/decorHow2.png" 
        class="decor2"
      >
      <div class="wrapper">
        <div class="left">
          <div class="title big">
            <b>{{ $t('How It Works') }}</b>
          </div>
          <button 
            class="button button-get-started"
            @click="modalsStore.openSignIn()"
          >
            <div class="ball"></div>
            <b>{{ $t('GET STARTED') }}</b>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_259_1697)">
              <path d="M31.6334 15.1152C31.633 15.1148 31.6327 15.1144 31.6322 15.114L25.1007 8.614C24.6114 8.12706 23.82 8.12887 23.3329 8.61825C22.8459 9.10756 22.8478 9.899 23.3371 10.386L27.7224 14.75H1.25C0.559625 14.75 0 15.3096 0 16C0 16.6904 0.559625 17.25 1.25 17.25H27.7223L23.3372 21.614C22.8479 22.101 22.846 22.8924 23.333 23.3817C23.8201 23.8712 24.6116 23.8729 25.1008 23.386L31.6323 16.886C31.6327 16.8856 31.633 16.8852 31.6334 16.8848C32.123 16.3962 32.1214 15.6022 31.6334 15.1152Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_259_1697">
              <rect width="32" height="32" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div class="list">
          <div class="item">
            <img 
              src="./../assets/img/step/1.svg" 
              class="img"
            >
            <div class="title small">
              <b>{{ $t('Input Your Topic') }}</b>
            </div>
            <div class="desc">
              {{ $t('Simply enter your topic or keywords, and our AI will generate engaging text content tailored to your needs.') }}
            </div>
          </div>
          <div class="item">
            <img 
              src="./../assets/img/step/2.svg" 
              class="img"
            >
            <div class="title small">
              <b>{{ $t('Generate Texts') }}</b>
            </div>
            <div class="desc">
              {{ $t('Use our AI to create high-quality text content in seconds.') }}
            </div>
          </div>
          <div class="item">
            <img 
              src="./../assets/img/step/3.svg" 
              class="img"
            >
            <div class="title small">
              <b>{{ $t('Review and Download') }}</b>
            </div>
            <div class="desc">
              {{ $t('Review the generated content and download it for immediate use on your website, blog, or social media platforms.') }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section features-section">
      <img 
        src="./../assets/img/featuresDecor1.png" 
        class="decor1"
      >
      <div class="wrapper">
        <div class="left">
          <div class="item">
            <div  class="title small">
              <b>{{ $t('AI-Generated Texts') }}</b>
            </div>
            <div class="item-content">
              <ul>
                <li class="desc">
                  {{ $t('Create relevant and engaging text content with ease.') }}
                </li>
                <li class="desc">
                  {{ $t('Customize the tone, style, and length to suit your needs.') }}
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div  class="title small">
              <b>{{ $t('AI-Generated Header Images') }}</b>
            </div>
            <div class="item-content">
              <ul>
                <li class="desc">
                  {{ $t('Generate visually appealing images to complement your text.') }}
                </li>
                <li class="desc">
                  {{ $t('Customize the tone, style, and length to suit your needs.') }}
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div  class="title small">
              <b>{{ $t('Video Creation from Scripts') }}</b>
            </div>
            <div class="item-content">
              <ul>
                <li class="desc">
                  {{ $t('Transform your scripts or articles into engaging videos using our AI tools.') }}
                </li>
                <li class="desc">
                  {{ $t('Edit and enhance videos directly on the platform.') }}
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div  class="title small">
              <b>{{ $t('User-Friendly Interface') }}</b>
            </div>
            <div class="item-content">
              <ul>
                <li class="desc">
                  {{ $t('Simple and intuitive design for a seamless user experience.') }}
                </li>
                <li class="desc">
                  {{ $t('No technical skills required.') }}
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div  class="title small">
              <b>{{ $t('Boost Your Reach') }}</b>
            </div>
            <div class="item-content">
              <ul>
                <li class="desc">
                  {{ $t('Enhance the visibility of personal blogs and websites with optimized content.') }}
                </li>
                <li class="desc">
                  {{ $t('Improve SEO with AI-generated ad copy and keyword-rich texts.') }}
                </li>
                <li class="desc">
                  {{ $t('Aid in academic projects by generating well-structured and informative content.') }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title big">
            <b>Features</b>
          </div>
          <img 
            src="./../assets/img/features.png" 
            class="img"
          >
        </div>
      </div>
    </section>
    <section class="section presence-section">
      <img 
        src="./../assets/img/presenceDecor1.png" 
        class="decor1"
      >
      <div class="wrapper">
        <div class="left">
          <div class="desc tag">{{ $t('Discover the Benefits') }}</div>
          <div class="title big">
            <b>{{ $t('Maximize Your Online Presence') }}</b>
          </div>
          <button 
            class="button button-get-started"
            @click="modalsStore.openSignIn()"
          >
            <div class="ball"></div>
            <b>{{ $t('GET STARTED') }}</b>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_259_1697)">
              <path d="M31.6334 15.1152C31.633 15.1148 31.6327 15.1144 31.6322 15.114L25.1007 8.614C24.6114 8.12706 23.82 8.12887 23.3329 8.61825C22.8459 9.10756 22.8478 9.899 23.3371 10.386L27.7224 14.75H1.25C0.559625 14.75 0 15.3096 0 16C0 16.6904 0.559625 17.25 1.25 17.25H27.7223L23.3372 21.614C22.8479 22.101 22.846 22.8924 23.333 23.3817C23.8201 23.8712 24.6116 23.8729 25.1008 23.386L31.6323 16.886C31.6327 16.8856 31.633 16.8852 31.6334 16.8848C32.123 16.3962 32.1214 15.6022 31.6334 15.1152Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_259_1697">
              <rect width="32" height="32" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div class="right">
          <div class="item">
            <div  class="title small">
              <b>{{ $t('Increase Your Visibility') }}</b>
            </div>
            <div class="desc">
              {{ $t('Our AI-generated content is optimized to attract more visitors and keep them engaged.') }}
            </div>
          </div>
          <div class="item">
            <div  class="title small">
              <b>{{ $t('Support Your Learning Goals') }}</b>
            </div>
            <div class="desc">
              {{ $t('Whether you\'re a student or a professional, generate high-quality content for your academic and professional projects.') }}
            </div>
          </div>
          <div class="item">
            <div  class="title small">
              <b>{{ $t('Improve SEO Efforts') }}</b>
            </div>
            <div class="desc">
              {{ $t('Boost your search engine rankings with keyword-rich texts and compelling ad copy.') }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section features-section get-started-section">
      <img 
        src="./../assets/img/decorHow1.png" 
        class="decor1"
      >
      <img 
        src="./../assets/img/decorHow2.png" 
        class="decor2"
      >
      <div class="wrapper">
        <div class="left">
          <img 
            src="./../assets/img/get-started.png" 
            class="img"
          >
        </div>
        <div class="right">
          <div class="title">
            <b>{{ $t('Get Started Today') }}</b>
          </div>
          <div class="item">
            <div  class="title small">
              <b>{{ $t('Easy to Use') }}</b>
            </div>
            <div class="item-content">
              <ul>
                <li class="desc">
                  {{ $t('Sign up for free and explore our intuitive text generation tools.') }}
                </li>
                <li class="desc">
                  {{ $t('Create your first piece of content in minutes.') }}
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div  class="title small">
              <b>{{ $t('Grow Your Influence') }}</b>
            </div>
            <div class="item-content">
              <ul>
                <li class="desc">
                  {{ $t('Elevate your content strategy with high-quality, AI-generated texts.') }}
                </li>
                <li class="desc">
                  {{ $t('Reach a wider audience and enhance your online presence.') }}
                </li>
              </ul>
            </div>
          </div>
          <button 
            class="button button-get-started"
            @click="modalsStore.openSignIn()"
          >
            <div class="ball"></div>
            <b>{{ $t('GET STARTED') }}</b>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_259_1697)">
              <path d="M31.6334 15.1152C31.633 15.1148 31.6327 15.1144 31.6322 15.114L25.1007 8.614C24.6114 8.12706 23.82 8.12887 23.3329 8.61825C22.8459 9.10756 22.8478 9.899 23.3371 10.386L27.7224 14.75H1.25C0.559625 14.75 0 15.3096 0 16C0 16.6904 0.559625 17.25 1.25 17.25H27.7223L23.3372 21.614C22.8479 22.101 22.846 22.8924 23.333 23.3817C23.8201 23.8712 24.6116 23.8729 25.1008 23.386L31.6323 16.886C31.6327 16.8856 31.633 16.8852 31.6334 16.8848C32.123 16.3962 32.1214 15.6022 31.6334 15.1152Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_259_1697">
              <rect width="32" height="32" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import{getModalsStore} from './../stores/modals'
import {scroller} from 'vue-scrollto/src/scrollTo'


export default {
  name: 'HomePage',
  props: {
    hostname: {
      type: String,
      required: true
    },
  },
  components: {
  },
  data: function() {
    return {
      modalsStore: '',
      imgDomain: ''
    }
  },
  mounted() {
    this.modalsStore = getModalsStore();
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    scrollToSection(section) {
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/'});
      }
      setTimeout(()=> {
        const firstScrollTo = scroller()
        firstScrollTo(section)
      }, 200)
    },
  }
}
</script>