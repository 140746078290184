<template>
  <main class="page-inside pricing-page">
    <div class="section hero-section">
      <div class="wrapper">
        <div class="title">
          <b>{{ $t('Our pricing') }}</b>
        </div>
      </div>
    </div>
    <div class="section pricing-section">
      <div class="wrapper">
        <div class="common-nav">
          <a :class="['title small', { active: activeNavItem === 1 }]" @click.prevent="changeNavItem(1)">
            <b>{{ $t('Individual Services') }}</b>
          </a>
          <a :class="['title small', { active: activeNavItem === 2 }]" @click.prevent="changeNavItem(2)">
            <b>{{ $t('Discounted Packages') }}</b>
          </a>
        </div>

        <!-- Индивидуальные услуги -->
        <div class="topup-payment" v-if="activeNavItem === 1">
          <div class="pricing-cards">
            <div class="card">
              <div class="card-top">
                <div class="title small">
                  <b>{{ $t('Text') }}</b>
                </div>
                <div class="icon">
                  <img class="img" src="./../assets/img/icons/article.svg" />
                </div>
              </div>
              <div class="list">
                <div class="item">
                  <div class="desc small">
                    {{ $t('1000 characters') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('1 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('5000 characters') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('5 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('10,000 characters') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('10 EUR') }}
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-top">
                <div class="title small">
                  <b>{{ $t('Videos') }}</b>
                </div>
                <div class="icon">
                  <img class="img" src="./../assets/img/icons/video.svg" />
                </div>
              </div>
              <div class="list">
                <div class="item">
                  <div class="desc small">{{ $t('1 video') }}</div>
                  <div class="desc">
                    <b>{{ $t('10 EUR') }}</b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">{{ $t('3 videos') }}</div>
                  <div class="desc">
                    <b>{{ $t('30 EUR') }}</b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">{{ $t('5 videos') }}</div>
                  <div class="desc">
                    <b>{{ $t('50 EUR') }}</b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">{{ $t('10 videos') }}</div>
                  <div class="desc">
                    <b>{{ $t('100 EUR') }}</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-top">
                <div class="title small">
                  <b>{{ $t('Images') }}</b>
                </div>
                <div class="icon">
                  <img
                      class="img"
                      src="./../assets/img/icons/picture.svg"
                  />
                </div>
              </div>
              <div class="list">
                <div class="item">
                  <div class="desc small">
                    {{ $t('1 image') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('0.7 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('9 images') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('6.3 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('15 images') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('10.5 EUR') }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('30 images') }}
                  </div>
                  <div class="desc">
                    <b>
                      {{ $t('21 EUR') }}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Пакеты услуг -->
        <div class="topup-payment topup-payment-2" v-if="activeNavItem === 2">
          <div class="pricing-cards pricing-plans">
            <div :class="['card', pack.name.toLowerCase()]" :key="pack.id" v-for="(pack, i) in packs" class="card">
              <div class="card-top">
                <div class="title small">
                  <b>{{ $t(pack.name) }}</b> {{ $t('Package') }}
                </div>
                <div class="icon">
                  <div class="desc small">
                    <b>{{ i + 1 }}</b>
                  </div>
                </div>
              </div>
              <div class="desc small">
                {{ $t('Includes:') }}
              </div>
              <div class="list">
                <div class="item">
                  <div class="desc small">
                    {{ $t('characters of text ') }}
                  </div>
                  <div class="desc small">
                    <b>
                      {{ pack.article_characters_amount }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('videos') }}
                  </div>
                  <div class="desc small">
                    <b>
                      {{ pack.videos_amount }}
                    </b>
                  </div>
                </div>
                <div class="item">
                  <div class="desc small">
                    {{ $t('images') }}
                  </div>
                  <div class="desc small">
                    <b>
                      {{ pack.article_images_amount }}
                    </b>
                  </div>
                </div>
              </div>
              <div class="card-bottom">
                <div class="left">
                  <!--                  <div class="desc extrasmall">{{ $t('51.00 EUR') }}</div>-->
                  <div class="title small">
                    <b>{{ `${pack.price} EUR` }}</b>
                  </div>
                </div>
                <div class="right">
                  <button @click="openTopUp(pack)" class="button extrasmall">
                    <span>{{ $t('GET') }}</span>
                  </button>
                </div>
              </div>
            </div>
<!--            <div class="card">-->
<!--              <div class="card-top">-->
<!--                <div class="title small">-->
<!--                  <b>{{ $t('Standard ') }}</b> {{ $t('Package') }}-->
<!--                </div>-->
<!--                <div class="icon">-->
<!--                  <div class="desc small">-->
<!--                    <b>2</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="desc small">{{ $t('Includes') }}:</div>-->
<!--              <div class="list">-->
<!--                <div class="item">-->
<!--                  <div class="desc small">{{ $t('characters of text ') }}</div>-->
<!--                  <div class="desc small">-->
<!--                    <b>{{ $t('5000') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <div class="desc small">{{ $t('videos') }}</div>-->
<!--                  <div class="desc small">-->
<!--                    <b>{{ $t('7') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <div class="desc small">{{ $t('images') }}</div>-->
<!--                  <div class="desc small">-->
<!--                    <b>{{ $t('15') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card-bottom">-->
<!--                <div class="left">-->
<!--                  <div class="desc extrasmall">{{ $t('105.00 EUR') }}</div>-->
<!--                  <div class="title small">-->
<!--                    <b>{{ $t('76.50 EUR') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="right">-->
<!--                  <button class="button extrasmall">-->
<!--                    <span>{{ $t('GET') }}</span>-->
<!--                  </button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card premium">-->
<!--              <div class="card-top">-->
<!--                <div class="title small">-->
<!--                  <b>{{ $t('Premium ') }}</b> {{ $t('Package') }}-->
<!--                </div>-->
<!--                <div class="icon">-->
<!--                  <div class="desc small">-->
<!--                    <b>3</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="desc small">{{ $t('Includes') }}:</div>-->
<!--              <div class="list">-->
<!--                <div class="item">-->
<!--                  <div class="desc small">{{ $t('characters of text') }}</div>-->
<!--                  <div class="desc small">-->
<!--                    <b>{{ $t('10 000') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <div class="desc small">{{ $t('videos') }}</div>-->
<!--                  <div class="desc small">-->
<!--                    <b>{{ $t('15') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                  <div class="desc small">{{ $t('images') }}</div>-->
<!--                  <div class="desc small">-->
<!--                    <b>{{ $t('30') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card-bottom">-->
<!--                <div class="left">-->
<!--                  <div class="desc extrasmall">{{ $t('250.00 EUR') }}</div>-->
<!--                  <div class="title small">-->
<!--                    <b>{{ $t('210.00 EUR') }}</b>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="right">-->
<!--                  <button class="button extrasmall">-->
<!--                    <span>{{ $t('GET') }}</span>-->
<!--                  </button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useDepositsStore } from "@/stores/deposits";
import { storeToRefs } from "pinia";
import {getAuthStore} from "@/stores/auth";
import {getModalsStore} from "@/stores/modals";

const depositsStore = useDepositsStore();
const authStore = getAuthStore();
const activeNavItem = ref(1);
const modalsStore = getModalsStore()

const { packs } = storeToRefs(depositsStore);
const { fetchDepositPacks } = depositsStore;

function changeNavItem(item) {
  activeNavItem.value = item;
}

const openTopUp = (pack) => {
  if (authStore.isAuth) {
    modalsStore.openTopupModal(null, pack)
  } else {
    modalsStore.openSignIn();
  }
}

onMounted(() => {
  if (!packs.value || !packs.value.length) {
    fetchDepositPacks();
  }
});
</script>
