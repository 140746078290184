<template>
  <div class="cabinet-wrapper">
    <div class="cabinet-profile-container">
      <div class="title regular">
        <b>{{ $t('My profile') }}</b>
      </div>
      <div class="profile-bar">
        <div class="nav">
          <a :class="['desc nav-button', {'active': activeTab == 1}]" @click="activeTab = 1">
            <b>{{ $t('Personal information') }}</b>
          </a>
          <a :class="['desc nav-button', {'active': activeTab == 2}]" @click="activeTab = 2">
            <b>{{ $t('Change password') }}</b>
          </a>
          <a :class="['desc nav-button', {'active': activeTab == 3}]" @click="activeTab = 3">
            <b>{{ $t('Billing history') }}</b>
          </a>
        </div>
        <a @click.prevent="profileStore.logout()" class="nav-button">
          <img
              class="img"
              src="./../assets/img/logout.svg"
          />
          <div class="desc">
            <b>{{ $t('Log out') }}</b>
          </div>
        </a>
      </div>
      <div class="profile-content" v-if="activeTab == 1">
        <div class="profile-form">
          <Form :initial-values="initialValuesGeneral" :on-submit="submitProfile" :validation-schema="schemaProfileGeneral" class="label-list">
            <label>
              <div class="desc small gray"><b>{{ $t('Full name') }}:</b></div>
              <TextInput
                  name="full_name"
                  type="text"
                  required
              />
            </label>
            <label>
              <div class="desc small gray"><b>{{ $t('Email') }}:</b></div>
              <TextInput
                  name="email"
                  type="text"
                  required
              />
            </label>
            <label>
              <div class="desc small gray"><b>{{ $t('Phone') }}:</b></div>
              <TextInput
                  name="phone"
                  type="text"
                  required
              />
            </label>
            <button class="button extrasmall">
              <span>{{ $t('SAVE') }}</span>
            </button>
          </Form>
        </div>
        <div class="profile-form">
          <Form :initial-values="initialValuesOptional" :on-submit="submitProfile" :validation-schema="schemaProfileOptions" class="label-list">
            <label>
              <div class="desc small gray"><b>{{ $t('Country') }}:</b></div>
              <SelectInput
                  :options="countryOptions"
                  name="country_id"
                  required
              />
            </label>
            <label>
              <div class="desc small gray"><b>{{ $t('Address') }}:</b></div>
              <TextInput
                  name="address"
                  type="text"
                  required
              />
            </label>
            <label>
              <div class="desc small gray"><b>{{ $t('Post code') }}:</b></div>
              <TextInput
                  name="zip"
                  type="text"
                  required
              />
            </label>
            <button type="submit" class="button extrasmall">
              <span>{{ $t('SAVE') }}</span>
            </button>
          </Form>
        </div>
      </div>
      <div class="profile-content" v-if="activeTab == 2">
        <div class="profile-form">
          <Form :on-submit="submitPasswordChange" :validation-schema="schemaPasswordChange" class="label-list">
            <label>
              <div class="desc small gray"><b>{{ $t('Current password') }}:</b></div>
              <TextInput
                  name="cur_password"
                  type="password"
                  required
              />
            </label>
            <label>
              <div class="desc small gray"><b>{{ $t('New password') }}:</b></div>
              <TextInput
                  name="new_password"
                  type="password"
                  required
              />
            </label>
            <label>
              <div class="desc small gray"><b>{{ $t('Confirm new password') }}:</b></div>
              <TextInput
                  name="repeat_password"
                  type="password"
                  required
              />
            </label>
            <button type="submit" class="button extrasmall">
              <span>{{ $t('SAVE') }}</span>
            </button>
          </Form>
        </div>
      </div>
      <div class="profile-content" v-if="activeTab == 3">
        <div class="profile-history">
          <table>
            <thead>
            <tr>
              <th class="desc small">
                <b>{{ $t("#") }}</b>
              </th>
              <th class="desc small">
                <b>{{ $t("Date") }}</b>
              </th>
              <th class="desc small">
                <b>{{ $t("Info") }}</b>
              </th>
              <th class="desc small">
                <b>{{ $t("Amount") }}</b>
              </th>
              <th class="desc small">
                <b>{{ $t("Status") }}</b>
              </th>
            </tr>
            </thead>
            <tbody>

            <tr :key="deposit.id" v-for="deposit in deposits" >
              <td class="desc small">{{ deposit.id }}</td>
              <td class="desc small">{{ formatDate(deposit.created_at) }}</td>
              <td class="desc small">{{ $t('Top up balance ') }}</td>
              <td class="desc small">
                <b>${{ deposit.sum }}</b>
              </td>
              <td class="desc small">
                <img v-if="deposit.status === 'Success' || deposit.status === 'Pending'"
                    class="img"
                    src="./../assets/img/icons/successIcon.svg"
                />
                <img
                    v-else-if="deposit.status === 'Error'"
                    class="img"
                    src="./../assets/img/icons/warningIcon.svg"
                />
              </td>
            </tr>

<!--            <tr>-->
<!--              <td class="desc small">1</td>-->
<!--              <td class="desc small">01.09.2024</td>-->
<!--              <td class="desc small">{{ $t('Top up balance ') }}</td>-->
<!--              <td class="desc small">-->
<!--                <b>$20.00</b>-->
<!--              </td>-->
<!--              <td class="desc small">-->
<!--                <img-->
<!--                    class="img"-->
<!--                    src="./../assets/img/icons/failIcon.svg"-->
<!--                />-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td class="desc small">1</td>-->
<!--              <td class="desc small">01.09.2024</td>-->
<!--              <td class="desc small">{{ $t('Top up balance ') }}</td>-->
<!--              <td class="desc small">-->
<!--                <b>$20.00</b>-->
<!--              </td>-->
<!--              <td class="desc small">-->
<!--                <img-->
<!--                    class="img"-->
<!--                    src="./../assets/img/icons/warningIcon.svg"-->
<!--                />-->
<!--              </td>-->
<!--            </tr>-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import TextInput from "@/components/TextInput.vue";
import { Form } from 'vee-validate';
import {useDepositsStore} from "@/stores/deposits";
import {storeToRefs} from "pinia";
import moment from "moment";
import * as Yup from "yup";
import axios from "@/axios";
import SelectInput from "@/components/SelectInput.vue";
import {getSettingsStore} from "@/stores/settings";
import {getProfileStore} from "@/stores/profile";

const schemaProfileGeneral = Yup.object().shape({
  full_name: Yup.string().required(),
  email: Yup.string().required().email(),
  phone: Yup.string().required(),
});

const schemaProfileOptions = Yup.object().shape({
  country_id: Yup.number().required(),
  address: Yup.string().required(),
  zip: Yup.string().required(),
});

const schemaPasswordChange = Yup.object().shape({
  cur_password: Yup.string().required('Current password is required.'),
  new_password: Yup.string()
      .required('New password is required.')
      .min(8, 'New password must be at least 8 characters long.'),
  repeat_password: Yup.string()
      .required('Please confirm your new password.')
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match.')
});


const depositStore = useDepositsStore();
const settingsStore = getSettingsStore();
const profileStore = getProfileStore();

// Reactive state using Composition API
const activeTab = ref(1);
const {deposits} = storeToRefs(depositStore);
const {fetchDeposits} = depositStore;
const {countries, fetchCountries} = settingsStore;
const {profile, fetchData: fetchProfile} = profileStore;

const submitProfile = async (values) => {
  try {
    await axios.put(process.env.VUE_APP_API + 'user/profile', values);
    fetchProfile();
  } catch (e) {
    console.error(e);
  }
}

const submitPasswordChange = async (values, {resetForm}) => {
  try {
    await axios.post(process.env.VUE_APP_API + 'user/change-password', values);
    resetForm();
  } catch (e) {
    console.error(e);
  }
}

const initialValuesGeneral = {
  full_name: `${profile.name} ${profile.surname}`,
  email: profile.email,
  phone: profile.phone
}

const initialValuesOptional = {
  country_id: profile.country_id,
  address: profile.address,
  zip: profile.zip
}

const countryOptions = computed(() => countries.map(({title, id}) => ({value: id, label: title})));

onMounted(() => {
  fetchDeposits();
  fetchCountries();
})

const formatDate = (date) => {
  return moment(date).format('DD.MM.YYYY');
};
</script>