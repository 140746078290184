<script setup>
import {ref, watch} from "vue";
import axios from "@/axios";
import {useVideoStore} from "@/stores/video";
import {storeToRefs} from "pinia";

const videoStore = useVideoStore();
const selectedFiles = ref([]);
const isDragging = ref(false);
const fileInput = ref(null);
const currentAudio = ref(null); // Ссылка на текущий воспроизводимый аудио элемент
const audioPlaying = ref(false); // Флаг для отслеживания состояния воспроизведения
const backgroundMusicUri = ref(null); // Create a ref to store the background music URI

const {
  fetchAudioUploads
} = videoStore;

const {
  video,
  audioUploads
} = storeToRefs(videoStore);

watch(
    () => video.value?.metadata?.audio?.backGroundMusicUri,
    (newUri) => {
      backgroundMusicUri.value = newUri; // Update the reactive ref when the watched value changes
    },
    { immediate: true } // Optional: run the watcher immediately on setup
);

const audioFileTypes = [
  "audio/mp3",
  "audio/wav",
  "audio/weba",
  "audio/mpeg"
];

const triggerFileInput = () => {
  fileInput.value.click();
};

const onFileChange = (event, mediaTypes) => {
  const files = Array.from(event.target.files);
  handleFiles(files, mediaTypes);
};

const onDragOver = () => {
  isDragging.value = true;
};

const onDragLeave = () => {
  isDragging.value = false;
};

const onDrop = (event, mediaTypes) => {
  isDragging.value = false;
  const files = Array.from(event.dataTransfer.files);
  handleFiles(files, mediaTypes);
};

const handleFiles = (files, mediaTypes) => {
  const validFiles = files.filter((file) => {
    const allowedTypes = mediaTypes;
    const maxSize = 1024 * 1024 * 1024; // 1GB

    if (!allowedTypes.includes(file.type)) {
      return false;
    }

    if (file.size > maxSize) {
      return false;
    }

    return true;
  });

  selectedFiles.value = [...selectedFiles.value, ...validFiles];

  if (validFiles.length > 0) {
    uploadFiles(validFiles);
  }
};

const uploadFiles = async (files) => {
  const formData = new FormData();
  formData.append('project_id', video.value.id);

  files.forEach((file) => {
    formData.append('content[]', file);
    formData.append('type[]', 'audio');
  });

  try {
    await axios.post('/video/uploads/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    fetchAudioUploads();
  } catch (error) {
    console.error('Upload error:', error);
  }
};

// Воспроизведение и остановка аудио
const playAudio = async (upload) => {
  if (currentAudio.value) {
    await currentAudio.value.pause(); // Останавливаем текущее аудио, если оно играет
    currentAudio.value.currentTime = 0;
  }

  if (audioPlaying.value) {
    // Если аудио уже играет, остановить его
    await currentAudio.value.pause();
    audioPlaying.value = false;
  } else {
    // Создать новый аудио элемент и воспроизвести
    currentAudio.value = new Audio(upload.url);
    await currentAudio.value.play();
    audioPlaying.value = true;

    currentAudio.value.onended = () => {
      audioPlaying.value = false; // Сбрасываем флаг после окончания аудио
    };
  }
};

// Форматирование времени
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

const setBackgroundMusicUri = (backGroundMusicUri) => {
  video.value.metadata.audio.backGroundMusicUri = backGroundMusicUri;
};

</script>

<template>
  <div class="library library2">
    <div
        class="library-header"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop($event, audioFileTypes)"
        :class="{ 'dragging': isDragging }"
    >
      <label class="upload-label">
        <input
            type="file"
            multiple
            @change="onFileChange($event, audioFileTypes)"
            ref="fileInput"
            style="display: none"
            :accept="audioFileTypes.join(',')"
        />
        <div @click="triggerFileInput" class="upload">
          <img
              src="../assets/img/icons/uploadVideo.svg"
              class="img"
          >
          <div class="desc small">
            <b>{{ $t('Drag and drop files or browse from your computer') }}</b>
          </div>
          <div class="desc small">{{ $t('Supported format types and sizes:') }}</div>
          <div class="desc small">
            {{ $t('MP3, WAV, WEBA up to 100 MB') }}
          </div>
        </div>
      </label>
    </div>
    <div class="list audio-list">
      <div class="list-wrapper">
        <div :class="['item', {'active': backgroundMusicUri === upload.url}]"
             v-for="upload in audioUploads" :key="upload.id">
          <div class="left">
            <img
                v-if="audioPlaying && currentAudio?.src === upload.url"
                src="../assets/img/icons/stop.svg"
                class="img"
                @click="playAudio(upload)"
            >
            <img
                v-else
                src="../assets/img/icons/play3.svg"
                class="img"
                @click="playAudio(upload)"
            >
            <div class="text">
              <div class="desc small">
                <b>{{ $t(upload.original_name) }}</b>
              </div>
            </div>
          </div>
<!--          {{ backgroundMusicUri }}-->
<!--          {{ upload.url }}-->
          <div v-if="backgroundMusicUri === upload.url" class="time">
            <div class="applied">
              <div class="desc small">
                <b>{{ $t('Applied') }}</b>
              </div>
              <img
                  src="../assets/img/icons/smallCross.svg"
                  class="img"
                  @click="setBackgroundMusicUri(null)"
              >
            </div>
            <div class="desc small">{{ formatTime(upload.duration) }}</div>
          </div>
          <a v-else class="button extrasmall" @click.prevent="setBackgroundMusicUri(upload.url)">
            <span>{{ $t('Apply') }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Ваши стили */
</style>
