import { defineStore } from 'pinia'
import axios from '@/axios';

export const getTopicsStore = defineStore('topicsStore', {
    state: () => ({
        topics: [],
        imageKeywordOptions: []
    }),
    actions: {
        async fetchTopics() {
            try {
                const response = await axios.get(process.env.VUE_APP_API + 'topics/index');
                this.topics = response.data.payload;
            } catch (err) {
                console.log(err);
            }
        },
        async fetchImageKeywordOptions() {
            try {
                const response = await axios.get(process.env.VUE_APP_API + 'image-keywords');
                this.imageKeywordOptions = response.data.payload;
            } catch (err) {
                console.log(err);
            }
        },
    },
});

