import { defineStore } from 'pinia'
import axios from 'axios'
import{getModalsStore} from './modals'
import router from '../router'


export const getStaticStore = defineStore('staticStore', {
  state: () => ({
    pages: [],
    textPageData: {},
    error: ''
  }),
  actions: {
    async fetchData() {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'static-pages');
        this.pages = response.data.payload;
      } catch (err) {
        this.error = err.message;
      }
    },
    goToPage(item) {
      let newItem = '';
      if (item == 'privacy') {
        for (let i = 0; i < this.pages.length; i++) {
          if (this.pages[i].title.toLowerCase().includes('privacy')) {
            newItem = this.pages[i].id;
          } 
        }
      } else if (item == 'terms') {
        for (let i = 0; i < this.pages.length; i++) {
          if (this.pages[i].title.toLowerCase().includes('terms')) {
            newItem = this.pages[i].id;
          } 
        }
      } else if (item == 'cookie') {
        for (let i = 0; i < this.pages.length; i++) {
          if (this.pages[i].title.toLowerCase().includes('cookie')) {
            newItem = this.pages[i].id;
          } 
        }
      } else {
        newItem = item
      }
      router.push({path: '/pages/' + newItem, params: {data: newItem}})
      this.getTextPageContent(newItem);
      getModalsStore().closeSignUp();
    },
    async getTextPageContent(id) {
      this.textPageData = {};
      try {
        const res = await axios.get(process.env.VUE_APP_API + 'static-pages/' + id);
        this.textPageData = res.data.payload;
      } catch (err) {
        this.error = err.message;
      }
    },
  },
});

