// stores/articleStore.js
import { defineStore } from 'pinia';
import {ref, computed, watch} from 'vue';
import axios from 'axios';
import {getProfileStore} from "@/stores/profile";

export const getArticleStore = defineStore('articleStore', () => {
    const article = ref(null);
    const articleId = ref(null);
    const articleGenerating = ref(true);
    const projects = ref([]);
    const projectsSearchQuery = ref('');
    const projectsFound = ref([]);
    let intervalId = null;

    const setArticle = (value) => {
        article.value = value;
    };

    // Устанавливаем статус генерации статьи
    const setArticleGenerating = (generating) => {
        articleGenerating.value = generating;
    };

    // Получение списка проектов
    const fetchProjects = async () => {
        try {
            const response = await axios.get(`${process.env.VUE_APP_API}project/index`);
            projects.value = response.data.payload;
        } catch (err) {
            console.log(err);
        }
    };

    const searchProjects = async () => {
        try {
            const response = await axios.get(`${process.env.VUE_APP_API}project/index`, {
                params: {
                    q: projectsSearchQuery.value,
                    limit: 20
                }
            });
            projectsFound.value = response.data.payload;
        } catch (err) {
            console.log(err);
        }
    }

    const pollArticleStatus = async () => {
        try {
            const {data: {payload}} = await axios.get(`${process.env.VUE_APP_API}article/${articleId.value}`);
            article.value = payload;

            if (['pending', 'generating_content'].includes(payload.status)) {
                setArticleGenerating(true);
            } else {
                const generating = articleGenerating.value;

                stopPolling();
                setArticleGenerating(false);
                clearInterval(intervalId);
                intervalId = null;

                if (generating) {
                    getProfileStore().fetchData();
                }

                fetchProjects();
            }
        } catch (err) {
            setArticleGenerating(false);
            clearInterval(intervalId);
            intervalId = null;
        }
    };

    watch(articleId, async (newValue) => {
        if (newValue) {
            stopPolling();
            await startPolling(newValue);
        }
    });

    // Запуск пуллинга с интервалом 3 секунды
    const startPolling = async () => {
        await pollArticleStatus(articleId.value);

        if (articleId.value && !intervalId) {
            intervalId = setInterval(pollArticleStatus, 3000);
        }
    };

    // Остановка пуллинга
    const stopPolling = () => {
        if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
        }
    };

    const articleIsLoading = computed(() => {
        return ['pending', 'generating_content'].includes(article.value?.status);
    });

    const articleContentNotGenerated = computed(() => {
        return ['pending', 'generating_content', 'headings'].includes(article.value?.status);
    });

    return {
        article,
        articleId,
        articleGenerating,
        projects,
        setArticle,
        setArticleGenerating,
        fetchProjects,
        startPolling,
        stopPolling,
        searchProjects,
        projectsSearchQuery,
        projectsFound,
        articleIsPending: articleIsLoading,
        articleContentNotGenerated
    };
});
