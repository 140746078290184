// stores/videoStore.js
import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import axios from '@/axios';
import { getModalsStore } from "@/stores/modals";

const pendingStatuses = ['preprocessing', 'rendering'];

export const useVideoStore = defineStore('videoStore', () => {
    const modalStore = getModalsStore();

    const video = ref(null);
    const videoId = ref(null);
    let skipMetadataUpdate = false;
    const successPullingCallback = ref(null);

    const videoGenerating = ref(false);
    const videoRendering = ref(false);
    const isManualPreviewSceneGeneration = ref(null);
    // const isManualPreviewVideoGeneration = ref(null);
    const projects = ref([]);
    const projectsSearchQuery = ref('');
    const projectsFound = ref([]);

    let intervalId = null;
    let progressIntervalId = null; // Новая переменная для интервала прогресса
    const fonts = ref([]);
    const visualUploads = ref([]);
    const audioUploads = ref([]);
    const selectedSceneNum = ref(null);
    const mediaCategory = ref(null);
    const mediaSearch = ref('');
    const mediaPage = ref(1);
    const media = ref([]);
    const backgroundType = ref(null);

    const voiceSearch = ref('');
    const voices = ref([]);

    // Переменные для отслеживания прогресса
    const progress = ref(0); // Прогресс от 0 до 100
    let maxPreprocessingTime = 60 * 1000; // 60 секунд для имитации прогресса на стадии preprocessing
    let startTime = null; // Начало отсчета времени

    const setVideo = (value) => {
        video.value = value;
    };

    function updateVideoWithoutWatch(value) {
        skipMetadataUpdate = true;
        video.value = value;
    }

    const setVideoGenerating = (generating) => {
        videoGenerating.value = generating;
    };

    const setVideoRendering = (rendering) => {
        videoGenerating.value = rendering;
    };

    // Получение списка проектов
    const fetchProjects = async () => {
        try {
            const response = await axios.get(`${process.env.VUE_APP_API}video/index`);
            projects.value = response.data.payload;
        } catch (err) {
            console.log(err);
        }
    };

    const searchProjects = async () => {
        try {
            const response = await axios.get(`${process.env.VUE_APP_API}video/index`, {
                params: {
                    q: projectsSearchQuery.value,
                    limit: 20
                }
            });
            projectsFound.value = response.data.payload;
        } catch (err) {
            console.log(err);
        }
    }

    const fetchFonts = async () => {
        try {
            const { data: { payload } } = await axios.get(`${process.env.VUE_APP_API}video/fonts`);
            fonts.value = payload;
        } catch (err) {
            console.log(err);
        }
    };

    const fetchUploads = async (type = null) => {
        try {
            const { data: { payload } } = await axios.get(`${process.env.VUE_APP_API}video/uploads/index`, {
                params: {
                    type
                }
            });
            return payload;
        } catch (err) {
            console.log(err);
        }
    };

    const fetchMedia = async (search = false) => {
        try {
            const { data: { payload } } = await axios.get(`${process.env.VUE_APP_API}video/media`, {
                params: {
                    page: mediaPage.value,
                    search: mediaSearch.value,
                    category: mediaCategory.value
                }
            });

            if (search) {
                media.value  = [...payload.data.searchResults]
            } else {
                media.value = [...media.value, ...payload.data.searchResults];
            }
        } catch (err) {
            console.log(err);
        }
    };

    const fetchVoices = async () => {
        try {
            const { data: { payload } } = await axios.get(`${process.env.VUE_APP_API}video/voices`, {
                params: {
                    search: voiceSearch.value
                }
            });
            voices.value = payload;
        } catch (err) {
            console.log(err);
        }
    }

    const fetchVisualUploads = async () => {
        visualUploads.value = await fetchUploads(['video', 'image']);
    };

    const fetchAudioUploads = async () => {
        audioUploads.value = await fetchUploads(['audio']);
    };

    const calculateProgress = () => {
        if (!video?.value || !(['preprocessing', 'rendering'].includes(video?.value.status))) return;

        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;

        // Рассчитываем прогресс на основе прошедшего времени и максимального времени обработки
        const preprocessingProgress = Math.min((elapsedTime / maxPreprocessingTime) * 100, 100).toFixed(2);
        progress.value = preprocessingProgress;

        if (progress.value == 100) {
            modalStore.changeLoadingModalText('Please wait a moment')
        }
    };

    const setSuccessPullingCallback = (cb) => {
        successPullingCallback.value = cb;
    }

    const clearCallback = () => {
        successPullingCallback.value = null;
    }

    const calculateMaxPreprocessingTime = (status, type) => {
        switch (status) {
            case 'preprocessing':
                if (type === 'article_to_video') {
                    maxPreprocessingTime = 3 * 60 * 1000;
                } else {
                    maxPreprocessingTime = 60 * 1000;
                }
                break;
            case 'rendering':
                maxPreprocessingTime = 10 * 60 * 1000;
                break;
            default:
                maxPreprocessingTime = 60 * 1000;
                break;
        }

        return maxPreprocessingTime; // Return the value
    };

    const getRenderedVideo = async (id) => {
            const response = await axios.get(process.env.VUE_APP_API + `video/${id}/download`, {
                responseType: 'blob'  // Указываем тип ответа 'blob', чтобы получить видео как файл
            });

            // Создаем Blob-URL для скачивания файла
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = 'video.mp4';  // Указываем имя скачиваемого файла
            document.body.appendChild(a);
            a.click();  // Запускаем клик на временной ссылке
            a.remove();  // Удаляем ссылку после скачивания

            // Освобождаем Blob-URL, чтобы предотвратить утечку памяти
            window.URL.revokeObjectURL(url);
    }

    const pollVideoStatus = async (id = null, initialLoad = false) => {
        if (id) {
            videoId.value = id;
        }

        try {
            const { data: { payload } } = await axios.get(`${process.env.VUE_APP_API}video/${videoId.value}`);

            if (initialLoad) {
                calculateMaxPreprocessingTime(payload.status, payload.type);
            }

            updateVideoWithoutWatch(payload);

            if (pendingStatuses.includes(payload.status)) {
                setVideoGenerating(true);

                if (payload.status === 'rendering') {
                    videoRendering.value = true;
                } else {
                    videoRendering.value = false;
                }

                if (initialLoad && !modalStore.loadingModal.show) {
                    if (payload.status === 'preprocessing') {
                        modalStore.openLoadingModal('Generating storyboard');
                    } else if (payload.status === 'rendering') {
                        modalStore.openLoadingModal('Rendering');
                    }
                }

                return null; // Возвращаем null, чтобы продолжить поллинг
            } else if (payload.status === 'preprocessing_done') {
                stopPolling();
                setVideoGenerating(false);
                progress.value = 100; // Если видео готово, прогресс 100%
                videoRendering.value = false;

                if (typeof successPullingCallback.value === 'function') {
                    await (successPullingCallback.value)(payload);
                }

                modalStore.closeLoadingModal();

                if (isManualPreviewSceneGeneration.value === 'generating') {
                    isManualPreviewSceneGeneration.value = 'success';
                }

                clearCallback();
                clearInterval(intervalId);
                clearInterval(progressIntervalId); // Очищаем интервал прогресса
                intervalId = null;
                progressIntervalId = null; // Обнуляем переменную прогресса

                return true;
            } else if (payload.status === 'rendering_done') {
                stopPolling();
                setVideoGenerating(false);
                progress.value = 100; // Если видео готово, прогресс 100%

                if (videoRendering.value) {
                    try {
                       await getRenderedVideo(videoId.value)
                    } catch (error) {
                        console.error('Ошибка при скачивании видео:', error);
                    }
                }

                if (typeof successPullingCallback.value === 'function') {
                    await (successPullingCallback.value)(payload);
                }

                modalStore.closeLoadingModal();

                clearCallback();
                clearInterval(intervalId);
                clearInterval(progressIntervalId); // Очищаем интервал прогресса
                intervalId = null;
                progressIntervalId = null; // Обнуляем переменную прогресса

                return true;
            }
        } catch (err) {
            modalStore.closeLoadingModal();

            if (isManualPreviewSceneGeneration.value === 'generating') {
                isManualPreviewSceneGeneration.value = 'error';
            }

            setVideoGenerating(false);
            clearCallback();
            clearInterval(intervalId);
            clearInterval(progressIntervalId); // Очищаем интервал прогресса
            intervalId = null;
            progressIntervalId = null; // Обнуляем переменную прогресса

            return false;
        }
    };


    const updateScenes = (newScenes) => {
        // Убедимся, что video и metadata существуют
        if (video?.value && video?.value?.metadata && Array.isArray(video?.value?.metadata.scenes)) {
            // Удаляем старые элементы и добавляем новые
            video.value.metadata.scenes.splice(0, video.value.metadata.scenes.length, ...newScenes);
        }
    };

    const updateScene = (values) => {
        video.value.metadata.scenes.splice(selectedSceneNum.value, 1, {
            ...video.value.metadata.scenes[selectedSceneNum.value],
            ...values
        });
    };

    const updateAllScenes = (values) => {
        video.value.metadata.scenes = video.value.metadata.scenes.map(scene => ({
            ...scene,
            ...values
        }));
    };

    const duplicateScene = () => {
        const duplicatedScene = { ...video.value.metadata.scenes[selectedSceneNum.value] };
        video.value.metadata.scenes.splice(selectedSceneNum.value + 1, 0, duplicatedScene);
    };

    const deleteScene = () => {
        // Проверяем, что в массиве больше одной сцены (нельзя удалять последнюю сцену)
        if (video.value.metadata.scenes.length > 1 && selectedSceneNum.value >= 0 && selectedSceneNum.value < video.value.metadata.scenes.length) {
            // Удаляем сцену по индексу selectedSceneNum
            video.value.metadata.scenes.splice(selectedSceneNum.value, 1); // Удаляем один элемент

            // Обновляем selectedSceneNum в зависимости от того, какая сцена была удалена
            if (selectedSceneNum.value >= video.value.metadata.scenes.length) {
                selectedSceneNum.value = video.value.metadata.scenes.length - 1; // Если удалена последняя сцена, переходим к последней
            } else if (selectedSceneNum.value === 0) {
                // Если была удалена первая сцена, следующей становится теперь вторая
                selectedSceneNum.value = 0; // Остаемся на первой
            } else {
                // Если удаляемая сцена была не первой, переходим на предыдущую
                selectedSceneNum.value -= 1; // Переходим к предыдущей сцене
            }
        }
    };

    const addScene = (newScene) => {
        // Убедимся, что video и metadata существуют
        if (video?.value && video?.value?.metadata && Array.isArray(video?.value?.metadata.scenes)) {
            // Добавляем новую сцену в конец массива сцен
            video.value.metadata.scenes.push(newScene);
        }
    };

    const cutScene = async (start_time, end_time) => {
        const {data: {payload: metadata}} = await axios.post(`${process.env.VUE_APP_API}video/${videoId.value}/cut-scene/${selectedSceneNum.value}`, {
            start_time,
            end_time
        });

        video.value = {
            ...video.value,
            metadata
        }
    }


    const replaceSceneBackground = (type, url) => {
        updateScene({
            backgroundType: type,
            backgroundUri: url,
        });
    };

    watch(videoId, async (newValue) => {
        if (newValue) {
            stopPolling();
            await startPolling(newValue);
        }
    });

    watch(
        () => video?.value?.metadata, // Отслеживаем изменения в metadata
        async (newMetadata) => {
            if (newMetadata) {
                if (!skipMetadataUpdate) {
                    try {
                        await axios.put(`${process.env.VUE_APP_API}video/${videoId.value}`, {
                            metadata: newMetadata, // Используем новую метаданные
                        });
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    skipMetadataUpdate = false;
                }
            } else if (skipMetadataUpdate) {
                skipMetadataUpdate = false;
            }
        },
        { deep: true } // Отслеживаем изменения в вложенных объектах
    );

    watch(() => progress.value, async (newProgress) => {
        modalStore.loadingModal.percent = newProgress;
    });

    // Запуск пуллинга с интервалом 2.5 секунды
    const startPolling = async () => {
        await pollVideoStatus(null, true);
        intervalId = setInterval(async () => {
            await pollVideoStatus();
        }, 2500); // Пуллинг каждые 2.5 секунды

        // Запускаем интервал обновления прогресса каждую секунду
        startTime = Date.now(); // Запоминаем время начала
        progressIntervalId = setInterval(() => {
            calculateProgress(); // Обновляем прогресс
        }, 1000); // Обновляем прогресс каждую секунду
    };

    const stopPolling = () => {
        if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
        }
        if (progressIntervalId) {
            clearInterval(progressIntervalId);
            progressIntervalId = null; // Очищаем интервал прогресса
        }
    };

    const generatePreviewScene = async () => {
        stopPolling();
        modalStore.loadingModal.percent = 0;
        modalStore.openLoadingModal('Generating preview');

        try {
            isManualPreviewSceneGeneration.value = 'generating';
            await axios.post(`${process.env.VUE_APP_API}video/${videoId.value}/preview`);
            await startPolling();
        } catch (e) {
            modalStore.closeLoadingModal();
            console.log(e);
        }
    };

    const downloadVideo = async () => {
        stopPolling();
        modalStore.loadingModal.percent = 0;
        modalStore.openLoadingModal('Render video');
        videoRendering.value = true;

        try {
            await axios.post(`${process.env.VUE_APP_API}video/render`, {
                video_project_id: videoId.value
            });
            await startPolling();
        } catch (e) {
            videoRendering.value = false;
            modalStore.closeLoadingModal();
            console.log(e);
        }

    }

    const selectedScene = computed(() => {
        return scenes.value ? scenes.value[selectedSceneNum.value] || null : null;
    });

    const videoIsPending = computed(() => {
        return pendingStatuses.includes(video?.value?.status);
    });

    const scenes = computed(() => {
        return video?.value?.metadata?.scenes;
    });

    const scenesDuration = computed(() => {
        return video?.value?.metadata?.scenes?.map(({duration, minimumDuration}) =>
            (minimumDuration !== null && minimumDuration !== undefined) ? Math.round(minimumDuration) : Math.round(duration));
    });

    const actualScenesDuration = computed(() => {
        return video?.value?.metadata?.scenes?.map(({duration}) => Math.round(duration));
    });

    const videoDuration = computed(() => {
        return scenesDuration.value.reduce((acc, curr) => acc + curr, 0) || 0
    })

    const backgroundMusic = computed(() => {
        if (video?.value?.metadata?.audio?.backGroundMusicUri) {
            const audio = new Audio(video.value.metadata.audio.backGroundMusicUri);
            audio.loop = true; // Enable looping
            return audio;
        }
        return null;
    });
    const voiceOverMusic = computed(() => {
        return video?.value?.scenes?.audio?.tts ? new Audio(video?.value?.scenes?.audio?.tts) : null
    });

    const initialGeneration = computed(() => {
        return video?.value.status === 'preprocessing' && video?.value?.scenes === null
    });

    const replaceSceneText = (newText) => {
        if (selectedSceneNum.value !== null && video.value?.metadata?.scenes[selectedSceneNum.value]) {
            video.value.metadata.scenes[selectedSceneNum.value].text = newText;
        }
    };

    const sceneDuration = ref(selectedScene?.value?.minimumDuration || selectedScene?.value?.duration || 0);
    const actualSceneDuration = ref(selectedScene?.value?.duration || 0);

    watch(
        () => [selectedScene?.value?.duration, selectedScene?.value?.minimumDuration],
        ([newDuration, newMinDuration]) => {
            actualSceneDuration.value = newDuration;
            sceneDuration.value = (newMinDuration !== null && newMinDuration !== undefined) ? newMinDuration : newDuration;
            console.log("Новое значение duration:", newMinDuration, newDuration);
        }
    );


    watch(selectedScene, (newScene) => {
        backgroundType.value = newScene?.backgroundType || ''; // Обновляем backgroundType
    }, { immediate: true }); // Запускаем сразу при инициализации

    return {
        video,
        scenes,
        scenesDuration,
        actualScenesDuration,
        videoDuration,
        updateScenes,
        updateScene,
        updateAllScenes,
        duplicateScene,
        deleteScene,
        addScene,
        cutScene,
        replaceSceneBackground,
        replaceSceneText,
        selectedScene,
        selectedSceneNum,
        sceneDuration,
        actualSceneDuration,
        backgroundType,
        backgroundMusic,
        voiceOverMusic,
        videoId,
        videoGenerating,
        videoRendering,
        progress, // Прогресс доступен в других компонентах
        projects,
        projectsSearchQuery,
        projectsFound,
        setVideo,
        setVideoGenerating,
        setVideoRendering,
        fetchProjects,
        searchProjects,
        startPolling,
        stopPolling,
        setSuccessPullingCallback,
        videoIsPending,
        fonts,
        fetchFonts,
        fetchUploads,
        media,
        mediaCategory,
        mediaPage,
        mediaSearch,
        fetchMedia,
        visualUploads,
        audioUploads,
        fetchAudioUploads,
        fetchVisualUploads,
        generatePreviewScene,
        downloadVideo,
        initialGeneration,
        isManualPreviewSceneGeneration,
        fetchVoices,
        voiceSearch,
        voices
    };
});
