<script setup>
import { toRef, defineProps } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  filter: {
    type: Function, // Accept a function prop for input filtering
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  }
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
const name = toRef(props, 'name');

// Initialize vee-validate's useField
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

// Custom handler that applies filtering if the filter function is provided
const handleInput = (event) => {
  let value = event.target.value;

  if (props.filter) {
    value = props.filter(value); // Apply the filter function
  }

  handleChange(value); // Call the internal handleChange
  event.target.value = value
};
</script>

<template>
  <div
      class="TextInput"
      :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <textarea
        :name="name"
        :id="name"
        :value="inputValue"
        :placeholder="$t(placeholder)"
        :required="required"
        @input="handleInput"
        @blur="handleBlur"
    ></textarea>

    <p class="help-message" v-if="errorMessage">
      {{ $t(errorMessage) }}
    </p>
  </div>
</template>

<style scoped>
.TextInput {
  position: relative;
  /* margin-bottom: calc(1em * 1.5); */
  width: 100%;
}

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-size: 14px;
}
</style>
