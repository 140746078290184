import { defineStore } from 'pinia'
import axios from 'axios'
import{getAuthStore} from './auth'
import{getModalsStore} from './modals'
import router from "@/router";


export const getRegisterStore = defineStore('registerStore', {
  state: () => ({
    error: '',
    loading: false
  }),
  actions: {
    async submitRegister(params = {}) {
      this.error = '';
      this.loading = true;
      try {
        const res = await axios.post(process.env.VUE_APP_API + 'register', params);
        if (res.data && res.data.status == 'OK') {
          this.error = '';
          await getAuthStore().fetchData();
          getModalsStore().closeSignUp();
          await router.push({name: 'CabinetPage', params: {page: 'text'}});
        } else if (res.data && res.data.status != 'OK') {
          this.error = res.data.message;
        } else {
          this.error = res;
        }
      } catch (err) {
        if (err.response) {
          this.error = err.response.data.message;
        } else {
          this.error = err;
        }
      } finally {
        this.loading = false;
      }
    },
    clearError() {
      this.error = '';
    }
  },
});

