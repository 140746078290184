<script setup>
import { toRef, defineProps } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
const name = toRef(props, 'name');

// Initialize vee-validate's useField
const {
  value: selectValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const handleSelectChange = (event) => {
  const value = event.target.value;
  handleChange(value); // Call the internal handleChange
};
</script>

<template>
  <div
      class="SelectInput"
      :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label :for="name">{{ label && $t(label) }}</label>
    <select
        :name="name"
        :id="name"
        :value="selectValue"
        :required="required"
        @change="handleSelectChange"
        @blur="handleBlur"
    >
      <option disabled value="">{{ label && $t(placeholder) }}</option>
      <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
      >
        {{ $t(option.label) }}
      </option>
    </select>

    <p class="help-message" v-if="errorMessage">
      {{ $t(errorMessage) }}
    </p>
  </div>
</template>

<style scoped>
.SelectInput {
  position: relative;
  width: 100%;
}

.help-message {
  display: block;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-size: 14px;
  color: red;
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>
