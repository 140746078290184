<template>
  <footer class="section footer">
    <div class="wrapper">
      <div class="left">
        <div class="desc" v-if="settingsStore?.settings?.requisites">
          {{ settingsStore?.settings?.requisites }}
        </div>
        <ul class="methods">
          <li class="item">
            <img src="./../assets/img/methods/visa.svg" class="img"/>
          </li>
          <li class="item">
            <img src="./../assets/img/methods/mc_symbol.svg" class="img"/>
          </li>
          <li class="item">
            <img src="./../assets/img/methods/3ds.svg" class="img"/>
          </li>
        </ul>
        <div class="desc" v-if="settingsStore?.settings?.copyright">
          {{ settingsStore?.settings?.copyright }}
        </div>
      </div>
      <div class="center">
        <router-link 
          to="/" 
          class="logo"
        >
          <img 
            src="./../assets/img/logo.svg" 
            class="img"
          >
          <div class="desc">
            <b>{{ $t('WriteWizard') }}</b>
          </div>
        </router-link>
      </div>
      <div class="right">
        <ul class="nav">
          <template v-if="staticStore.pages && staticStore.pages.length">
            <li 
              v-for="item in staticStore.pages" 
              :key="item.id"
              class="nav__item" 
            >
              <a class="desc" v-if="item" @click="staticStore.goToPage(item.id)">
                <b v-if="item.title">{{ $t(item.title) }}</b>
              </a>
            </li>
          </template>
          <li class="nav__item">
            <router-link class="desc" to="/pricing">
              <b>{{ $t('Pricing') }}</b>
            </router-link>
          </li>
        </ul>
        <ul class="social-nav">
          <li v-for="link in socialLinks" :key="link.img" class="social-nav__item">
            <a :href="link.link" class="desc" target="_blank">
              <img :src="link.img" :alt="link.link" class="img" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import{getStaticStore} from './../stores/static'
import{getSettingsStore} from './../stores/settings'

export default {
	name: 'FooterComponent',
  components: {
    
  },
  props: {
  },
	data: function() {
		return {
      staticStore: '',
      settingsStore: '',
      socialLinks: []
		}
	},
  watch: {
    
  },
  mounted() {
    this.staticStore = getStaticStore();
    this.settingsStore = getSettingsStore();
    this.getSocialLinks();
  },
	methods: {
    getSocialLinks() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
        .then(({ data }) => {
          this.socialLinks = data.payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
	}
}
</script>
