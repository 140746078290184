import { defineStore } from 'pinia'
import axios from 'axios'


export const getOrdersStore = defineStore('ordersStore', {
  state: () => ({
    orders: [],
    error: ''
  }),
  actions: {
    async fetchData() {
      this.error = '';
      try {
        const res = await axios.get(process.env.VUE_APP_API + 'purchase/history');
        this.orders = res.data.payload;
      } catch (err) {
        this.error = err;
      }
    },
    clear() {
      this.orders = [];
    }
  },
});

