import { defineStore } from 'pinia'
import{getMenuStore} from './menu'
import {useVideoStore} from "@/stores/video";

export const getModalsStore = defineStore('modalsStore', {
  state: () => ({
    signInModalIsVisible: false,
    signUpModalIsVisible: false,
    textCreateModalIsVisible: false,
    videoPathsModalIsVisible: false,
    videoPreviewModalIsVisible: false,
    videoPathModalStep: 'initial',
    loadingModal: {
      show: false,
      title: '',
      percent: 0,
      text: null
    },
    topupModalIsVisible: false,
    topUpAmount: null,
    pack: null
  }),
  actions: {
    openSignIn() {
      this.signInModalIsVisible = true;
      this.signUpModalIsVisible = false;
      
      if (getMenuStore().menuIsVisible) {
          getMenuStore().closeMenu();
      }
    },
    closeSignIn() {
      this.signInModalIsVisible = false;
    },

    openSignUp() {
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;

      if (getMenuStore().menuIsVisible) {
        getMenuStore().closeMenu();
      }
    },
    closeSignUp() {
      this.signUpModalIsVisible = false;
    },

    openTextCreateModal() {
      this.textCreateModalIsVisible = true;
    },
    closeTextCreateModal() {
      this.textCreateModalIsVisible = false;
    },

    openVideoPathsModal(step = 'initial') {
      this.videoPathModalStep = step;
      this.videoPathsModalIsVisible = true;
    },
    openLoadingModal(title) {
      this.loadingModal = {
        ...this.loadingModal,
        title,
        show: true,
        text: null
      }
    },
    changeLoadingModalText(text) {
      this.loadingModal = {
        ...this.loadingModal,
        text
      }
    },
    closeVideoPathsModal() {
      this.videoPathsModalIsVisible = false;
    },
    closeLoadingModal() {
     this.loadingModal = {
       ...this.loadingModal,
       show: false
     }

     const videoStore = useVideoStore();
     videoStore.setVideoRendering(false);
    },
    openVideoPreviewModal() {
      this.videoPreviewModalIsVisible = true;
    },
    closeVideoPreviewModal() {
      this.videoPreviewModalIsVisible = false;
    },
    openTopupModal(amount = null, pack = null) {
      this.topUpAmount = amount;
      this.pack = pack;
      this.topupModalIsVisible = true;
    },
    closeTopupModal() {
      this.topupModalIsVisible = false;
    }
  },
});

