import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import toastOptions from "@/toastification";
// import vClickOutside from "click-outside-vue3"
import ClickOutsideDirective from "@/directives/ClickOutsideDirective";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

import { createPinia } from 'pinia'
const pinia = createPinia()


const app = createApp(App)

import './assets/css/style.scss'
import 'promise-polyfill/src/polyfill'

// Add Axios to the global properties
app.config.globalProperties.$http = axios
app.config.globalProperties.$http.defaults.withCredentials = true



// Use the router, store, and i18n plugins
app.use(pinia)
app.use(Vue3ColorPicker)
app.use(router)
app.use(store)
app.use(i18n)
app.use(Toast, toastOptions);
// app.use(vClickOutside)
app.directive('click-outside', ClickOutsideDirective);

// Mount the app
app.mount('#app')