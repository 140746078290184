import { ref, reactive } from 'vue';
import axios from 'axios';
import { loadScriptsFromString } from "@/utils/load_scripts";
import {defineStore} from "pinia";

export const getSettingsStore = defineStore('settingsStore', () => {
  // State
  const settings = reactive({});
  const countries = reactive([]);
  const error = ref('');

  // Actions
  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.VUE_APP_API + 'settings');
      Object.assign(settings, response.data.payload);
      const snippet = response.data.payload.google_tag;
      if (snippet) {
        loadScriptsFromString(snippet);
      }
    } catch (err) {
      error.value = err.message;
    }
  };

  const fetchCountries = async () => {
    try {
        const {data: {payload}} = await axios.get(process.env.VUE_APP_API + 'countries');
        Object.assign(countries, payload);
    } catch (e) {
      error.value = e.message;
    }
  }

  // Fetch data when the component is mounted
  // onMounted(fetchData);

  return {
    settings,
    error,
    countries,
    fetchData,
    fetchCountries
  };

});