<template>
  <div class="menu">
    <div class="link-container">
      <div 
        v-if="currId && currencyOptions.length > 1"
        class="select-container" 
      >
        <Select2 
          ref="select3"
          v-model="currId"
          class="select2 single currency-select" 
          :options="currencyOptions"
          @select="selectCurr($event)"
        />
      </div>
      <div 
        v-if="currentLanguage && languages.length > 1"
        class="select-container language-select-container" 
      >
        <Select2 
          ref="select2"
          v-model="language"
          class="select2 single currency-select" 
          :options="languages"
          @select="selectLanguage($event)"
        />
      </div>
      <ul class="nav">
        <li class="nav__item">
          <router-link class="desc" to="/pricing">
            <b>{{ $t('Pricing') }}</b>
          </router-link>
        </li>
        <li class="nav__item">
          <a  class="desc" @click="scrollToSection('#howItWorks')">
            <b>{{ $t('How it works') }}</b>
          </a>
        </li>
      </ul>
      <a 
        class="button login-button" 
        @click="modalsStore.openSignIn()"
      >
        <div class="ball"></div>
        <b>{{ $t('LOGIN') }}</b>
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9999 0C13.3135 0 12.7571 0.556452 12.7571 1.24286C12.7571 1.92926 13.3135 2.48571 13.9999 2.48571C20.6353 2.48571 26.0142 7.8647 26.0142 14.5C26.0142 21.1354 20.6353 26.5143 13.9999 26.5143C13.3135 26.5143 12.7571 27.0708 12.7571 27.7571C12.7571 28.4435 13.3135 29 13.9999 29C22.0081 29 28.4999 22.5081 28.4999 14.5C28.4999 6.49187 22.0081 0 13.9999 0Z" fill="white"/>
          <path d="M11.9641 10.4074C11.4787 9.92203 11.4787 9.13509 11.9641 8.64973C12.4495 8.16437 13.2363 8.16437 13.7216 8.64973L18.6931 13.6212C19.1784 14.1066 19.1784 14.8934 18.6931 15.3788L13.7216 20.3502C13.2363 20.8356 12.4495 20.8356 11.9641 20.3502C11.4787 19.8648 11.4787 19.078 11.9641 18.5926L14.8137 15.7428H1.24286C0.556452 15.7428 0 15.1864 0 14.5C0 13.8136 0.556452 13.2571 1.24286 13.2571H14.8137L11.9641 10.4074Z" fill="white"/>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import Select2 from "vue3-select2-component";
import{getModalsStore} from './../stores/modals'

export default {
  name: "MobMenu",
  components: {
    Select2,
  },
  props: {
    currencyCode: {
      type: String,
      required: true,
    },
    currencyCodeId: {
      type: String,
      required: true,
    },
    currencyOptions: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      currId: "",
      modalsStore: '',
    };
  },
  computed: {
    ...mapState({
      languages: ({ app: { languages } }) =>
        languages.map(({ id, code: text }) => ({ id, text })),
      currentLanguage: (state) => state.app.currentLanguage,
    }),
    ...mapGetters("app", ["currentLanguageName"]),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      },
    },
  },
  watch: {
    currencyCodeId(newValue) {
      this.currId = newValue;
    },
  },
  mounted() {
    this.currId = this.currencyCodeId;
    this.modalsStore = getModalsStore();
  },
  methods: {
    selectCurr(event) {
      this.currId = event.id;
      this.changeCurrency(event.text);
      const { id, text, value } = event;
      const newObject = { id, text, value };
      localStorage.setItem("currency", JSON.stringify(newObject));
    },
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
    },
    changeCurrency(item) {
      this.$emit("changeCurrency", item);
    },
    ...mapMutations("app", ["setCurrentLanguage"]),
    ...mapActions("app", ["changeLocale"]),
  },
};
</script>
