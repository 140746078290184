<script setup>
import {defineProps, toRef} from "vue"
import OutlineTreeItem from "@/components/OutlineTreeItem.vue";

const props = defineProps({
  headings: {
    type: Array,
    default: () => [],
  },
  showControls: {
    type: Boolean,
    default: () => true
  }
});

const headings = toRef(props, 'headings');
const showControls = toRef(props, 'showControls');
</script>

<template>
  <ul>
    <li v-for="heading in headings" :class="['item', `h${heading.heading_level}`]" :key="heading.id">
      <OutlineTreeItem :show-controls="showControls" :heading="heading"/>
      <OutlineTree :show-controls="showControls" v-if="heading.children && heading.children.length" :headings="heading.children"/>
    </li>
  </ul>
</template>

<style scoped>

</style>