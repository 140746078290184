<template>
  <div 
    :class="['app ' + $route.name]"
  >
  <Transition>
      <div
        v-if="pageLoaded"
        id="page" 
        class="page" 
      >
        <Transition>
          <MobMenu 
            v-if="menuIsVisible" 
            :currency-code="currencyCode" 
            :currency-options="currencyOptions" 
            :currency-code-id="currencyCodeId"
            @changeCurrency="changeCurrency" 
          />
        </Transition>
        <a 
          :class="['mob menu-icon', { 'active': menuIsVisible }]"
          @click="menuStore.toggleMenu()" 
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
        <Header 
          v-if="!$route.path.includes('cabinet')"
          :currency-code="currencyCode" 
          :currency-options="currencyOptions" 
          :currency-code-id="currencyCodeId"
          @changeCurrency="changeCurrency" 
        />
        <router-view 
          :currency="currency"
          :currency-code="currencyCode"
          :hostname="hostname"
        />
        <Footer v-if="!$route.path.includes('cabinet')" />
        <Transition>
          <LoginModal v-if="signInModalIsVisible" />
        </Transition>
        <Transition>
          <RegistrationModal
            v-if="signUpModalIsVisible" 
          />
        </Transition>
        <Transition>
          <TextCreateModal
            v-if="textCreateModalIsVisible" 
          />
        </Transition>
        <Transition>
          <VideoPathsModal
            v-if="videoPathsModalIsVisible" 
          />
        </Transition>
        <Transition>
          <VideoPreviewModal
            v-if="videoPreviewModalIsVisible"
          />
        </Transition>
        <Transition>
          <LoadingModal
              v-if="loadingModalIsVisible"
          />
        </Transition>
        <transition>
          <TopUpModal
            v-if="topupModalIsVisible"
          />
        </transition>
        <div 
          v-if="cookesModalIsVisible"
          class="modal-overlay" 
        >
          <div class="cookies-modal">
            <div class="title">
              {{ $t('About Cookies') }}
            </div>
            <div class="desc">
              {{ $t('Cookies modal text') }}
              <a 
                class="desc blue"
                @click="staticStore.goToPage('cookie')" 
              >
                {{ $t('Cookie Policy') }}
              </a>.
            </div>
            <button 
              class="button small" 
              @click="acceptCookies"
            >
              <span>
                {{ $t('Accept') }}
              </span>
            </button>

          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

import{getMenuStore} from './stores/menu'
import{getModalsStore} from './stores/modals'
import{getAuthStore} from './stores/auth'
import{getLoginStore} from './stores/login'
import{getRegisterStore} from './stores/register'
import{getOrdersStore} from './stores/orders'
import{useDepositsStore} from './stores/deposits'
import{getStaticStore} from './stores/static'
import{getSettingsStore} from './stores/settings'


import Header from './components/HeaderComponent.vue'
import Footer from './components/FooterComponent.vue'
import MobMenu from "./components/MobMenu.vue";
import LoginModal from "./components/LoginModal.vue";
import RegistrationModal from "./components/RegistrationModal.vue";
import TextCreateModal from "./components/TextCreateModal.vue";
import VideoPathsModal from "./components/VideoPathsModal.vue";
import VideoPreviewModal from "./components/VideoPreviewModal.vue";


import TopUpModal from "./components/TopUpModal.vue";

import {mapActions, mapState} from 'vuex';
import LoadingModal from "@/components/LoadingModal.vue";
import {useLocalizationsStore} from "@/stores/localizations";

export default {
  name: 'App',
  components: {
    LoadingModal,
    Header,
    Footer,
    MobMenu,
    LoginModal,
    RegistrationModal,
    TextCreateModal,
    VideoPathsModal,
    VideoPreviewModal,
    TopUpModal
  },
  data: function() {
    return {
      currency: '',
      currencyCode: '',
      currencyCodeId: '',
      currencyOptions: [],
      pageLoaded: false,
      cookesModalIsVisible: false,
      menuStore: '',
      modalsStore: '',
      authStore: '',
      loginStore: '',
      registerStore: '',
      ordersStore: '',
      depositsStore: '',
      staticStore: '',
      settingsStore: '',
      localizationsStore: '',
      error: '',
      hostname: ''
    }
  },
  computed: {
    ...mapState({
      currentLanguage: state => state.app.currentLanguage,
    }),
    menuIsVisible() {
      let isVisible = this.menuStore.menuIsVisible;
      if (isVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
      return isVisible;
    },
    signInModalIsVisible() {
      let isVisible = this.modalsStore.signInModalIsVisible;
      if (!this.modalsStore.signUpModalIsVisible) {
        if (isVisible) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
          this.loginStore.clearError();
        }
      }
      return isVisible;
    },
    signUpModalIsVisible() {
      let isVisible = this.modalsStore.signUpModalIsVisible;
      if (!this.modalsStore.signInModalIsVisible) {
        if (isVisible) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
          this.registerStore.clearError();
        }
      }
      return isVisible;
    },
    textCreateModalIsVisible() {
      let isVisible = this.modalsStore.textCreateModalIsVisible;
      if (!this.modalsStore.textCreateModalIsVisible) {
        if (isVisible) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      }
      return isVisible;
    },
    videoPathsModalIsVisible() {
      let isVisible = this.modalsStore.videoPathsModalIsVisible;
      if (!this.modalsStore.videoPathsModalIsVisible) {
        if (isVisible) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      }
      return isVisible;
    },
    videoPreviewModalIsVisible() {
      let isVisible = this.modalsStore.videoPreviewModalIsVisible;
      if (!this.modalsStore.videoPreviewModalIsVisible) {
        if (isVisible) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      }
      return isVisible;
    },
    loadingModalIsVisible() {
      let isVisible = this.modalsStore.loadingModal.show;

      console.log(isVisible, 'loadingModalIsVisible')

      if (!this.modalsStore.loadingModal.show) {
        if (isVisible) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      }
      return isVisible;
    },

    topupModalIsVisible() {
      let isVisible = this.modalsStore.topupModalIsVisible;
      if (isVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
      return isVisible;
    }
  },
  watch: {
    currency() {
      if (this.authStore.isAuth) {
        this.ordersStore.clear();
        this.depositsStore.clear();
        if (this.$route.path == '/profile/order-history') {
          this.ordersStore.fetchData();
        }
        if (this.$route.path == '/profile/deposit-history') {
          this.depositsStore.fetchData();
        }
      }
    },
    $route() {
      this.error = '';
      window.scrollTo(0, 0);
      this.menuStore.closeMenu();
    }
  },
  methods: {
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");

      if (!cookiesAccepted) {
        this.cookesModalIsVisible = true;
      }
    },
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookesModalIsVisible = false;
    },
    getCurrencies() {
      this.$http.get(process.env.VUE_APP_API + 'currencies')
      .then((res) => {
        const newCurrencies = res.data.payload.map(({
          code: text,
          value: value
        }, index) => ({
          text,
          value: value,
          id: index + 1,
        }));
          this.currencyOptions = newCurrencies;
          if (localStorage.getItem("currency")) {
            this.currencyCode = JSON.parse(localStorage.getItem("currency")).text
            this.currencyCodeId = JSON.parse(localStorage.getItem("currency")).id;
          } else {
            this.currencyCode = this.currencyOptions[0].text;
            this.currencyCodeId = this.currencyOptions[0].id;
          }
          this.checkCurrency();
      })
      .catch(() => {
       
      })
    },
    changeCurrency(item) {
      this.currencyCode = item;
      this.checkCurrency();
    },
    checkCurrency() {
      if (this.currencyCode == 'USD') {
        this.currency = '$';
      } else if (this.currencyCode == 'EUR') {
        this.currency = '€';
      }  else {
        this.currency = this.currencyCode;
      }
    },
    clearError() {
      this.error = '';
    },

    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    ...mapActions('app', ['fetchLanguages'])
  },
  mounted: async function() {
    // this.pageLoaded = true;
    this.hostname = this.getDomainName(window.location.hostname);
    let self = this;
    self.menuStore = getMenuStore();
    self.modalsStore = getModalsStore();
    self.authStore = getAuthStore();
    self.loginStore = getLoginStore();
    self.registerStore = getRegisterStore();
    self.ordersStore = getOrdersStore();
    self.depositsStore = useDepositsStore();
    self.staticStore = getStaticStore();
    self.settingsStore = getSettingsStore();
    self.localizationsStore = useLocalizationsStore();
    
    self.authStore.fetchData();
    self.staticStore.fetchData();
    self.settingsStore.fetchData();

    self.currency = process.env.VUE_APP_CURRENCY;
    await self.localizationsStore.fetchLocalizations('en');
    // await self.fetchLanguages();
    self.getCurrencies();
    self.menuHeight();
    self.cookiesModal();
    window.addEventListener("resize", self.menuHeight);
    setTimeout(function(){
      self.pageLoaded = true;
    }, 300)
    
  }
}
</script>

