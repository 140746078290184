import axios from 'axios';
import {getProfileStore} from "@/stores/profile";
// import { getProfileStore } from '@/stores/profile'; // Import the store

const instance = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: true,
    timeout: 20000
});

// Request interceptor (remains the same)
instance.interceptors.request.use(function (config) {
    let currency = localStorage.getItem('currency');
    if (!currency) {
        currency = 'EUR';
    }

    // const lang_id = 1;

    if (config.method === 'get') {
        config.params = {
            ...config.params,
            // lang_id,
            currency
        }
    } else if (['post', 'put', 'patch', 'POST', 'PUT', 'PATCH'].includes(config.method)) {
        if (config.data instanceof FormData) {
            config.data.append('currency', currency);
        } else {
            config.data = {
                ...config.data,
                currency,
                // lang_id
            };
        }
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

// Response interceptor to handle 401 errors
instance.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        if (error.response && error.response.status === 401) {
            const profileStore = getProfileStore(); // Access the profile store
            await profileStore.logout(); // Call the logout action
        }
        return Promise.reject(error);
    }
);

export default instance;
