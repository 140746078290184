<template>
  <div class="cabinet-wrapper">
    <div class="topic-list-container">
      <div class="top">
        <div class="desc">
          <b>{{ $t('New text') }}</b>
        </div>
        <a @click.prevent="modalsStore.openTextCreateModal()" class="open-button">
          <img
              class="img"
              src="./../assets/img/topUp.svg"
          />
        </a>
      </div>
      <div class="bottom">
        <div class="desc empty-desc" v-if="!modalStepsDone">
          {{ $t('Your titles appear here once you create a topic') }}
        </div>
        <div class="bottom-form" v-if="modalStepsDone">
          <label v-if="projects?.length > 0">
            <div class="desc small gray"><b>{{ $t('Project') }}:</b></div>
            <select v-model="articleId">
              <option v-for="project in projects" :value="project.article_id" :key="project.article_id">{{ $t(project.title) }}</option>
            </select>
          </label>
          <label v-if="projects?.length > 0" class="search-label">
            <input
                name="search"
                type="text"
                :placeholder="$t('Search')"
                required
                v-model="projectsSearchQuery"
            />
            <img
                class="search-img"
                src="./../assets/img/icons/search.svg"
            />
          </label>
          <div class="titles-with-actions-list">
            <div
                v-for="project in projectsFound"
                :key="project.id"
                :class="['title-actions', {'active': activeTitle == 'The Basics of SEO: What you need to know'}]"
                @click="articleId = project.id"
            >
              <div class="desc">
                <b>{{ $t(project.title) }}</b>
              </div>
              <div class="actions">
<!--                <a class="actions__item">-->
<!--                  <img-->
<!--                      class="img"-->
<!--                      src="./../assets/img/icons/generate.svg"-->
<!--                  />-->
<!--                </a>-->
<!--                <a class="actions__item">-->
<!--                  <img-->
<!--                      class="img"-->
<!--                      src="./../assets/img/icons/publish.svg"-->
<!--                  />-->
<!--                </a>-->
<!--                <a class="actions__item">-->
<!--                  <img-->
<!--                      class="img"-->
<!--                      src="./../assets/img/icons/translate.svg"-->
<!--                  />-->
<!--                </a>-->
                <a @click.prevent.stop="deleteProject(project.id)" class="actions__item">
                  <img
                      class="img"
                      src="./../assets/img/icons/bin.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="topic-generating-container topic-generating-container-editable" v-if="hasProjects && !articleStore.articleContentNotGenerated">
      <div class="topic-generating-header">
        <div @click="editTitle = !editTitle" v-if="!editTitle" class="title small">
          <b>{{ $t(article?.title) }}</b>
        </div>
        <label v-else>
          <input @blur="handleBlur" v-model="inputValue" ref="input" type="text"/>
        </label>
        <div class="actions">
          <a @click="editTitle = !editTitle">
            <img
                class="img"
                src="./../assets/img/icons/edit.svg"
            />
          </a>
          <div class="additional-actions">
            <div class="desc small">
              {{ $t('Download') }}:
            </div>
            <div class="list">
              <a @click.prevent="downloadFile('html')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.703 0H6.06364C4.65541 0 3.50927 1.14694 3.50927 2.55432V12H3.25973C2.69136 12 2.23047 12.4605 2.23047 13.0293V19.2717C2.23047 19.8405 2.69136 20.301 3.25973 20.301H3.50927V21.4456C3.50927 22.8546 4.65541 23.9999 6.06364 23.9999H19.2161C20.6236 23.9999 21.7697 22.8546 21.7697 21.4456V6.04551L15.703 0ZM3.34485 18.1569V14.3646H4.20621V15.8221H5.61842V14.3646H6.47351V18.1569H5.61842V16.5707H4.20621V18.1569H3.34485ZM19.216 22.4357H6.06364C5.51832 22.4357 5.07435 21.9916 5.07435 21.4456V20.301H17.3348C17.9032 20.301 18.3642 19.8405 18.3642 19.2717V13.0293C18.3642 12.4605 17.9032 12 17.3348 12H5.07435V2.55432C5.07435 2.00989 5.51832 1.56587 6.06364 1.56587L15.1178 1.55646V4.9032C15.1178 5.88074 15.911 6.67466 16.8891 6.67466L20.1677 6.66525L20.2045 21.4456C20.2046 21.9916 19.7613 22.4357 19.216 22.4357ZM6.93326 15.0847V14.3646H9.84772V15.0847H8.81214V18.1569H7.95147V15.0847H6.93326ZM10.1944 18.1569L10.4361 14.3646H11.5784L11.9495 15.6307C12.0679 16.0693 12.1864 16.5424 12.2703 16.9864H12.2876C12.3943 16.5478 12.5237 16.0473 12.6477 15.6253L13.0525 14.3646H14.172L14.3807 18.1569H13.542L13.4855 16.7056C13.4691 16.2497 13.4518 15.6982 13.4518 15.1468H13.4353C13.3169 15.6308 13.1592 16.1705 13.0132 16.6154L12.5519 18.095H11.882L11.4772 16.6264C11.3532 16.1823 11.2238 15.6418 11.1335 15.1468H11.1225C11.0998 15.6591 11.0833 16.2443 11.0551 16.7166L10.9876 18.1569H10.1944ZM17.4049 17.4367V18.1569H15.0357V14.3646H15.8972V17.4367H17.4049Z" fill="#2E263F"/>
                </svg>
              </a>
              <a @click.prevent="downloadFile('pdf')" >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_533_2020)">
                    <path d="M7.06973 15.4409C7.06973 15.0423 6.79316 14.8046 6.30519 14.8046C6.10592 14.8046 5.97098 14.8243 5.90039 14.8431V16.1219C5.98397 16.1407 6.08671 16.147 6.22833 16.147C6.74846 16.147 7.06973 15.8842 7.06973 15.4409Z" fill="#2E263F"/>
                    <path d="M10.0898 14.818C9.87123 14.818 9.73001 14.8369 9.64648 14.8564V17.6886C9.73006 17.7082 9.86501 17.7082 9.98702 17.7082C10.8735 17.7145 11.4517 17.2265 11.4517 16.1925C11.458 15.2934 10.9311 14.818 10.0898 14.818Z" fill="#2E263F"/>
                    <path d="M15.703 0H6.06363C4.65541 0 3.50927 1.14694 3.50927 2.55436V11.9999H3.25978C2.69141 11.9999 2.23047 12.4604 2.23047 13.0293V19.2717C2.23047 19.8405 2.69136 20.3009 3.25978 20.3009H3.50927V21.4456C3.50927 22.8546 4.65541 23.9999 6.06363 23.9999H19.2161C20.6235 23.9999 21.7698 22.8545 21.7698 21.4456V6.0455L15.703 0ZM4.93078 14.1558C5.23243 14.1049 5.65644 14.0664 6.25383 14.0664C6.85754 14.0664 7.28782 14.1817 7.57693 14.4131C7.8531 14.6313 8.03947 14.9913 8.03947 15.415C8.03947 15.8386 7.89825 16.1988 7.6413 16.4427C7.30709 16.7573 6.81284 16.8986 6.23467 16.8986C6.10599 16.8986 5.99065 16.8922 5.90046 16.8797V18.4276H4.93078V14.1558ZM19.2161 22.4356H6.06363C5.51836 22.4356 5.07434 21.9916 5.07434 21.4456V20.3009H17.3352C17.9036 20.3009 18.3645 19.8405 18.3645 19.2717V13.0293C18.3645 12.4604 17.9036 11.9999 17.3352 11.9999H5.07434V2.55436C5.07434 2.00989 5.51841 1.56587 6.06363 1.56587L15.1178 1.55641V4.90314C15.1178 5.88068 15.9109 6.67459 16.8892 6.67459L20.1677 6.66518L20.2046 21.4455C20.2046 21.9916 19.7614 22.4356 19.2161 22.4356ZM8.66473 18.408V14.1558C9.02438 14.0986 9.49314 14.0664 9.98783 14.0664C10.81 14.0664 11.3431 14.2139 11.7608 14.5285C12.2104 14.8627 12.4928 15.3954 12.4928 16.1602C12.4928 16.9887 12.1911 17.5607 11.7733 17.9136C11.3175 18.2925 10.6236 18.4722 9.77598 18.4722C9.26834 18.4722 8.90869 18.4401 8.66473 18.408ZM15.6748 15.8905V16.6867H14.1203V18.4276H13.1376V14.0986H15.7838V14.9011H14.1203V15.8905H15.6748Z" fill="#2E263F"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_533_2020">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a @click.prevent="downloadFile('doc')" >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_533_4016)">
                    <path d="M15.7031 0H6.06368C4.65541 0 3.50927 1.14694 3.50927 2.55436V11.9999H3.25973C2.69136 11.9999 2.23047 12.4604 2.23047 13.0293V19.2717C2.23047 19.8405 2.69136 20.3009 3.25973 20.3009H3.50927V21.4456C3.50927 22.8546 4.65541 23.9999 6.06363 23.9999H19.216C20.6235 23.9999 21.7696 22.8545 21.7696 21.4456V6.0455L15.7031 0ZM3.93328 13.8867C4.29302 13.8295 4.76168 13.7973 5.25643 13.7973C6.07856 13.7973 6.61169 13.9448 7.02942 14.2594C7.47897 14.5936 7.76136 15.1271 7.76136 15.8912C7.76136 16.7197 7.4597 17.2916 7.04197 17.6446C6.58615 18.0234 5.89229 18.2032 5.04462 18.2032C4.53708 18.2032 4.17734 18.171 3.93333 18.1389L3.93328 13.8867ZM19.2161 22.4356H6.06368C5.51836 22.4356 5.07434 21.9916 5.07434 21.4456V20.3009H17.3352C17.9035 20.3009 18.3645 19.8405 18.3645 19.2717V13.0293C18.3645 12.4604 17.9036 11.9999 17.3352 11.9999H5.07434V2.55436C5.07434 2.00989 5.51836 1.56587 6.06368 1.56587L15.1178 1.55641V4.90314C15.1178 5.88068 15.911 6.67459 16.8892 6.67459L20.1677 6.66518L20.2046 21.4455C20.2046 21.9916 19.7613 22.4356 19.2161 22.4356ZM8.20692 16.0261C8.20692 14.7286 9.03543 13.7589 10.3138 13.7589C11.6436 13.7589 12.3692 14.7544 12.3692 15.9492C12.3692 17.3684 11.5086 18.229 10.2431 18.229C8.95857 18.229 8.20692 17.2594 8.20692 16.0261ZM15.23 17.4132C15.5253 17.4132 15.8528 17.3488 16.0458 17.2719L16.1933 18.0361C16.0137 18.1263 15.6089 18.2228 15.0825 18.2228C13.5856 18.2228 12.8148 17.2916 12.8148 16.0584C12.8148 14.581 13.8684 13.7589 15.1785 13.7589C15.6861 13.7589 16.0713 13.8616 16.2447 13.9511L16.0458 14.7286C15.8465 14.6446 15.5703 14.5677 15.2236 14.5677C14.4462 14.5677 13.8425 15.0369 13.8425 16.0003C13.8426 16.8671 14.3564 17.4132 15.23 17.4132Z" fill="#2E263F"/>
                    <path d="M6.72122 15.9234C6.72749 15.0236 6.20074 14.549 5.35929 14.549C5.14076 14.549 4.99954 14.5678 4.91602 14.5873V17.4195C4.99954 17.4391 5.13454 17.4391 5.25655 17.4391C6.14305 17.4453 6.72122 16.9574 6.72122 15.9234Z" fill="#2E263F"/>
                    <path d="M11.3345 15.9806C11.3345 15.204 10.9619 14.5356 10.2876 14.5356C9.62586 14.5356 9.24023 15.1655 9.24023 16.0065C9.24023 16.8546 9.63876 17.4516 10.2939 17.4516C10.9556 17.4516 11.3345 16.8223 11.3345 15.9806Z" fill="#2E263F"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_533_4016">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="topic-generating-main">
        <div class="topic-generating-editor">
          <div class="topic-generating-wysiwyg">
            <quill-editor @change="handleTextChange" :value="article?.generated_text" />
          </div>
          <div class="topic-generating-side">
            <div class="side-dates">
              <div class="icon">
                <img
                    class="img"
                    src="./../assets/img/icons/date.svg"
                />
              </div>
              <div class="desc small">
                {{ $t('Created') }} {{ article?.created_at ? moment(article.created_at).format('MMM D, YYYY') : '' }}
                <br/>
                {{ $t('Updated') }} {{ article?.updated_at ? moment(article.updated_at).format('MMM D, YYYY h:mm A') : '' }}
              </div>
            </div>
            <div class="article-structure">
              <div class="title-container">
                <div class="icon">
                  <img
                      class="img"
                      src="./../assets/img/icons/article.svg"
                  />
                </div>
                <div class="title small">
                  <b>{{ $t('Outline') }}</b>
                </div>
              </div>

              <OutlineTree :show-controls="false" class="list outline-list" v-if="article?.headings" :headings="article?.headings"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="topic-generating-container" v-if="hasProjects && modalStepsDone && articleStore.articleContentNotGenerated">
      <div class="topic-generating-header">
        <div @click="editTitle = !editTitle" v-if="!editTitle" class="title small">
          <b>{{ $t(article?.title) }}</b>
        </div>
        <label v-else>
          <input @blur="handleBlur" v-model="inputValue" ref="input" type="text"/>
        </label>
        <div class="actions">
          <a @click="editTitle = !editTitle">
            <img
                class="img"
                src="./../assets/img/icons/edit.svg"
            />
          </a>
          <a>
            <img
                class="img"
                src="./../assets/img/icons/generate.svg"
            />
          </a>
        </div>
      </div>

      <div class="topic-generating-main">
        <div class="generated-container" v-if="!articleStore.articleIsPending">
          <div class="generated-header">
            <div class="desc small">
              <b>{{ $t('Target keyword') }}:</b>
            </div>
            <label>
              <input v-model="targetKeywordInputValue" type="text" />
            </label>
            <a
                :class="['button', 'login-button', 'wand-button', {'disabled':generatingArticleContent}]"
                @click.prevent="generateContent"
            >
              <div class="ball"></div>
              <b>{{ $t('GENERATE') }}</b>
              <img
                  class="img"
                  src="./../assets/img/wand.svg"
              />
              <img
                  class="hover-img"
                  src="./../assets/img/wand2.svg"
              />
            </a>
          </div>
          <div class="generated-main" v-if="articleLoaded">
            <div class="generated-main-top">
              <div class="left">
                <div class="icon">
                  <img
                      class="img"
                      src="./../assets/img/icons/article.svg"
                  />
                </div>
                <div class="title small">
                  <b>{{ $t('Outline') }}</b>
                </div>
              </div>
              <div class="right">
                <div class="desc small">{{ $t('New outline') }}</div>
                <a :class="{'disabled':generatingNewOutline}" @click.prevent="generateNewOutline">
                  <img
                      class="img"
                      src="./../assets/img/icons/blackRefresh.svg"
                  />
                </a>
              </div>
            </div>
            <div class="generated-main-center">
<!--              <div class="list">-->
<!--                <div :class="['item', {'active': editIsVisible}]">-->
<!--                  <div class="item-left">-->
<!--                    <div class="desc">-->
<!--                      <b>{{ $t('H2') }}</b>-->
<!--                    </div>-->
<!--                    <div class="desc small" v-if="!editIsVisible">-->
<!--                      <b>{{ $t('Understanding SEO') }}</b>-->
<!--                    </div>-->
<!--                    <label v-if="editIsVisible">-->
<!--                      <input type="text" />-->
<!--                    </label>-->
<!--                  </div>-->
<!--                  <div class="item-right">-->
<!--                    <a @click="editIsVisible = !editIsVisible">-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/edit.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                    <a>-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/generate.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                    <a>-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/move.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                    <a>-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/plus.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                    <a>-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/bin.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div :class="['item h3', {'active': editIsVisible}]">-->
<!--                  <div class="item-left">-->
<!--                    <div class="desc">-->
<!--                      <b>{{ $t('H3') }}</b>-->
<!--                    </div>-->
<!--                    <div class="desc small" v-if="!editIsVisible">-->
<!--                      <b>{{ $t('Objectives of SEO') }}</b>-->
<!--                    </div>-->
<!--                    <label v-if="editIsVisible">-->
<!--                      <input type="text" value="Understanding SEO" />-->
<!--                    </label>-->
<!--                  </div>-->
<!--                  <div class="item-right">-->
<!--                    <a @click="editIsVisible = !editIsVisible">-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/edit.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                    <a>-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/generate.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                    <a>-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/move.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                    <a>-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/plus.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                    <a>-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/bin.svg"-->
<!--                      />-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              {{ articleStore.article }}-->

              <OutlineTree class="list outline-list" v-if="article?.headings" :headings="article?.headings"/>
            </div>
          </div>
        </div>
        <div class="loader-container" v-else>
          <div class="loader">
            <img
                class="img"
                src="./../assets/img/icons/loader.svg"
            />
          </div>
          <div class="desc">
            <template v-if="article?.status === 'pending'">
              <span>{{ $t(`Generating outlets... Please wait while we process your request.`) }}</span>
              <br/>
              <span>{{ $t(`This should only take a moment.`) }}</span>
            </template>
            <template v-else-if="article?.status === 'generating_content'">
              <span>{{ $t(`Generating article content... Please wait while we create your content.`) }}</span>
              <br/>
              <span>{{ $t(`This will only take a moment.`) }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!hasProjects" class="topic-create-container">
      <div class="title regular">
        <b>{{ $t('Create your first text') }}</b>
      </div>
      <div class="desc">
        {{ $t('Your ttles appear here once you create a topic') }}
      </div>
      <a
          class="button login-button wand-button"
          @click="modalsStore.openTextCreateModal()"
      >
        <div class="ball"></div>
        <b>{{ $t('START') }}</b>
        <img
            class="img"
            src="./../assets/img/wand.svg"
        />
        <img
            class="hover-img"
            src="./../assets/img/wand2.svg"
        />
      </a>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, onMounted, onUnmounted, watch} from 'vue';
import {storeToRefs} from "pinia";
import {quillEditor} from 'vue3-quill'
import "quill/dist/quill.snow.css"; /* Import Quill styles */
import {getModalsStore} from './../stores/modals'
import {getArticleStore} from "./../stores/article";
import OutlineTree from "@/components/OutlineTree.vue";
import axios from "@/axios";
import {debounce} from "@/utils/utils";
import moment from 'moment'
import {useToast} from "vue-toastification";
import {getProfileStore} from "@/stores/profile";

const modalsStore = getModalsStore();
const articleStore = getArticleStore();
const profileStore = getProfileStore();
const modalStepsDone = ref(true);
const articleLoaded = ref(true);
const editTitle = ref(false);

const generatingArticleContent = ref(false);
const generatingNewOutline = ref(false);

const {articleId, article, projects, projectsSearchQuery, projectsFound} = storeToRefs(articleStore);
const {fetchProjects, searchProjects} = articleStore;
const {updateBalance} = profileStore;

const inputValue = ref(article?.value?.title || '');
const targetKeywordInputValue = ref(article?.value?.target_keyword || '');

// Create a debounced version of searchProjects once
const debouncedSearchProjects = debounce(searchProjects, 400);

// Watch the search query and trigger debounced search
watch(projectsSearchQuery, () => {
  debouncedSearchProjects();
});

watch(article, (newValue) => {
  inputValue.value = newValue?.title || '';
})

watch(() => article?.value?.target_keyword, (newTargetKeyword) => {
  targetKeywordInputValue.value = newTargetKeyword;
}, {immediate: true})

const toast = useToast();

const handleBlur = async () => {
  if (!inputValue.value) {
    inputValue.value = article?.value?.title;
  } else {
    try {
      const {data: {payload}} = await axios.put(process.env.VUE_APP_API + `article/${article?.value?.id}`, {
        title: inputValue.value,
      })

      article.value = {
        ...article.value,
        title: inputValue.value,
        ...payload
      }

      article.value.title = inputValue.value;
      editTitle.value = false;
    } catch (e) {
      inputValue.value = article?.value.title;
    }
  }
}

const generateNewOutline = async () => {
  try {
    generatingNewOutline.value = true;

    await axios.post(`${process.env.VUE_APP_API}article/${articleId.value}/generate-headings`);
    articleStore.startPolling();
  } catch (e) {
    console.error(e);
  } finally {
    generatingNewOutline.value = false;
  }
}

const generateContent = async () => {
  try {
    generatingArticleContent.value = true;

    const {data: {payload: {balance}}} = await axios.post(`${process.env.VUE_APP_API}article/${articleId.value}/generate-content`);
    updateBalance(balance);

    articleStore.startPolling();
  } catch (e) {
    if (e.response && e.response.status === 402) {
      toast.error(e.response.data.message, {
        timeout: 2000
      });
    } else {
      console.error(e);
    }
  } finally {
    generatingArticleContent.value = false;
  }
}

const downloadFile = async (fileType) => {
  try {
    let response;

    let url = `/project/${article.value.project_id}/${fileType}`;

    switch (fileType) {
      case 'pdf':
        response = await axios.get(url, { responseType: 'blob' });
        triggerDownload(response.data, 'your-file-name.pdf', 'application/pdf');
        break;

      case 'doc':
        response = await axios.get(url, { responseType: 'blob' });
        triggerDownload(response.data, 'your-file-name.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        break;

      case 'html':
        response = await axios.get(url, { responseType: 'blob' });
        triggerDownload(response.data, 'your-file-name.html', 'text/html');
        break;
    }
  } catch (error) {
    console.error(`Error downloading the ${fileType.toUpperCase()}:`, error);
  }
};

const triggerDownload = (blobData, fileName, mimeType) => {
  const blob = new Blob([blobData], { type: mimeType });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};

const handleTextChange = debounce(async (event) => {
  try {
    await axios.put(`${process.env.VUE_APP_API}article/${articleId.value}/text`, {
      text: event.html
    });
  } catch (e) {
    console.error(e);
  }
}, 200);

const deleteProject = async (id) => {
  try {
    await axios.delete(`/project/${id}`);
    await Promise.all([fetchProjects(), searchProjects()]);

    if (article?.value?.id === id && projects.value?.length > 0) {
      articleId.value = projects.value[0].id;
    }
  } catch (e) {
    console.error(e);
  }
}

onMounted(async () => {
  await Promise.all([articleStore.fetchProjects(), searchProjects()]);

  if (projects.value?.length > 0) {
    articleId.value = projects.value[0].id;
  }
});

onUnmounted(async () => {
  await articleStore.stopPolling();
});

const activeTitle = computed(() => 'The Basics of SEO: What you need to know');
const hasProjects = computed(() => articleStore.projects && articleStore.projects.length > 0);
</script>


<style scoped>
/* Add your CSS here */
</style>
