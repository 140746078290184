<template>
  <div class="modal text-create-modal">
    <div
        class="overlay"
        @click="modalsStore.closeTextCreateModal()"
    />
    <div class="wrapper">
      <img
          @click="modalsStore.closeTextCreateModal()"
          src="./../assets/img/close.svg"
          class="close"
      >
      <a
          class="back"
          v-if="step != 2"
          @click="step = step - 1"
      >
        <img
            src="./../assets/img/icons/back.svg"
            class="img"
        >
        <div class="desc">
          {{ $t('Back') }}
        </div>
      </a>
      <div class="container">
        <div class="step-preview" v-if="step == 1">
          <div class="step-container">
            <div class="title small">
              <b>{{ $t('Select article template') }}</b>
            </div>
            <div class="title small">
              <b>
                01/<span>03</span>
              </b>
            </div>
          </div>
          <div class="desc">
            {{
              $t('Text description. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.')
            }}
          </div>
          <div class="card-list-container">
            <div>
              <div class="title small">
                <b>{{ $t('Top of funnel content') }}</b>
              </div>
              <div class="card-list">
                <div class="card-list__item" v-for="topic in topicsStore.topics" :key="topic.id">
                  <div class="desc extrasmall blue">
                    {{ $t(topic.headline) }}
                  </div>
                  <div class="title-container">
                    <div class="desc">
                      <b>{{ $t(topic.title) }}</b>
                    </div>
                    <div class="icon">
                      <img
                          class="img"
                          src="./../assets/img/icons/article.svg"
                      />
                    </div>
                  </div>
                  <div class="desc small">
                    {{ $t(topic.description) }}
                  </div>
                  <button class="button small" @click="selectTopic(topic.id)">
                    <span>{{ $t('SELECT') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Form :novalidate="false" :on-submit="submitSecondStep" :validation-schema="schemaStepTwo" class="step-preview"
              v-if="step == 2">
          <div class="step-container">
            <div class="step-title-container">
              <div class="icon">
                <img
                    class="img"
                    src="./../assets/img/icons/article.svg"
                />
              </div>
              <div class="title small">
                <b>{{ $t('Single article') }}</b>
              </div>
            </div>
            <div class="title small">
              <b>
                01/<span>03</span>
              </b>
            </div>
          </div>
          <div class="desc">
            {{ $t('Create from a keyword or a title.') }}
          </div>
          <div class="form-container">
            <div class="label-list">
              <label>
                <div class="desc small gray"><b>{{ $t('Keyword') }}:</b></div>
                <TextInput
                    name="keyword"
                    type="text"
                    required
                />
              </label>
              <label>
                <div class="desc-container">
                  <div class="desc small gray"><b>{{ $t('Title') }}:</b></div>
                  <!--                  <div class="desc small">-->
                  <!--                    {{ $t('(optional)') }}-->
                  <!--                  </div>-->
                </div>
                <TextInput
                    name="title"
                    type="text"
                    required
                />
              </label>
            </div>
          </div>
          <button :disabled="secondStepSubmitting" class="button small" type="submit">
            <span>{{ $t('NEXT') }}</span>
          </button>
        </Form>
        <Form ref="thirdStepForm" :novalidate="false" :on-submit="submitThirdStep" :validation-schema="schemaStepThree"
              class="step-preview" v-if="step == 3">
          <div class="step-container">
            <div class="step-title-container">
              <div class="icon">
                <img
                    class="img"
                    src="./../assets/img/icons/article.svg"
                />
              </div>
              <div class="title small">
                <b>{{ $t('Single article') }}</b>
              </div>
            </div>
            <div class="title small">
              <b>
                02/<span>03</span>
              </b>
            </div>
          </div>
          <div class="desc">
            {{ $t('Create from a keyword or a title.') }}
          </div>
          <div class="form-container">
            <div class="label-list label-list-generate">
              <label>
                <div class="desc small gray"><b>{{ $t('Target keyword:') }}</b></div>
                <TextInput
                    name="target_keyword"
                    type="text"
                    required
                />
              </label>
              <!--              <label>-->
              <!--                <div class="desc-container">-->
              <!--                  <div class="desc small gray"><b>{{ $t('Title:') }}</b></div>-->
              <!--                </div>-->
              <!--                <TextInput-->
              <!--                    name="title"-->
              <!--                    type="text"-->
              <!--                    required-->
              <!--                />-->
              <!--                <a class="generate">-->
              <!--                  <img-->
              <!--                      src="./../assets/img/icons/generate.svg"-->
              <!--                      class="img"-->
              <!--                  >-->
              <!--                </a>-->
              <!--              </label>-->
              <label>
                <div class="desc-container">
                  <div class="desc small gray"><b>{{ $t('Image keyword') }}:</b></div>
                </div>
                <TextInput
                    name="image_keyword"
                    type="text"
                    required
                />
                <a class="generate">
                  <img
                      src="./../assets/img/icons/generate.svg"
                      class="img"
                  >
                </a>
                <a class="remove">
                  <img
                      src="./../assets/img/icons/remove.svg"
                      class="img"
                  >
                </a>
              </label>
            </div>
            <div class="keyword-list-container">
              <div class="keyword-title-container">
                <div class="desc small">
                  <b>{{ $t('Image keywords') }}</b>
                </div>
                <div class="icon">
                  <img
                      src="./../assets/img/icons/wand.svg"
                      class="img"
                  >
                </div>
              </div>
              <div class="keyword-list">
                <div @click="selectImageKeyword(imageKeyword)"
                     v-for="imageKeyword in imageKeywords" :key="imageKeyword" class="desc small">
                  {{ $t(imageKeyword) }}
                </div>
              </div>
            </div>
          </div>
          <button class="button small" type="submit">
            <span>{{ $t('NEXT') }}</span>
          </button>
        </Form>
        <Form :on-submit="submit" class="step-preview" v-if="step == 4">
          <div class="step-container">
            <div class="step-title-container">
              <div class="icon">
                <img
                    class="img"
                    src="./../assets/img/icons/article.svg"
                />
              </div>
              <div class="title-container">
                <div class="title small">
                  <b>{{ $t('Add links') }}</b>
                </div>
                <div class="desc">
                  {{ $t('(optional)') }}
                </div>
              </div>
            </div>
            <div class="title small">
              <b>
                03/<span>03</span>
              </b>
            </div>
          </div>
          <div class="desc">
            {{ $t('Add max 3 URLs you want all articles to link to') }}
          </div>
          <div class="form-container">
            <div class="label-list">
              <label>
                <div class="desc small gray"><b>{{ $t('Link 1') }}:</b></div>
                <TextInput
                    name="links[0]"
                    type="text"
                    required
                />
              </label>
              <label>
                <div class="desc small gray"><b>{{ $t('Link 2') }}:</b></div>
                <TextInput
                    name="links[1]"
                    type="text"
                    required
                />
              </label>
              <label>
                <div class="desc small gray"><b>{{ $t('Link 3') }}:</b></div>
                <TextInput
                    name="links[2]"
                    type="text"
                    required
                />
              </label>
            </div>
          </div>
          <div class="button-container">
            <div class="info">
              <img
                  src="./../assets/img/info.svg"
                  class="img"
              >
              <div class="desc extrasmall">
                {{ $t('Generating outlines doesn`t cost credit`s ') }}
              </div>
            </div>
            <button class="button small" type="submit">
              <span>{{ $t('GENERATE') }}</span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, defineExpose} from 'vue';
import {getModalsStore} from './../stores/modals';
import {getArticleStore} from './../stores/article';
import TextInput from "@/components/TextInput.vue";
import {Form} from 'vee-validate';
import {getTopicsStore} from "@/stores/topics";
import * as Yup from "yup";
import axios from "@/axios";
import {storeToRefs} from "pinia";


const modalsStore = getModalsStore();
const topicsStore = getTopicsStore();
const articleStore = getArticleStore();
const step = ref(2);
const topicId = ref(null);
const title = ref('');
const keyword = ref('');
const targetKeyword = ref('');
const imageKeyword = ref('');
const thirdStepForm = ref(null)

const {fetchProjects, searchProjects} = articleStore;

const selectTopic = (id) => {
  topicId.value = id;
  step.value = 2;
};

const schemaStepTwo = Yup.object().shape({
  keyword: Yup.string().required(),
  title: Yup.string().required(),
});

const secondStepSubmitting = ref(false);
const imageKeywords = ref([]);

const submitSecondStep = async (values) => {
  title.value = values.title;
  keyword.value = values.keyword;
  secondStepSubmitting.value = true;

  try {
    const {data: {payload}} = await axios.post(process.env.VUE_APP_API + 'image-keywords/generate', values);
    imageKeywords.value = payload;
  } catch (e) {
    console.error(e);
  } finally {
    secondStepSubmitting.value = false
  }

  step.value = 3;
}

const selectImageKeyword = (keyword) => {
  thirdStepForm.value.setFieldValue('image_keyword', keyword);
}

const schemaStepThree = Yup.object().shape({
  target_keyword: Yup.string().required(),
  image_keyword: Yup.string().required(),
});

const submitThirdStep = (values) => {
  targetKeyword.value = values.target_keyword;
  imageKeyword.value = values.image_keyword;
  step.value = 4;
}

const submit = async (values) => {
  try {
    const {data} = await axios.post('/article/create', {
      title: title.value,
      // topic_id: topicId.value,
      keyword: keyword.value,
      target_keyword: targetKeyword.value,
      image_keyword: imageKeyword.value,
      links: values.links.filter((link) => link)
    });

    fetchProjects();
    searchProjects()

    const {articleId} = storeToRefs(articleStore)
    articleId.value = data.payload.id;
    modalsStore.closeTextCreateModal()
  } catch (e) {
    console.log(e);
  } finally {
    // await articleStore.setArticleGenerating(false);
  }
}

onMounted(async () => {
  // topicsStore.fetchImageKeywordOptions();
  await topicsStore.fetchTopics();
});

defineExpose({thirdStepForm})
</script>
