import { defineStore } from 'pinia'



export const getMenuStore = defineStore('menuStore', {
  state: () => ({
    menuIsVisible: false
  }),
  actions: {
    toggleMenu() {
      this.menuIsVisible = !this.menuIsVisible;
    },
    closeMenu() {
      this.menuIsVisible = false;
    },
  },
});

