<template>
  <div class="modal sign-modal">
    <div class="overlay" />
    <div class="wrapper">
      <router-link 
        to="/" 
        class="logo"
      >
        <img 
          src="./../assets/img/logo.svg" 
          class="img"
        >
        <div class="desc">
          <b>{{ $t('WriteWizard') }}</b>
        </div>
      </router-link>
      <img 
        @click="modalsStore.closeSignIn()"
        src="./../assets/img/close.svg" 
        class="close"
      >
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="modal-left">
              <div class="img-container">
                <div class="title">
                  <b>{{ $t('Welcome back!') }}</b>
                </div>
                <img 
                  src="./../assets/img/get-started.png" 
                  class="img"
                >
              </div>
            </div>
            <div class="modal-right">
              <div v-if="loginContentVisible" class="form-fields login-content">
                <div class="title regular">
                  <b>{{ $t("Sign in to WriteWizard") }}</b>
                </div>
                <div class="modal-bottom">
                  <div class="desc">
                    {{ $t("New user?") }}
                  </div>
                  <a class="desc link" @click="modalsStore.openSignUp()">
                    {{ $t("Sign up") }}
                  </a>
                </div>
                <Form ref="loginForm" :novalidate="false" :on-submit="submitLogin" :validation-schema="schema" class="form-fields-wrapper">
                  <div class="label-list">
                    <label>
                      <div class="desc small">
                        <b>{{ $t('Email') }}:</b>
                      </div>
                      <TextInput
                          name="email"
                          type="email"
                          required
                      />
                    </label>
                    <label>
                      <div class="desc small">
                        <b>{{ $t('Password') }}:</b>
                      </div>
                      <TextInput
                          name="password"
                          type="password"
                          required
                      />
                    </label>
                  </div>
                  <div class="cta-container">
                    <button
                        type="submit"
                        class="button login-button"
                    >
                      <div v-if="loginStore.loading" class="loader-container">
                        <div class="loader"></div>
                      </div>
                      <div class="ball"></div>
                      <b v-if="!loginStore.loading">{{ $t('LOGIN') }}</b>
                      <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.9999 0C13.3135 0 12.7571 0.556452 12.7571 1.24286C12.7571 1.92926 13.3135 2.48571 13.9999 2.48571C20.6353 2.48571 26.0142 7.8647 26.0142 14.5C26.0142 21.1354 20.6353 26.5143 13.9999 26.5143C13.3135 26.5143 12.7571 27.0708 12.7571 27.7571C12.7571 28.4435 13.3135 29 13.9999 29C22.0081 29 28.4999 22.5081 28.4999 14.5C28.4999 6.49187 22.0081 0 13.9999 0Z" fill="white"/>
                        <path d="M11.9641 10.4074C11.4787 9.92203 11.4787 9.13509 11.9641 8.64973C12.4495 8.16437 13.2363 8.16437 13.7216 8.64973L18.6931 13.6212C19.1784 14.1066 19.1784 14.8934 18.6931 15.3788L13.7216 20.3502C13.2363 20.8356 12.4495 20.8356 11.9641 20.3502C11.4787 19.8648 11.4787 19.078 11.9641 18.5926L14.8137 15.7428H1.24286C0.556452 15.7428 0 15.1864 0 14.5C0 13.8136 0.556452 13.2571 1.24286 13.2571H14.8137L11.9641 10.4074Z" fill="white"/>
                      </svg>
                    </button>
                  </div>
                  <Transition>
                    <div v-if="loginStore.error" class="error-desc desc red">
                      {{ $t(loginStore.error) }}
                    </div>
                  </Transition>
                </Form>
                <div class="modal-absolute">
                  <div class="desc">
                    <a class="link" @click="switchToForgotPass()">
                      {{ $t("Forgot your password?") }}
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="forgotPassContentVisible" class="form-fields forgot-pass-content">
                <div class="title regular">
                  <b>
                    {{ $t("Forgot password?") }}
                  </b>
                </div>
                <div class="form-fields-wrapper">
                  <div class="label-list">
                    <label>
                      <div class="desc small">
                        <b>{{ $t('Email') }}:</b>
                      </div>
                      <input v-model="email" type="email" />
                    </label>
                  </div>
                  <div class="cta-container">
                    <a 
                      class="button login-button" 
                      @click="submitForgotPass()"
                    >
                      <div v-if="recoverStore.loading" class="loader-container">
                        <div class="loader"></div>
                      </div>
                      <div class="ball"></div>
                      <b v-if="!recoverStore.loading">{{ $t('Recover') }}</b>
                      <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.9999 0C13.3135 0 12.7571 0.556452 12.7571 1.24286C12.7571 1.92926 13.3135 2.48571 13.9999 2.48571C20.6353 2.48571 26.0142 7.8647 26.0142 14.5C26.0142 21.1354 20.6353 26.5143 13.9999 26.5143C13.3135 26.5143 12.7571 27.0708 12.7571 27.7571C12.7571 28.4435 13.3135 29 13.9999 29C22.0081 29 28.4999 22.5081 28.4999 14.5C28.4999 6.49187 22.0081 0 13.9999 0Z" fill="white"/>
                        <path d="M11.9641 10.4074C11.4787 9.92203 11.4787 9.13509 11.9641 8.64973C12.4495 8.16437 13.2363 8.16437 13.7216 8.64973L18.6931 13.6212C19.1784 14.1066 19.1784 14.8934 18.6931 15.3788L13.7216 20.3502C13.2363 20.8356 12.4495 20.8356 11.9641 20.3502C11.4787 19.8648 11.4787 19.078 11.9641 18.5926L14.8137 15.7428H1.24286C0.556452 15.7428 0 15.1864 0 14.5C0 13.8136 0.556452 13.2571 1.24286 13.2571H14.8137L11.9641 10.4074Z" fill="white"/>
                      </svg>
                    </a>
                  </div>
                  <Transition>
                    <div v-if="recoverStore.successMessage" class="desc green">
                      {{ $t(recoverStore.successMessage) }}
                    </div>
                  </Transition>
                  <Transition>
                    <div v-if="recoverStore.error" class="error-desc desc red">
                      {{ $t(recoverStore.error) }}
                    </div>
                  </Transition>
                  <div class="modal-bottom">
                    <div class="desc">
                      {{ $t("Have account?") }}
                    </div>
                    <a
                      class="desc link switch-login"
                      @click="switchToLogin()"
                    >
                      {{ $t("Login") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Yup from 'yup';
import { Form } from 'vee-validate';
import TextInput from "@/components/TextInput.vue";
import { ref, defineExpose } from 'vue';
import { getModalsStore } from './../stores/modals';
import { getLoginStore } from './../stores/login';
import { getRecoverStore } from './../stores/recover';

// Define reactive state
const loginContentVisible = ref(true);
const forgotPassContentVisible = ref(false);
const email = ref('');
// const pass = ref('');
const modalsStore = getModalsStore();
const loginStore = getLoginStore();
const recoverStore = getRecoverStore();

const loginForm = ref(null)

// Define methods
const switchToForgotPass = () => {
  loginStore.clearError();
  setTimeout(() => {
    loginContentVisible.value = false;
    forgotPassContentVisible.value = true;
  }, 100);
};

const switchToLogin = () => {
  recoverStore.clearError();
  setTimeout(() => {
    forgotPassContentVisible.value = false;
    loginContentVisible.value = true;
  }, 100);
};

const submitLogin = (values) => {
  loginStore.submitLogin(values);
};

const submitForgotPass = () => {
  const data = {
    email: email.value,
  };
  recoverStore.submitRecover(data);
};

const schema = Yup.object().shape({
  email: Yup.string().required().email(),
  password: Yup.string().required(),
});

defineExpose({loginForm})
</script>
