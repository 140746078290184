import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./pages/HomePage.vue";
import PageNotFound from "./pages/PageNotFound.vue";
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFaliPage from './pages/PaymentFaliPage.vue'
import TextPage from './pages/TextPage.vue'
import CabinetPage from './pages/CabinetPage.vue'
import PricingPage from './pages/PricingPage.vue'

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: '/success-payment',
    name: 'PaymentSuccessPage',
    component: PaymentSuccessPage
  },
  {
    path: '/fail-payment',
    name: 'PaymentFaliPage',
    component: PaymentFaliPage
  },
  {
    path: '/pages/:id',
    name: 'TextPage',
    component: TextPage
  },
  {
    path: '/cabinet/:page/:id?',
    name: 'CabinetPage',
    component: CabinetPage
  },
  {
    path: '/pricing',
    name: 'PricingPage',
    component: PricingPage
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
  });

  
export default router;
