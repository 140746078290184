<template>
  <div class="modal sign-modal reg-modal">
    <div class="overlay"/>
    <div class="wrapper">
      <router-link to="/" class="logo">
        <img src="./../assets/img/logo.svg" class="img" />
        <div class="desc"><b>{{ $t('WriteWizard') }}</b></div>
      </router-link>
      <img @click="modalsStore.closeSignUp()" src="./../assets/img/close.svg" class="close" />
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="modal-left">
              <div class="img-container">
                <div class="title small"><b>{{ $t('Let`s create something') }}</b></div>
                <div class="title"><b>{{ $t('awesome') }}</b></div>
                <img src="./../assets/img/get-started2.png" class="img" />
              </div>
            </div>
            <div class="modal-right">
              <div class="form-fields register-content">
                <div class="title regular">
                  <b>{{ $t("Sign up to WriteWizard") }}</b>
                </div>
                <Form :novalidate="false" :on-submit="submitRegister" :validation-schema="schema" class="form-fields-wrapper">
                  <div class="modal-bottom">
                    <div class="desc">{{ $t("Already have an account?") }}</div>
                    <a class="desc link" @click="modalsStore.openSignIn()">
                      {{ $t("Sign in") }}
                    </a>
                  </div>
                  <div class="label-list">
                    <label>
                      <div class="desc small gray"><b>{{ $t('Name') }}:</b></div>
<!--                      <input v-model="name" type="text" />-->
                      <TextInput
                          name="name"
                          type="text"
                          required
                      />
                    </label>
                    <label>
                      <div class="desc small gray"><b>{{ $t('Surname') }}:</b></div>
<!--                      <input v-model="surname" type="text" />-->
                      <TextInput
                          name="surname"
                          type="text"
                          required
                      />
                    </label>
                    <label>
                      <div class="desc small gray"><b>{{ $t('E-mail') }}:</b></div>
<!--                      <input v-model="email" type="email" />-->

                      <TextInput
                          name="email"
                          type="email"
                          required
                      />
                    </label>
                    <label>
                      <div class="desc small gray"><b>{{ $t('Phone') }}:</b></div>
<!--                      <input v-model="phone" type="text" @input="allowOnlyNumbers" />-->

                      <TextInput
                          name="phone"
                          type="text"
                          required
                          :filter="allowOnlyNumbers"
                      />
                    </label>
                    <label>
                      <div class="desc small gray"><b>{{ $t('Password') }}:</b></div>
<!--                      <input v-model="pass" type="password" />-->

                      <TextInput
                          name="password"
                          type="password"
                          required
                      />
                    </label>
                    <label>
                      <div class="desc small gray"><b>{{ $t('Confirm Password') }}:</b></div>
<!--                      <input v-model="passConfirm" type="password" />-->

                      <TextInput
                          name="password_confirmation"
                          type="password"
                          required
                      />
                    </label>
                  </div>
                  <div class="cta-container">
                    <button :disabled="!agree" type="submit" :class="['button login-button']" class="button login-button">
                      <div v-if="registerStore.loading" class="loader-container">
                        <div class="loader"></div>
                      </div>
                      <div class="ball"></div>
                      <b v-if="!registerStore.loading">{{ $t('SIGN UP') }}</b>
                      <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.9999 0C13.3135 0 12.7571 0.556452 12.7571 1.24286C12.7571 1.92926 13.3135 2.48571 13.9999 2.48571C20.6353 2.48571 26.0142 7.8647 26.0142 14.5C26.0142 21.1354 20.6353 26.5143 13.9999 26.5143C13.3135 26.5143 12.7571 27.0708 12.7571 27.7571C12.7571 28.4435 13.3135 29 13.9999 29C22.0081 29 28.4999 22.5081 28.4999 14.5C28.4999 6.49187 22.0081 0 13.9999 0Z"
                            fill="white"
                        />
                        <path
                            d="M11.9641 10.4074C11.4787 9.92203 11.4787 9.13509 11.9641 8.64973C12.4495 8.16437 13.2363 8.16437 13.7216 8.64973L18.6931 13.6212C19.1784 14.1066 19.1784 14.8934 18.6931 15.3788L13.7216 20.3502C13.2363 20.8356 12.4495 20.8356 11.9641 20.3502C11.4787 19.8648 11.4787 19.078 11.9641 18.5926L14.8137 15.7428H1.24286C0.556452 15.7428 0 15.1864 0 14.5C0 13.8136 0.556452 13.2571 1.24286 13.2571H14.8137L11.9641 10.4074Z"
                            fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                  <Transition>
                    <div v-if="registerStore.error" class="error-desc desc red">{{ registerStore.error }}</div>
                  </Transition>
                </Form>
              </div>
              <div class="cta-container modal-absolute">
                <input v-model="agree" v-show="false" type="checkbox">
                <div @click="agree = !agree" class="checkbox"></div>
                <div class="desc">
                  {{ $t(" I agree with") }}
                  <a class="link" @click="staticStore.goToPage('terms')">{{ $t("privacy policy") }}</a>
                  {{ $t("and") }}
                  <a class="link" @click="staticStore.goToPage('privacy')">{{ $t("terms and conditions") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import * as Yup from 'yup';
import { Form } from 'vee-validate';
import TextInput from "@/components/TextInput.vue";
import { getModalsStore } from './../stores/modals';
import { getRegisterStore } from './../stores/register';
import { getStaticStore } from '../stores/static';

export default {
  name: 'RegistrationModal',
  components: {
    Form,
    TextInput
  },
  setup() {
    const agree = ref(false);

    const modalsStore = ref(getModalsStore());
    const registerStore = ref(getRegisterStore());
    const staticStore = ref(getStaticStore());

    onMounted(() => {
      // modalsStore.value = getModalsStore();
      // registerStore.value = getRegisterStore();
      // staticStore.value = getStaticStore();
    });

    const allowOnlyNumbers = (value) => {
      value = value.replace(/[^0-9+.,]/g, '');
      const parts = value.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      return decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    };

    const submitRegister = (values) => {
      registerStore.value.submitRegister(values);
    };

    const schema = Yup.object().shape({
      name: Yup.string().required(),
      surname: Yup.string().required(),
      email: Yup.string().required().email(),
      phone: Yup.string().required(),
      password: Yup.string().required(),
      password_confirmation: Yup.string()
          .required()
          .oneOf([Yup.ref('password')], 'Passwords do not match'),
    });

    return {
      agree,
      modalsStore,
      registerStore,
      staticStore,
      allowOnlyNumbers,
      submitRegister,
      schema
    };
  },
};
</script>
