<template>
  <div class="modal video-preview-modal">
    <div class="overlay" @click="modalsStore.closeVideoPreviewModal()"/>
    <div class="wrapper">
      <div class="container">
        <div class="preview">
          <div v-show="currentSceneIndex === i" class="video-container" v-for="(scene, i) in scenes" :key="i">
            <img
                v-if="scene?.backgroundType === 'image'"
                class="img"
                ref="videoRefs"
                :src="scene?.backgroundUri"
            />
            <video
                v-else-if="scene?.backgroundType === 'video'"
                :src="scene?.backgroundUri"
                class="img"
                preload
                ref="videoRefs"
                @ended="onVideoEnded(i)"
                @timeupdate="onTimeUpdate"
                muted
            />

            <!-- Custom controls -->
            <div class="controls">
              <img
                  v-if="isPlaying && i === currentSceneIndex"
                  @click="pauseVideo"
                  src="./../assets/img/icons/stop2.svg"
                  class="control-icon"
                  alt="Stop"
              />
              <img
                  v-else
                  @click="playVideo(i)"
                  src="./../assets/img/icons/play2.svg"
                  class="control-icon"
                  alt="Play"
              />
            </div>
          </div>
          <canvas ref="subtitles">
          </canvas>
        </div>
        <div class="cut-container">
          <vue-slider :max="videoDuration" v-model="value" @change="onSliderChange"/>
          <div class="time desc small time-start">{{ formatTime(value) }}</div>
          <div class="time desc small time-end">{{ formatTime(videoDuration) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, defineExpose} from 'vue';
import {getModalsStore} from './../stores/modals';
import 'vue-slider-component/theme/antd.css';
import {useVideoStore} from "@/stores/video";
import {storeToRefs} from "pinia";

let sceneImageTimer = null; // Timer for scenes with images
const modalsStore = ref('');
const videoStore = useVideoStore();
const value = ref(0); // Current time position in the slider
const currentSceneIndex = ref(0); // The currently active scene index

const videoRefs = ref([]); // Refs for video elements
const subtitles = ref(null); // Refs for video elements
const ctx = ref(null);

const isPlaying = ref(false); // Controls the play/stop state
const completedCycles = ref(0);
const isProgrammaticTimeUpdate = ref(false); // Flag for programmatic time changes
const isUpdating = ref(false);
const videoEnded = ref(false);

const {
  scenes,
  videoDuration,
  scenesDuration,
  backgroundMusic,
  voiceOverMusic
} = storeToRefs(videoStore);

// let lastTimeUpdate = 0; // Для отслеживания времени последнего обновления
// const timeUpdateInterval = 100; // Интервал обновлений в миллисекундах

let textTimings = [];

function calculateTextTimings(sceneDuration, textLines) {
  const textCount = textLines.length;

  // Время для каждой строки
  const timePerText = sceneDuration / textCount;

  const timings = [];
  let currentTime = 0;

  textLines.forEach((_, ) => {
    timings.push({
      start: currentTime,
      end: currentTime + timePerText,
    });
    currentTime += timePerText; // Добавляем время на каждый текст
  });

  return timings;
}

function initSceneTextTimings() {
  const currentScene = scenes.value[currentSceneIndex.value] || {};
  const sceneDuration = scenesDuration.value[currentSceneIndex.value] || 0;

  if (currentScene?.sub_scenes?.[0]?.text_lines) {
    textTimings = calculateTextTimings(sceneDuration, currentScene.sub_scenes[0].text_lines);
  }

  console.log(textTimings, 'newTiming', currentSceneIndex.value);
}

const initSubtitles = () => {
  initSceneTextTimings();
  const canvasEl = subtitles.value;
  console.log(canvasEl)

  if (canvasEl) {
    // Get canvas context
    ctx.value = canvasEl.getContext("2d");

    const currentScene = scenes.value[currentSceneIndex.value];
    // Start the rendering loop
    drawTextOnCanvas(currentScene?.sub_scenes?.[0]?.text_lines[0]?.text, 0);
  }
};



const drawTextOnCanvas = (text, currentScene) => {
  const canvasEl = subtitles.value;
  const context = ctx.value;

  if (canvasEl && context) {
    // Очистка canvas
    context.clearRect(0, 0, canvasEl.width, canvasEl.height);

    // Удаляем все HTML-теги из текста
    text = text.replace(/<\/?[^>]+(>|$)/g, "");

    // Настройка текста
    const fontSize = currentScene.fontSize || (18 / 1.1);
    const fontFamily = currentScene.fontFamily || "Arial";
    const textColor = currentScene.textColor || "white";
    const backgroundColor = currentScene.backgroundColor || "rgba(0, 0, 0, 0.55)";
    const padding = currentScene.padding || 10;
    const lineHeight = currentScene.lineHeight || fontSize * 1.5;
    const maxWidth = canvasEl.width - 40;

    context.font = `${fontSize}px ${fontFamily}`;
    context.textAlign = "center";
    context.textBaseline = "middle";

    const words = text.split(" ");
    let line = "";
    const lines = [];

    words.forEach((word) => {
      const testLine = line + word + " ";
      const testWidth = context.measureText(testLine).width;
      if (testWidth > maxWidth) {
        lines.push(line.trim());
        line = word + " ";
      } else {
        line = testLine;
      }
    });
    lines.push(line.trim());

    const totalTextHeight = lines.length * lineHeight;
    const startY = (canvasEl.height - totalTextHeight) / 2 + lineHeight / 2;

    lines.forEach((line, index) => {
      const textY = startY + index * lineHeight;

      const textWidth = context.measureText(line).width;
      context.fillStyle = backgroundColor;
      context.fillRect(
          canvasEl.width / 2 - textWidth / 2 - padding,
          textY - fontSize / 2 - padding / 2,
          textWidth + 2 * padding,
          fontSize + padding
      );

      context.fillStyle = textColor;
      context.fillText(line, canvasEl.width / 2, textY);
    });
  }
};











function formatTime(seconds) {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}

function updateCurrentScene(time) {
  let accumulatedTime = 0;

  for (let i = 0; i < scenesDuration.value.length; i++) {
    accumulatedTime += scenesDuration.value[i];
    if (time < accumulatedTime) {
      if (currentSceneIndex.value !== i) {
        currentSceneIndex.value = i;
        initSceneTextTimings(); // Пересчитать тайминги текста
      }
      break;
    }
  }
}


function calculateOffsetTime(time) {
  let accumulatedTime = 0;
  for (let i = 0; i < currentSceneIndex.value; i++) {
    accumulatedTime += scenesDuration.value[i];
  }

  const videoElement = videoRefs.value[currentSceneIndex.value];
  const offset = (time - accumulatedTime) % videoElement.duration;

  return [offset, Math.floor((time - accumulatedTime) / videoElement.duration)];
}

async function syncVideoWithSlider(time) {
  const currentScene = scenes.value[currentSceneIndex.value];
  if (currentScene && currentScene.backgroundType === 'video') {
    const videoElement = videoRefs.value[currentSceneIndex.value];
    const [offsetTime, cycles] = calculateOffsetTime(time);

    if (videoElement && videoElement.currentTime !== offsetTime) {
      completedCycles.value = cycles;

      // Set flag before updating currentTime programmatically
      isProgrammaticTimeUpdate.value = true;
      videoElement.currentTime = offsetTime;
    }

    if (isPlaying.value && videoElement.paused) {
      await videoElement.play();
    }
  }

  if (voiceOverMusic.value.currentTime !== time) {
    voiceOverMusic.value.currentTime = time;
  }

  if (backgroundMusic.value) {
    backgroundMusic.value.currentTime = value.value >= backgroundMusic.value.duration ? value.value % backgroundMusic.value.duration : value.value;
  }
}

async function onTimeUpdate() {
  // Проверка на флаг isUpdating, чтобы избежать параллельного выполнения
  if (isUpdating.value) return;

  isUpdating.value = true; // Установите флаг в начале выполнения

  try {
    // const now = Date.now();
    // if (now - lastTimeUpdate >= timeUpdateInterval) {
    //   lastTimeUpdate = now;
    const currentScene = scenes.value[currentSceneIndex.value];
    if (currentScene && currentScene.backgroundType === 'video') {
      const videoElement = videoRefs.value[currentSceneIndex.value];
      if (videoElement) {
        const calculateTimeWithinCurrentScene = () => {
          return videoElement.currentTime + videoElement.duration * completedCycles.value;
        };
        const sceneDuration = scenesDuration.value[currentSceneIndex.value];
        let accumulatedDuration = calculateAccumulatedDuration(currentSceneIndex.value);
        let currentTime = calculateTimeWithinCurrentScene();

        // console.log(sceneDuration, accumulatedDuration, 'duration');

        // if ((currentSceneIndex.value + 1) >= scenes.value.length && sceneDuration <= currentTime) {
        //   value.value = videoDuration.value;
        //   videoEnded.value = true;
        //   await pauseVideo();
        //
        //   console.log(scenes.value[0].backgroundType, 'backgroundType 1');
        //
        //   if (scenes.value[0].backgroundType === 'video') {
        //     const firstVideoElement = videoRefs.value[0];
        //
        //     if (firstVideoElement) {
        //       firstVideoElement.currentTime = 0;
        //     }
        //   }
        //
        //   // return;
        // }

        const currentTextIndex = textTimings.findIndex(
            timing => currentTime >= timing.start && currentTime <= timing.end
        );

        // console.log('text index init', currentTextIndex);

        if (currentTextIndex !== -1) {
          const currentText = currentScene?.sub_scenes?.[0]?.text_lines[currentTextIndex]?.text || "";
          // console.log(currentTextIndex, 'textIndex');
          drawTextOnCanvas(currentText, currentScene);
        }

        if (sceneDuration <= currentTime) {
          completedCycles.value = 0;


          if (currentSceneIndex.value + 1 < scenes.value.length) {
            currentSceneIndex.value++;
            accumulatedDuration = calculateAccumulatedDuration(currentSceneIndex.value);
            value.value = accumulatedDuration;

            const nextVideoElement = videoRefs.value[currentSceneIndex.value];
            nextVideoElement.currentTime = 0;
            await playVideo(currentSceneIndex.value); // добавьте await для синхронизации
            initSceneTextTimings();
          } else {
            videoEnded.value = true;
            await pauseVideo();
            value.value = videoDuration.value;

            // videoElement.currentTime = videoElement.duration;

            console.log(scenes.value[0].backgroundType, 'backgroundType 2');

            if (scenes.value[0].backgroundType === 'video') {
              const firstVideoElement = videoRefs.value[0];

              if (firstVideoElement) {
                firstVideoElement.currentTime = 0;
              }
            }
          }
        } else {
          console.log('Here333', currentTime, accumulatedDuration, videoDuration.value);
          value.value = currentTime + accumulatedDuration;
        }

        // if (voiceOverMusic.value) {
        //   voiceOverMusic.value.currentTime = value.value;
        // }
      }
    }
    // }
  } finally {
    isUpdating.value = false; // Сбросьте флаг после завершения
  }
}

async function onVideoEnded(currentIndex) {
  // Проверка на флаг isUpdating, чтобы избежать параллельного выполнения
  if (isUpdating.value) return;

  isUpdating.value = true; // Установите флаг в начале выполнения
  const videoElement = videoRefs.value[currentSceneIndex.value];

  try {
    if (videoElement.currentTime >= videoElement.duration) {
      clearInterval(sceneImageTimer);
      const sceneDuration = scenesDuration.value[currentSceneIndex.value];

      if (sceneDuration > (completedCycles.value + 1) * videoElement.duration) {
        completedCycles.value++;
        await videoElement.play(); // Добавьте await для синхронизации

        console.log(1);
      } else if (currentIndex + 1 < scenes.value.length) {
        completedCycles.value = 0;
        currentSceneIndex.value = currentIndex + 1;
        await playVideo(currentSceneIndex.value); // Добавьте await для синхронизации
        initSceneTextTimings()

        console.log(2);
      } else {
        value.value = videoDuration.value;
        isPlaying.value = false;
        completedCycles.value = 0;
        videoEnded.value = true;
        await resetMusic();

        console.log(scenes.value[0].backgroundType, 'backgroundType 3');

        if (scenes.value[0].backgroundType === 'video') {
          const firstVideoElement = videoRefs.value[0];

          if (firstVideoElement) {
            firstVideoElement.currentTime = 0;
          }
        }
      }
    } else {
      console.log('dsfdfd3433232ds')
    }
  } finally {
    isUpdating.value = false; // Сбросьте флаг после завершения
  }
}

function onSliderChange(newValue) {
  updateCurrentScene(newValue);
  syncVideoWithSlider(newValue);

  if (newValue < videoDuration.value) {
    videoEnded.value = false;
  } else if (!isPlaying.value && newValue >= videoDuration.value) {
    videoEnded.value = true;
  }

  const currentScene = scenes.value[currentSceneIndex.value];
  if (currentScene && currentScene.backgroundType === 'image') {
    clearInterval(sceneImageTimer); // Clear previous timer
    startImageTimer(currentSceneIndex.value); // Start timer for the image scene
  }
}

function calculateAccumulatedDuration(index) {
  return scenesDuration.value.slice(0, index).reduce((a, b) => a + b, 0);
}

async function playVideo(index) {
  const currentScene = scenes.value[index];
  if (currentScene.backgroundType === 'video') {
    let videoElement = videoRefs.value[index];

    if (videoElement) {
      if (videoEnded.value) {
        videoEnded.value = false;
        currentSceneIndex.value = 0;
        videoElement = videoRefs.value[currentSceneIndex.value];
        initSceneTextTimings();
        videoElement.currentTime = 0;
        value.value = 0;

        await resetMusic();
        console.log('start');
      }

      await videoElement.play();
      isPlaying.value = true;
      if (voiceOverMusic.value) {
        voiceOverMusic.value.currentTime = value.value;
        await voiceOverMusic.value.play();
      }
      if (backgroundMusic.value.paused) {
        backgroundMusic.value.currentTime = 0;
        await backgroundMusic.value.play();
      }
    }

  } else if (currentScene.backgroundType === 'image') {
    isPlaying.value = true;
    startImageTimer(index);
    if (voiceOverMusic.value) {
      voiceOverMusic.value.currentTime = value.value;
      await voiceOverMusic.value.play();
    }
    if (backgroundMusic.value.paused) {
      backgroundMusic.value.currentTime = 0;
      await backgroundMusic.value.play();
    }
  }
}

function startImageTimer(index) {
  clearInterval(sceneImageTimer);
  const sceneDuration = scenesDuration.value[index] * 1000;

  sceneImageTimer = setInterval(() => {
    value.value += 1;
    if (value.value >= (calculateAccumulatedDuration(index) + scenesDuration.value[index])) {
      clearInterval(sceneImageTimer);
      onVideoEnded(index);
    }
  }, 1000);
}

async function pauseVideo() {
  const currentScene = scenes.value[currentSceneIndex.value];
  if (currentScene.backgroundType === 'video') {
    const videoElement = videoRefs.value[currentSceneIndex.value];
    if (videoElement) {
      await videoElement.pause();
      isPlaying.value = false;
      if (voiceOverMusic.value) {
        await voiceOverMusic.value.pause();
      }
      if (!backgroundMusic.value.paused) {
        await backgroundMusic.value.pause();
      }
    }
  } else if (currentScene.backgroundType === 'image') {
    clearInterval(sceneImageTimer);
    isPlaying.value = false;
    if (voiceOverMusic.value) {
      await voiceOverMusic.value.pause();
    }
    if (!backgroundMusic.value.paused) {
      await backgroundMusic.value.pause();
    }
  }
}

async function resetMusic() {
  if (voiceOverMusic.value) {
    await voiceOverMusic.value.pause();
    voiceOverMusic.value.currentTime = 0;
  }
  if (backgroundMusic.value) {
    await backgroundMusic.value.pause();
    backgroundMusic.value.currentTime = 0;
  }
}

onMounted(() => {
  initSubtitles();

  modalsStore.value = getModalsStore();
  if (backgroundMusic.value) {
    backgroundMusic.value.currentTime = 0;
    backgroundMusic.value.loop = true;
  }
  if (voiceOverMusic.value) {
    voiceOverMusic.value.currentTime = 0;
  }
});

onUnmounted(async () => {
  await resetMusic();
});

defineExpose({subtitles})
</script>

<script>
import VueSlider from 'vue-slider-component';

export default {
  components: {
    VueSlider
  }
};
</script>
