<template>
  <div class="modal video-paths-modal">
    <div
        class="overlay"
        @click="modalsStore.closeVideoPathsModal()"
    />

<!--    <div class="wrapper wrapper-loading" v-if="videoGenerating">-->
<!--      <div class="container">-->
<!--        <div class="title regular">-->
<!--          <b>{{ $t('Please wait while the video storyboard is being created') }}</b>-->
<!--        </div>-->
<!--        <div class="progress">-->
<!--          <div class="bar"></div>-->
<!--        </div>-->
<!--        <div class="desc">-->
<!--          <b>31%</b>-->
<!--          <b> {{ $t('completed') }}</b>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <template v-else>-->
      <div class="wrapper" v-if="modalsStore.videoPathModalStep == 'initial'">
        <div class="container">
          <div class="video-paths-top">
            <img
                @click="modalsStore.closeVideoPathsModal()"
                src="./../assets/img/close.svg"
                class="close"
            >
            <div class="title small">
              <b>{{ $t('Which content would you like to repurpose into videos?') }}</b>
            </div>
            <div class="desc">
              {{
                $t('Text description. "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.')
              }}
            </div>
          </div>

          <div class="video-paths-list-container">
            <div class="video-paths-list">
              <div class="item">
                <div class="item-top">
                  <div class="title-container">
                    <div class="desc">
                      <b>{{ $t('Script to Video') }}</b>
                    </div>
                    <div class="icon">
                      <img
                          class="img"
                          src="./../assets/img/icons/script.svg"
                      />
                    </div>
                  </div>
                  <div class="desc small">{{ $t('Start typing or Copy paste your script') }}</div>
                </div>
                <div class="item-bottom">
                  <button class="button small" @click="modalsStore.videoPathModalStep = 'script_to_video'">
                    <span>{{ $t('Proceed') }}</span>
                  </button>
                  <div class="sublist-container">
                    <div class="desc small">
                      <b>{{ $t('Recommended for') }}:</b>
                    </div>
                    <ul class="sublist">
                      <li class="desc small">{{ $t('Educational videos') }}</li>
                      <li class="desc small">{{ $t('Listicle videos') }}</li>
                      <li class="desc small">{{ $t('Coaching videos') }}</li>
                      <li class="desc small">{{ $t('Step by step guides') }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-top">
                  <div class="title-container">
                    <div class="desc">
                      <b>{{ $t('Article to Video') }}</b>
                    </div>
                    <div class="icon">
                      <img
                          class="img"
                          src="./../assets/img/icons/article.svg"
                      />
                    </div>
                  </div>
                  <div class="desc small">{{ $t('Copy paste the URL') }}</div>
                </div>
                <div class="item-bottom">
                  <button class="button small" @click="modalsStore.videoPathModalStep = 'article_to_video'">
                    <span>{{ $t('Proceed') }}</span>
                  </button>
                  <div class="sublist-container">
                    <div class="desc small">
                      <b>{{ $t('Recommended for') }}:</b>
                    </div>
                    <ul class="sublist">
                      <li class="desc small">{{ $t('Blogs') }}</li>
                      <li class="desc small">{{ $t('Any HTML articles') }}</li>
                      <li class="desc small">{{ $t('Press releases') }}</li>
                    </ul>
                  </div>
                </div>
              </div>
<!--              <div class="item">-->
<!--                <div class="item-top">-->
<!--                  <div class="title-container">-->
<!--                    <div class="desc">-->
<!--                      <b>{{ $t('Edit Recorded Videos with AI') }}</b>-->
<!--                    </div>-->
<!--                    <div class="icon">-->
<!--                      <img-->
<!--                          class="img"-->
<!--                          src="./../assets/img/icons/aiEdit.svg"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="desc small">{{ $t('Input video from various sources') }}</div>-->
<!--                </div>-->
<!--                <div class="item-bottom">-->
<!--                  <button class="button small" @click="modalsStore.videoPathModalStep = 'edit_recorded_videos_with_ai'">-->
<!--                    <span>{{ $t('Proceed') }}</span>-->
<!--                  </button>-->
<!--                  <div class="sublist-container">-->
<!--                    <div class="desc small">-->
<!--                      <b>{{ $t('Recommended for:') }}</b>-->
<!--                    </div>-->
<!--                    <ul class="sublist">-->
<!--                      <li class="desc small">{{ $t('Add subtitles, logo, intro, etc.') }}</li>-->
<!--                      <li class="desc small">{{ $t('Cut portions of video') }}</li>-->
<!--                      <li class="desc small">{{ $t('Create highlights') }}</li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="item">
                <div class="item-top">
                  <div class="title-container">
                    <div class="desc">
                      <b>{{ $t('Visuals to video') }}</b>
                    </div>
                    <div class="icon">
                      <img
                          class="img"
                          src="./../assets/img/icons/visuals.svg"
                      />
                    </div>
                  </div>
                  <div class="desc small">{{ $t('Create video using images & short video clips') }}</div>
                </div>
                <div class="item-bottom">
                  <button class="button small" @click="modalsStore.videoPathModalStep = 'visuals_to_video'">
                    <span>{{ $t('Proceed') }}</span>
                  </button>
                  <div class="sublist-container">
                    <div class="desc small">
                      <b>{{ $t('Recommended for') }}:</b>
                    </div>
                    <ul class="sublist">
                      <li class="desc small">{{ $t('Educational videos') }}</li>
                      <li class="desc small">{{ $t('Listicle videos') }}</li>
                      <li class="desc small">{{ $t('Step by step guides') }}</li>
                      <li class="desc small">{{ $t('Personal use') }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrapper wrapper-special wrapper-script" v-if="modalsStore.videoPathModalStep == 'script_to_video'">
        <img
            @click="modalsStore.closeVideoPathsModal()"
            src="./../assets/img/close.svg"
            class="close"
        >
        <a
            class="back"
            @click="modalsStore.videoPathModalStep = modalsStore.videoPathModalStep = 'initial'"
        >
          <img
              src="./../assets/img/icons/back.svg"
              class="img"
          >
          <div class="desc">
            {{ $t('Back') }}
          </div>
        </a>
        <Form :on-submit="handleScriptToVideo" class="container">
          <div class="title-container">
            <div class="left">
              <div class="icon">
                <img
                    class="img"
                    src="./../assets/img/icons/script.svg"
                />
              </div>
              <div class="title small">
                <b>{{ $t('Script to Video') }}</b>
              </div>
            </div>
            <div class="right">
              <a class="settings-button" @click="settingsIsActive = !settingsIsActive">
                <img
                    class="img"
                    src="./../assets/img/icons/settings.svg"
                />
              </a>
              <div :class="['settings-dropdown', {'active': settingsIsActive}]">
                <div class="settings-dropdown-wrapper">
                  <div class="desc small">
                    <b>{{ $t('Scene settings:') }}</b>
                  </div>
                  <label class="checkbox-label toggle-label">
                    <div class="desc small">
                      <b>{{ $t('Auto highlight keywords') }}</b>
                    </div>
                    <div class="toggle">
                      <input type="checkbox"/>
                      <div class="checkbox"></div>
                    </div>
                  </label>
                  <label class="checkbox-label toggle-label">
                    <div class="desc small">
                      <b>{{ $t('Auto visuals selection') }}</b>
                    </div>
                    <div class="toggle">
                      <input type="checkbox" checked/>
                      <div class="checkbox"></div>
                    </div>
                  </label>
                  <div class="desc small">
                    <b>{{ $t('Create new scenes on:') }}</b>
                  </div>
                  <label class="checkbox-label">
                    <input type="checkbox"/>
                    <div class="checkbox"></div>
                    <div class="desc small">{{ $t('Both') }}</div>
                  </label>
                  <label class="checkbox-label">
                    <input type="checkbox"/>
                    <div class="checkbox"></div>
                    <div class="desc small">{{ $t('Both') }}</div>
                  </label>
                  <label class="checkbox-label">
                    <input type="checkbox"/>
                    <div class="checkbox"></div>
                    <div class="desc small">{{ $t('Both') }}</div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-container">
            <div class="label-list">
              <label>
                <div class="desc small gray"><b>{{ $t('Enter video name') }}:</b></div>
                <TextInput
                    name="title"
                    type="text"
                    required
                />
              </label>
              <label>
                <div class="desc small gray"><b>{{ $t('Enter your script') }}:</b></div>
                <TextArea name="text" required/>
              </label>
            </div>
          </div>
          <div class="bottom">
            <div class="text">
              <div class="desc small">
                <b>{{ $t('Characters') }}:</b>
              </div>
              <div class="desc small">
                <b>0</b>
              </div>
            </div>
            <button type="submit" class="button small">
              <span>{{ $t('NEXT') }}</span>
            </button>
          </div>

        </Form>
      </div>

      <div class="wrapper wrapper-special wrapper-article" v-if="modalsStore.videoPathModalStep == 'article_to_video'">
        <img
            @click="modalsStore.closeVideoPathsModal()"
            src="./../assets/img/close.svg"
            class="close"
        >
        <a
            class="back"
            @click="modalsStore.videoPathModalStep = modalsStore.videoPathModalStep = 'initial'"
        >
          <img
              src="./../assets/img/icons/back.svg"
              class="img"
          >
          <div class="desc">
            {{ $t('Back') }}
          </div>
        </a>
        <Form :on-submit="handleArticleToVideo" class="container">
          <div class="title-container">
            <div class="left">
              <div class="icon">
                <img
                    class="img"
                    src="./../assets/img/icons/article.svg"
                />
              </div>
              <div class="title small">
                <b>{{ $t('Article to Video') }}</b>
              </div>
            </div>
          </div>
          <div class="form-container">
            <div class="label-list">
              <label>
                <div class="desc small gray"><b>{{ $t('Enter video name') }}:</b></div>
                <TextInput
                    name="title"
                    type="text"
                    required
                />
              </label>
              <label>
                <div class="desc small gray"><b>{{ $t('Paste the URL') }}:</b></div>
                <TextInput
                    name="article_url"
                    type="text"
                    required
                />
              </label>
            </div>
          </div>
          <button class="button small" type="submit">
            <span>{{ $t('NEXT') }}</span>
          </button>
        </Form>
      </div>

      <div class="wrapper wrapper-special wrapper-article-content" v-if="modalsStore.videoPathModalStep == 'article_to_video_preview'">
        <img
            @click="modalsStore.closeVideoPathsModal()"
            src="./../assets/img/close.svg"
            class="close"
        >
        <a
            class="back"
            @click="modalsStore.videoPathModalStep = modalsStore.videoPathModalStep = 'article_to_video'"
        >
          <img
              src="./../assets/img/icons/back.svg"
              class="img"
          >
          <div class="desc">
            {{ $t('Back') }}
          </div>
        </a>
        <div class="container">
          <div class="title-container">
            <div class="left">
              <div>
                <div class="desc small">{{ $t('Project name') }}</div>
                <div class="title small">
                  <b>{{ $t(video?.title) }}</b>
                </div>
              </div>
            </div>
            <div class="right">
              <a class="settings-button" @click="settingsIsActive = !settingsIsActive">
                <img
                    class="img"
                    src="./../assets/img/icons/settings.svg"
                />
              </a>
              <div :class="['settings-dropdown', {'active': settingsIsActive}]">
                <div class="settings-dropdown-wrapper">
                  <div class="desc small">
                    <b>{{ $t('Scene settings') }}:</b>
                  </div>
                  <label class="checkbox-label toggle-label">
                    <div class="desc small">
                      <b>{{ $t('Auto highlight keywords') }}</b>
                    </div>
                    <div class="toggle">
                      <input type="checkbox"/>
                      <div class="checkbox"></div>
                    </div>
                  </label>
                  <label class="checkbox-label toggle-label">
                    <div class="desc small">
                      <b>{{ $t('Auto visuals selection') }}</b>
                    </div>
                    <div class="toggle">
                      <input type="checkbox" checked/>
                      <div class="checkbox"></div>
                    </div>
                  </label>
                  <div class="desc small">
                    <b>{{ $t('Create new scenes on') }}:</b>
                  </div>
                  <label class="checkbox-label">
                    <input type="checkbox"/>
                    <div class="checkbox"></div>
                    <div class="desc small">{{ $t('Both') }}</div>
                  </label>
                  <label class="checkbox-label">
                    <input type="checkbox"/>
                    <div class="checkbox"></div>
                    <div class="desc small">{{ $t('Both') }}</div>
                  </label>
                  <label class="checkbox-label">
                    <input type="checkbox"/>
                    <div class="checkbox"></div>
                    <div class="desc small">{{ $t('Both') }}</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-container">
<!--            <div class="content-container">-->
<!--              <div class="desc small">-->
<!--                <b>{{ $t('Source article') }}</b>-->
<!--              </div>-->
<!--              <div class="content">-->
<!--                <div class="title small">{{ $t('55 Foods You Should Never Put in the Fridge  Nerdy Foodies') }}</div>-->
<!--                <div class="desc small">-->
<!--                  {{-->
<!--                    $t('This paper aims to explore the significant capabilities, features with treatment workflow, and barriers to the adoption of telemedicine in Healthcare.')-->
<!--                  }}-->
<!--                  <br/><br/>-->
<!--                  {{-->
<!--                    $t('Regular hospital visits can be expensive, particularly in rural areas, due to travel costs.')-->
<!--                  }}-->
<!--                </div>-->
<!--                <img-->
<!--                    src="./../assets/img/img.png"-->
<!--                >-->
<!--              </div>-->
<!--            </div>-->
            <div class="content-container">
              <div class="desc small">
                <b>{{ $t('AI powered summary') }}</b>
              </div>
              <div class="content">
                <div class="list">
                  <VueDraggable handle=".item" @change="onChange"  v-model="localScenes" @end="onEndDrag" animation="150" ghostClass="ghost" class="container">
                  <div :key="i" v-for="(scene, i) in scenes" class="item">
                    <div class="item-top">
                      <div class="desc small">{{ $t('Scene') }} {{ i + 1 }}</div>
                      <a class="remove">
                        <img
                            src="./../assets/img/icons/move.svg"
                            class="img"
                        >
                      </a>
                    </div>
                    <div class="item-content">
                      <div v-html="$t(scene.text)" class="desc small">
                      </div>
                    </div>
                  </div>
                  </VueDraggable>
                </div>
              </div>
            </div>
          </div>
          <button class="button small" @click="router.push({name: 'CabinetPage', params: {page: 'video', id: videoId}}); modalsStore.closeVideoPathsModal();">
            <span>{{ $t('NEXT') }}</span>
          </button>
        </div>
      </div>

      <div class="wrapper wrapper-special wrapper-ai-recorded" v-if="modalsStore.videoPathModalStep == 'edit_recorded_videos_with_ai'">
        <img
            @click="modalsStore.closeVideoPathsModal()"
            src="./../assets/img/close.svg"
            class="close"
        >
        <a
            class="back"
            @click="modalsStore.videoPathModalStep = modalsStore.videoPathModalStep = 'initial'"
        >
          <img
              src="./../assets/img/icons/back.svg"
              class="img"
          >
          <div class="desc">
            {{ $t('Back') }}
          </div>
        </a>
        <div class="container">
          <div class="title-container">
            <div class="left">
              <div class="icon">
                <img
                    class="img"
                    src="./../assets/img/icons/aiEdit.svg"
                />
              </div>
              <div class="title small">
                <b>{{ $t('Edit Recorded Videos with AI') }}</b>
              </div>
            </div>
            <div class="right">
              <div class="desc small">
                <b>{{ $t('Select language in the video') }}:</b>
              </div>
              <select class="small">
                <option selected value="US">English, US</option>
                <option value="LV">Latvian, LV</option>
              </select>
            </div>
          </div>
          <div class="form-container">
            <Form class="label-list">
              <label>
                <div class="desc small gray"><b>{{ $t('Youtube video link') }}:</b></div>
                <TextInput
                    name="keywords"
                    type="text"
                    required
                />
              </label>
            </Form>
          </div>
          <label class="upload-label">
            <input type="file"/>
            <div class="upload">
              <img
                  src="./../assets/img/icons/uploadVideo.svg"
                  class="img"
              >
              <div class="desc small">
                <b>{{ $t('Drag and drop files or browse from your computer') }}</b>
              </div>
              <div class="desc small">{{ $t('Supported format types and sizes:') }}</div>
              <div class="desc small">
                {{ $t('Video - MP4, MOV, WEBM, OGG up to 1 GB or 15.00 minute(s)') }}
                <br/>
                {{ $t('Audio - MP3, WAV, WEBA up to 1 GB or 15.00 minute(s)') }}
              </div>
            </div>
          </label>
          <button class="button small" @click="modalsStore.videoPathModalStep = 'loading'">
            <span>{{ $t('NEXT') }}</span>
          </button>
        </div>
      </div>

      <div class="wrapper wrapper-special wrapper-visuals" v-if="modalsStore.videoPathModalStep == 'visuals_to_video'">
        <img
            @click="modalsStore.closeVideoPathsModal()"
            src="./../assets/img/close.svg"
            class="close"
        >
        <a
            class="back"
            @click="modalsStore.videoPathModalStep = modalsStore.videoPathModalStep = 'initial'"
        >
          <img
              src="./../assets/img/icons/back.svg"
              class="img"
          >
          <div class="desc">
            {{ $t('Back') }}
          </div>
        </a>
        <div class="container">
          <div class="title-container">
            <div class="left">
              <div class="icon">
                <img
                    class="img"
                    src="./../assets/img/icons/visuals.svg"
                />
              </div>
              <div class="title small">
                <b>{{ $t('Visuals to video') }}</b>
              </div>
            </div>
          </div>

          <Form :novalidate="false" :on-submit="(values) => {projectTitle = values.title; modalsStore.videoPathModalStep = 8}" class="form-container">
            <div class="label-list">
              <label>
                <div class="desc small gray"><b>{{ $t('Enter video name:') }}</b></div>
                <TextInput
                    name="title"
                    type="text"
                    required
                />
              </label>
            </div>

            <div class="flex">
              <label
                  @dragover.prevent="onDragOver"
                  @dragleave.prevent="onDragLeave"
                  @drop.prevent="onDrop($event, visualUploadFileTypes)"
                  :class="{ 'dragging': isDragging }"
                  class="upload-label"
              >
                <input style="display: none;" ref="fileInput" :accept="visualUploadFileTypes.join(',')" type="file" multiple @change="onFileChange" />
                <div @click="triggerFileInput" class="upload">
                  <img src="./../assets/img/icons/uploadVideo.svg" class="img">
                  <div class="desc small">
                    <b>{{ $t('Drag and drop files or browse from your computer') }}</b>
                  </div>
                  <div class="desc small">{{ $t('Supported format types and sizes:') }}</div>
                  <div class="desc small">
                    {{ $t('Video - MP4, MOV, WEBM up to 1 GB or 15.00 minute(s)') }}
                    <br />
                    {{ $t('Image - JPG, PNG, SVG, WEBP up to 1 GB') }}
                  </div>
                </div>
              </label>

              <!-- Список загруженных файлов -->
              <div class="list-container">
                <div class="desc small">
                  <b>{{ $t('Uploading files') }}:</b>
                </div>

                <div class="list">
                  <div v-for="(file, index) in selectedFiles" :key="index" class="item">
                    <div class="item-left">
                      <div class="text small">{{ index + 1 }}.</div>

                      <!-- Отображение превью изображения или видео -->
                      <template v-if="file.type === 'image'">
                        <img :src="file.previewUrl" class="img" />
                      </template>
                      <template v-else-if="file.type === 'video'">
                        <video :src="file.previewUrl" class="video-preview img"></video>
                      </template>

                      <div class="text">
                        <div class="desc small">
                          {{ $t(file.file.name) }}
                        </div>
                        <div class="desc extrasmall">
                          {{ (file.file.size / 1024 / 1024).toFixed(2) }} MB
                        </div>
                      </div>
                    </div>
                    <div class="item-right">
                      <a class="bin" @click="selectedFiles.splice(index, 1)">
                        <img src="./../assets/img/icons/bin2.svg" class="img">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="button small" type="submit">
              <span>{{ $t('Upload') }}</span>
            </button>
          </Form>
        </div>
      </div>

      <div class="wrapper wrapper-special wrapper-visuals wrapper-arrange" v-if="modalsStore.videoPathModalStep == 8">
        <img
            @click="modalsStore.closeVideoPathsModal()"
            src="./../assets/img/close.svg"
            class="close"
        >
        <a
            class="back"
            @click="modalsStore.videoPathModalStep = modalsStore.videoPathModalStep = 'visuals_to_video'"
        >
          <img
              src="./../assets/img/icons/back.svg"
              class="img"
          >
          <div class="desc">
            {{ $t('Back') }}
          </div>
        </a>
        <div class="container">
          <div class="title-container">
            <div class="left">
              <div class="title small">
                <b>{{ $t('Arrange and tag files') }}</b>
              </div>
            </div>
            <div class="right">
              <div class="desc">
                <b>{{ $t('Add more files') }}</b>
              </div>
              <a @click.prevent="triggerFileInput" class="open-button">
                <img
                    class="img"
                    src="./../assets/img/topUp.svg"
                />
              </a>
              <input style="display: none;" ref="fileInput" :accept="visualUploadFileTypes.join(',')" type="file" multiple @change="onFileChange" />
            </div>
          </div>
          <div class="list">
            <VueDraggable handle=".item" @change="onChange" v-model="selectedFiles"
                          animation="150" ghostClass="ghost" class="container">
              <div v-for="(file, index) in selectedFiles" :key="index" class="item">
                <div class="item-left">
                  <a class="move">
                    <img
                        src="./../assets/img/icons/move.svg"
                        class="move-img"
                        alt="move-icon"
                    >
                  </a>
                  <div class="text small">{{ index + 1 }}.</div>

                  <!-- Check file type to display image or video preview -->
                  <template v-if="file.type === 'image'">
                    <img
                        :src="file.previewUrl"
                        class="img"
                        alt="Image preview"
                    >
                  </template>
                  <template v-else-if="file.type === 'video'">
                    <video
                        :src="file.previewUrl"
                        class="img"
                        controls
                    ></video>
                  </template>

                  <div class="text">
                    <div class="desc small">
                      {{ $t(file.file.name) }}
                    </div>
                    <div class="desc extrasmall">
                      {{ (file.file.size / 1024 / 1024).toFixed(2) }} MB
                    </div>
                  </div>
                </div>

                <div class="item-right">
                  <label>
                    <input type="text" v-model="file.tags" placeholder="Tags"/>
                  </label>
                  <img src="./../assets/img/icons/success.svg" alt="success-icon">
                </div>
              </div>
            </VueDraggable>
          </div>
          <button class="button small" @click="handleVisualsToVideo">
            <span>{{ $t('Proceed') }}</span>
          </button>
        </div>
      </div>
<!--    </template>-->
  </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue';
import {useRouter} from "vue-router";
import {getModalsStore} from './../stores/modals';
import TextInput from "@/components/TextInput.vue";
import TextArea from "@/components/TextArea.vue";
import {Form} from 'vee-validate';
import axios from "@/axios";
import {useVideoStore} from "@/stores/video";
import {storeToRefs} from "pinia";
import {VueDraggable} from "vue-draggable-plus";
import {getProfileStore} from "@/stores/profile";
import {useToast} from "vue-toastification";
// import {storeToRefs} from "pinia";

// Reactive state
// const step = ref('initial');
const settingsIsActive = ref(false);
const selectedFiles = ref([]);
const projectTitle = ref('')
let modalsStore = getModalsStore();
const videoStore = useVideoStore();
const profileStore = getProfileStore();
const {setSuccessPullingCallback, updateScenes} = videoStore;
const {updateBalance} = profileStore;

const visualUploadFileTypes = [
  // Video formats
  'video/mp4',
  'video/quicktime',
  'video/webm',

  // Image formats
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp'
];


const router = useRouter();

const {videoId, video, scenes} = storeToRefs(videoStore);

// Локальная копия для манипуляций
const localScenes = ref(scenes?.value ? [...scenes?.value] : []);

// Отслеживаем изменения в исходных сценах и обновляем localScenes
watch(scenes, (newScenes) => {
  localScenes.value = [...newScenes];
});

// Для отслеживания текущего редактируемого индекса
const editingIndex = ref(null);
// Функция для обновления сцен после сортировки
const onEndDrag = () => {
  updateScenes(localScenes.value);
};

// const onEndDragFiles () => {
//   selectedFiles.value =
// };

const onChange = (event) => {
  // Получаем индекс элемента, который был перенесён
  const newIndex = event.newIndex;

  // Обновляем editingIndex, если он совпадает с индексом перемещённого элемента
  if (editingIndex.value !== null && editingIndex.value === event.oldIndex) {
    editingIndex.value = newIndex; // Обновляем на новую позицию
  }
};

// Состояние перетаскивания
const isDragging = ref(false);

// Обработка перетаскивания
const onDragOver = () => {
  isDragging.value = true;
};

const onDragLeave = () => {
  isDragging.value = false;
};

// Обработка дропа файлов
const onDrop = (event, mediaTypes) => {
  isDragging.value = false;
  const files = Array.from(event.dataTransfer.files);
  handleFiles(files, mediaTypes);
};

// Обработка выбора файлов через input
const onFileChange = (event) => {
  const files = Array.from(event.target.files);
  handleFiles(files, visualUploadFileTypes);
};

const fileInput = ref(null);

const triggerFileInput = () => {
  fileInput.value.click();
};


// Функция для обработки файлов и генерации превью
const handleFiles = (files, mediaTypes) => {
  const validFiles = files.filter((file) => {
    const allowedTypes = mediaTypes;
    const maxSize = 1024 * 1024 * 1024; // 1GB

    if (!allowedTypes.includes(file.type)) {
      return false;
    }

    if (file.size > maxSize) {
      return false;
    }

    return true;
  });

  // Добавляем файлы и их превью
  validFiles.forEach((file) => {
    const previewUrl = URL.createObjectURL(file); // Генерация локальной ссылки для превью
    selectedFiles.value.push({
      file,
      previewUrl,
      type: file.type.startsWith('image') ? 'image' : 'video',
      tags: ''
    });
  });
};

const uploadFiles = async (files) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('content[]', file.file); // Use the appropriate field name as expected by your server
    formData.append('type[]', file.type)
    formData.append('tags[]', file.tags)
  });

  const {data: {payload}} = await axios.post('/video/uploads/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return payload;
};

const toast = useToast();

const handleScriptToVideo = async (values) => {
  try {
    const {data: {payload: {id, balance}}} = await axios.post('/video/create', {
      type: 'script_to_video',
      voiceOver: true,
      splitTextOnNewLine: false,
      splitTextOnPeriod: false,
      ...values,
    });

    updateBalance(balance);

    modalsStore.closeVideoPathsModal();
    modalsStore.loadingModal.percent = 0;
    modalsStore.openLoadingModal('Generating storyboard');

    await router.push({name: 'CabinetPage', params: {page: 'video', id}});
  } catch (e) {
    if (e.response && e.response.status === 402) {
      toast.error(e.response.data.message, {
        timeout: 2000
      });
    } else {
      console.error(e);
    }
  }
}

const handleArticleToVideo = async (values) => {
  try {
    const {data: {payload: {id, balance}}} = await axios.post('/video/create', {
      type: 'article_to_video',
      voiceOver: true,
      splitTextOnNewLine: false,
      splitTextOnPeriod: false,
      ...values,
    });

    updateBalance(balance);

    modalsStore.closeVideoPathsModal();
    modalsStore.loadingModal.percent = 0;
    setSuccessPullingCallback(() => {
      modalsStore.openVideoPathsModal('article_to_video_preview');
    })
    videoId.value = id;
    modalsStore.openLoadingModal('Generating storyboard');


    // await router.push({name: 'CabinetPage', params: {page: 'video', id}});
  } catch (e) {
    if (e.response && e.response.status === 402) {
      toast.error(e.response.data.message, {
        timeout: 2000
      });
    } else {
      console.error(e);
    }
  }
}

const handleVisualsToVideo = async () => {
  try {
    const uploads = await uploadFiles(selectedFiles.value);

    const {data: {payload: {id, balance}}} = await axios.post('/video/create', {
      type: 'visuals_to_video',
      voiceOver: true,
      splitTextOnNewLine: false,
      splitTextOnPeriod: false,
      visuals: uploads?.map(({upload_id}) => upload_id),
      title: projectTitle.value
    });

    updateBalance(balance);

    modalsStore.closeVideoPathsModal();
    modalsStore.loadingModal.percent = 0;
    // setSuccessPullingCallback(() => {
    //   modalsStore.openVideoPathsModal('article_to_video_preview');
    // })
    // videoId.value = id;
    modalsStore.openLoadingModal('Generating storyboard');


    await router.push({name: 'CabinetPage', params: {page: 'video', id}});
  } catch (e) {
    if (e.response && e.response.status === 402) {
      toast.error(e.response.data.message, {
        timeout: 2000
      });
    } else {
      console.error(e);
    }
  }
}

// Lifecycle hook
onMounted(() => {
  modalsStore = getModalsStore();
});
</script>


