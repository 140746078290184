<script setup>
import {defineProps, defineExpose, ref, toRef, nextTick} from "vue"
import axios from "@/axios";
import {storeToRefs} from "pinia";
import {getArticleStore} from "@/stores/article";

const props = defineProps({
  heading: {
    type: Object,
    default: () => null,
  },
  showControls: {
    type: Boolean,
    default: () => true
  }
});

const editIsVisible = ref(false);
const titleInitial = ref(props.heading.title);
const heading = toRef(props, 'heading')
const showControls = toRef(props, 'showControls')
const inputValue = ref(props.heading?.title || '');

const input = ref(null);

const articleStore = getArticleStore();
const {article} = storeToRefs(articleStore);

const handleBlur = async () => {
  if (!inputValue.value) {
    inputValue.value = titleInitial.value;
  } else {
    try {
      await axios.put(process.env.VUE_APP_API + `article/headings/${heading.value.id}/title`, {
        title: inputValue.value,
      })

      editIsVisible.value = false;
    } catch (e) {
      inputValue.value = titleInitial.value;
    }
  }
}

const handleDelete = async () => {
  try {
    const {data: {payload}} = await axios.delete(process.env.VUE_APP_API + `article/headings/${heading.value.id}`);
    article.value.headings = payload;
  } catch (e) {
    console.error(e);
  }
}

const toggleEdit = async () => {
  editIsVisible.value = !editIsVisible.value;
  if (editIsVisible.value) {
    // Ждем обновления DOM и устанавливаем фокус на input
    await nextTick();
    input.value.focus();
  }
}

defineExpose({input})
</script>

<template>
  <div class="title item-wrapper">
    <div class="item-left">
      <div class="desc">
        <b>H{{ heading.heading_level }}</b>
      </div>
      <div class="desc small" v-if="!editIsVisible">
        <b>{{ inputValue }}</b>
      </div>
      <label v-show="editIsVisible">
        <input ref="input" @blur="handleBlur" v-model="inputValue" type="text"/>
      </label>
    </div>

    <div v-if="showControls" class="item-right">
      <a @click="toggleEdit">
        <img
            class="img"
            src="./../assets/img/icons/edit.svg"
        />
      </a>
      <a @click="handleDelete">
        <img
            class="img"
            src="./../assets/img/icons/bin.svg"
        />
      </a>
    </div>
  </div>
</template>

<style scoped>

</style>
