<template>
  <main class="main page-inside payment-result-page">
    <div class="section hero-section payment-section">
      <div class="page-inside-top">
        <div class="wrapper">
          <div class="title small">
            {{ $t('Thank you - your payment was successful. You will shortly receive a confirmation to your e-mail. In case of any questions, please contact us at ') }} {{ settings?.support_email }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { getSettingsStore } from "@/stores/settings";
import {storeToRefs} from "pinia";

const settingsStore = getSettingsStore();
const {settings} = storeToRefs(settingsStore);
</script>
