<template>
  <main class="main cabinet-page page-inside">
    <div class="main-wrapper">
      <div class="section cabinet-section">
        <CabinetSidebar />
        <TextGenerator v-if="this.$route.params.page == 'text'"/>
        <VideoGenerator v-if="this.$route.params.page == 'video'"/>
        <Profile v-if="this.$route.params.page == 'profile'"/>
        <TopUp v-if="this.$route.params.page == 'topup'"/>
      </div>
    </div>
  </main>
</template>
<script>
import{getAuthStore} from '../stores/auth'
import{getProfileStore} from '../stores/profile'

import CabinetSidebar from '../components/CabinetSidebar.vue'
import TextGenerator from '../components/TextGenerator.vue'
import VideoGenerator from '../components/VideoGenerator.vue'
import Profile from '../components/ProfilePage.vue'
import TopUp from '../components/TopUp.vue'



export default {
  name: 'CabinetPage',
  props: {
    currency: {
      type: String,
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
  },
  components: {
    CabinetSidebar,
    TextGenerator,
    VideoGenerator,
    Profile,
    TopUp
  },
  data: function() {
    return {
      authStore: '',
      profileStore: '',
    }
  },
  mounted() {
    this.authStore = getAuthStore();
    this.profileStore = getProfileStore();
  },
  methods: {
    
  }
}
</script>