import { defineStore } from 'pinia'
import axios from 'axios'
import { getProfileStore } from './profile'
import router from "@/router";

export const getAuthStore = defineStore('authStore', {
  state: () => ({
    isAuth: false,
    error: ''
  }),
  actions: {
    async fetchData() {
      try {
        const response = await axios.get(process.env.VUE_APP_API + 'is-auth');
        this.isAuth = response.data.authenticated;

        // Get the current path from the router
        const currentPath = router.currentRoute.value.path;

        // Only redirect to '/' if the current path starts with '/cabinet' and the user is not authenticated
        if (!this.isAuth) {
          if (currentPath.startsWith('/cabinet')) {
            router.push('/');
          }
        } else {
          getProfileStore().fetchData();
        }
      } catch (err) {
        this.error = err.message;
      }
    },
  },
});
