<template>
  <div v-click-outside="clickOutside" :class="['cabinet-sidebar', { 'active': sidebarIsActive }, { 'not-active': !sidebarIsActive }]">
    <div class="cabinet-sidebar-wrapper">
      <div class="cabinet-sidebar__logo">
        <div class="logo">
          <a class="img-container" @click="sidebarIsActive = true">
            <img
                class="img"
                src="./../assets/img/logo.svg"
            />
          </a>
          <div class="desc white">
            <b>{{ $t('WriteWizard') }}</b>
          </div>
        </div>
        <a class="arrow" @click="sidebarIsActive = false">
          <img
              class="img"
              src="./../assets/img/arrowsLeft.svg"
          />
        </a>
      </div>
      <div class="cabinet-nav">
        <router-link to="/cabinet/text" class="nav__item">
          <TextIcon />
          <div class="desc">
            <b>{{$t("Text")}}</b>
          </div>
        </router-link>
        <router-link to="/cabinet/video" class="nav__item">
          <VideoIcon />
          <div class="desc">
            <b>{{$t("Video")}}</b>
          </div>
        </router-link>
        <router-link to="/cabinet/profile" class="nav__item">
          <ProfileIcon />
          <div class="desc">
            <b>{{$t("My profile")}}</b>
          </div>
        </router-link>
        <div class="nav__item nav__item-wallet" @click="sidebarIsActive = true">
          <div class="nav__item-wrapper">
            <WalletIcon />
            <div class="desc">
              <b>{{$t("Balance")}}</b>
            </div>
          </div>
          <div class="wallet">
            <div class="wallet-topup">
              <div class="wallet-total">
                <div class="desc medium">
                  <b>{{ profile.balance }}</b>
                </div>
                <div class="desc">
                  {{$t("credits")}}
                </div>
              </div>
              <router-link class="topup-icon" to="/cabinet/topup">
                <img
                    class="img"
                    src="./../assets/img/topUp.svg"
                />
              </router-link>
            </div>
            <div class="wallet-info">
              <img
                  class="img"
                  src="./../assets/img/info.svg"
              />
              <div class="desc extrasmall">
                 {{ $t('characters') }} = {{ profile.article_characters_amount }} <br/> {{ $t('video') }} = {{ profile.video_amount }} <br/> {{ $t('images') }} = {{ profile.article_images_amount}}
              </div>
            </div>
          </div>
        </div>
        <div class="nav__item-logout-contaier">
          <a class="nav__item nav__item-logout" @click="logoutStore.logout()">
            <LogoutIcon />
            <div class="desc">
              <b> {{$t("Log Out")}}</b>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { getLogoutStore } from '../stores/logout';
import { getProfileStore } from '../stores/profile';
import TextIcon from '../components/icons/TextIcon.vue';
import VideoIcon from '../components/icons/VideoIcon.vue';
import ProfileIcon from '../components/icons/ProfileIcon.vue';
import WalletIcon from '../components/icons/WalletIcon.vue';
import LogoutIcon from '../components/icons/LogoutIcon.vue';
import {storeToRefs} from "pinia";

// State management using ref
const sidebarIsActive = ref(false);

// Accessing the logout store
const logoutStore = getLogoutStore();
const profileStore = getProfileStore();

const clickOutside = () => {
  sidebarIsActive.value = false;
}

const {
  profile
} = storeToRefs(profileStore);
</script>
