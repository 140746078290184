<template>
  <main class="main page-inside text-page">
    <div class="section hero-section" v-if="text">
      <div v-if="text.title" class="title">
        <b>{{ $t(text.title) }}</b>
      </div>
    </div>
    <div class="section text-section">
      <div class="page-inside-top">
        <Transition>
          <div class="wrapper" v-if="text">
            <div 
              v-if="text.header"
              class="desc" 
              v-html="$t(text.header)"
            />
            <div 
               v-if="text.content"
              class="desc"  
              v-html="$t(text.content)"
            />
          </div>
        </Transition>
      </div>
    </div>
  </main>
</template>
<script>
import{getStaticStore} from '../stores/static'

export default {
  name: 'TextPage',
  components: {

  },
  data: function() {
    return {
      staticStore: ''
    }
  },
  computed: {
    text() {
      return this.staticStore.textPageData;
    }
  },
  mounted() {
    this.staticStore = getStaticStore();
    this.staticStore.getTextPageContent(this.$route.params.id);
  },
  methods: {
    
  }
}
</script>